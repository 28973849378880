// @ts-nocheck

import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import moment from 'moment'
import React, {FC, useState, useEffect} from 'react'
import axios, {AxiosResponse} from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL

const AffiliatePaymentRequestWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [loadingCardcom, setLoadingCardcom] = useState(true);
  const [requestData, setRequestData] = useState({});

  var monthly_sales_amount = 15;

  var historic_withdrawals = [
    {
      date: moment().format('YYYY-MM-DD'),
      amount: 700,
      status: 'pending',
    },
    {
      date: moment().subtract(1, 'week').format('YYYY-MM-DD'),
      amount: 350,
      status: 'complete',
    },
    {
      date: moment().subtract(2, 'week').format('YYYY-MM-DD'),
      amount: 350,
      status: 'complete',
    },
    {
      date: moment().subtract(3, 'week').format('YYYY-MM-DD'),
      amount: 350,
      status: 'complete',
    },
  ];


  const [paymentAmount, setPaymentAmount] = useState('');
  const [invoiceFile, setInvoiceFile] = useState(null);

  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };

  const handleFileChange = (e) => {
    setInvoiceFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Payment Amount:', paymentAmount);
    console.log('Invoice File:', invoiceFile);

    // Here you can add the logic to handle the form submission,
    // like sending the data to a backend server.
  };



  useEffect(() => {
    (async () => {
      try {
      } catch (e) {
      }
    })()

  }, []);

  return (
    <>
    <div direction="rtl" style={{direction: 'rtl'}}>

    <div className='row g-5 g-xl-8'>

    <div className='col-xl-12'>

    <h1>ברוכים הבאים לעמוד בקשת התשלום בתוכנית השותפים של AutoPost!</h1>
  <p>כאן תוכל לראות את הסכום שצברת ולהגיש בקשות לקבלת התשלום</p>


      <div className="alert alert-danger" role="alert">{intl.formatMessage({id: "Minimum withdrawal amount is"}, {
        amount: 350
      })}</div>
    </div>

    <div className='col-xl-6'>

<div className={`card primary`}>
{/* begin::Header */}
<div className='card-header border-0 pt-5'>
  <h3 className='card-title align-items-start flex-column'>
    <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: "Withdrawal History"})}</span>

    <p>במסך זה תוכל למשוך את הכסף שצברת ולעקוב אחר היסטוריית המשיכות שלך</p>
    <p>המשיכה בתוכנית השותפים שלנו פשוטה ונוחה. כדי להגיש בקשת תשלום חדשה, תצטרך להזין את הסכום שתרצה למשוך מתוך הסכום שצברת, העלו את החשבונית שלכם ולחצו על בקשת תשלום.</p>
    <p>שים לב שסכום המשיכה המינימלי הוא 350 ש"ח. רק לאחר שתצבור לפחות סכום זה, תוכל להגיש בקשה למשיכה.</p>


  </h3>
  <div className='card-toolbar'>
    {/* begin::Menu */}

    {/* begin::Menu 1 */}
    {/* end::Menu 1 */}
    {/* end::Menu */}
  </div>
</div>
{/* end::Header */}
{/* begin::Body */}
<div className='card-body py-3'>
  {/* begin::Table container */}
  <div className='table-responsive'>
    {/* begin::Table */}
    <table className='table align-middle gs-0 gy-5'>
      {/* begin::Table head */}
      <thead>
        <tr>
          <th className='p-0 min-w-100px'><strong>{intl.formatMessage({id: "Date"})}</strong></th>
          <th className='p-0 min-w-100px'><strong>{intl.formatMessage({id: "Amount"})}</strong></th>
          <th className='p-0 min-w-100px'><strong>{intl.formatMessage({id: "Status"})}</strong></th>
          <th className='p-0 min-w-100px'><strong>{intl.formatMessage({id: "Invoice"})}</strong></th>
          {/*<th className='p-0 min-w-100px'></th>*/}
          {/*<th className='p-0 min-w-40px'></th>*/}
        </tr>
      </thead>
      {/* end::Table head */}
      {/* begin::Table body */}
      <tbody>
        {historic_withdrawals.map((withdrawal) =><tr className={''}>
          <td>
            {withdrawal.date}
          </td>
          <td>
            ₪{withdrawal.amount}
          </td>
          <td>

            <span className="badge badge-light-danger fw-semibold me-1">{intl.formatMessage({id: withdrawal.status})}</span>

            
          </td>
          <td>
            <button className={'btn btn-primary'}>{intl.formatMessage({id: "Download"})}</button>
          </td>
          {/*<td>
            <div className='d-flex flex-column w-100 me-2'>
              <div className='d-flex flex-stack mb-2'>
                <span className='text-muted me-2 fs-7 fw-semibold'>100%</span>
              </div>
              <div className='progress h-6px w-100'>
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  style={{width: '100%'}}
                ></div>
              </div>
            </div>
          </td>*/}
        </tr>)}
      </tbody>
      {/* end::Table body */}
    </table>
    {/* end::Table */}
  </div>
  {/* end::Table container */}
</div>
</div>

  
</div>


      <div className='col-xl-6'>

      <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-primary`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{intl.formatMessage({id: "Payment Request"})}</h3>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{intl.formatMessage({id: "Your Balance"})}</span>
            <span className='fw-bold fs-2x pt-1'>₪0.00</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >


          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="paymentAmount" className="form-label">{intl.formatMessage({id: "Amount"})}</label>
                <input
                  type="number"
                  className="form-control"
                  id="paymentAmount"
                  placeholder={intl.formatMessage({id: "Enter payment amount"})}
                  value={paymentAmount}
                  onChange={handlePaymentAmountChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="invoiceFile" className="form-label">{intl.formatMessage({id: "Upload Invoice"})}</label>
                <input
                  type="file"
                  className="form-control"
                  id="invoiceFile"
                  onChange={handleFileChange}
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary">{intl.formatMessage({id: "Request Payment"})}</button>
            </form>
          </div>


        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>



      </div>
    </div>
    </div>


      {/* end::Row */}
    </>
  )
}
export {AffiliatePaymentRequestWrapper}
