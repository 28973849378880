// @ts-nocheck

import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import React, {FC, useState, useEffect} from 'react'
import axios, {AxiosResponse} from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ReactAudioPlayer from 'react-audio-player';

import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL

const AffiliateTutorialWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  return (
    <>
      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto shift-right'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  ריכזנו עבורכם את כל היתרונות של המוצר שאתם משווקים
                  </a>

                  <ul>
  <li>🛠 התוכנה המתקדמת בעולם - התוכנה המתקדמת בעולם לפרסום אוטומטי בקבוצות פייסבוק.</li>
  <li>🤖 פרסום אוטומטי בכל הקבוצות - עם התוכנה שלנו כל הפוסטים של הלקוחות יתפרסמו בצורה אוטומטית בכל הקבוצות פייסבוק הרלוונטיות, מ-7 בבוקר ועד 11 בלילה.</li>
  <li>💬 ניהול תגובות אוטומטי ותיוגים למתעניינים - המערכת שלנו לא רק מפרסמת את הפוסטים שלהם בקבוצות, אלא גם מנהלת את התגובות! כל מי שיגיב על הפוסטים, וירשום אשמח לפרטים, מחיר או כל שאלה, אנחנו נתייג את הפרופיל שלו ונגיב לו בחזרה את הפרטים של הלקוח.</li>
  <li>📩 מענה אוטומטי להודעות במסנג'ר - כל הודעה שתגיע למסנג'ר של הלקוח אנחנו נגיב בחזרה את הפרטים שלו ליצירת קשר.</li>
  <li>📤 פרסום גם במרקטפלייס של פייסבוק - בנוסף אנחנו דואגים לפרסם את הפוסטים של הלקוח גם במרקטפלייס של פייסבוק.</li>
  <li>🗂 מאגר הקבוצות הגדול בישראל - ברשותנו מאגר של כל הקבוצות שיש בישראל מפולחות לפי קטגוריות, תחומים, אזורים ועוד.</li>
  <li>👨‍💻 קמפיינר אישי שמנהל הכל - הלקוח מקבל קמפיינר צמוד שמנהל עבורו את כל הפרסום בצורה מקצועית ויעילה ושדואג לבצע עבורו את כל העבודה מהתחלה ועד הסוף, וחוץ מלספק לנו את התכנים לפרסום הלקוח לא יצטרך לגעת בכלום.</li>
  <li>💬 שירות לקוחות - שירות לקוחות זמין מדי יום בין השעות 9:00 ועד 17:00.</li>
  <li>💰 מחיר חסר תקדים של 300 ש"ח בחודש - והחלק הטוב ביותר? שכל זה במחיר חסר תקדים של 300 ש"ח בחודש בלבד – ואנחנו מתחייבים לכל הלקוחות למחיר הזול בעולם.</li>
</ul>                  </div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto shift-right'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  דוגמאות שיחות מכירה - לידים קרים
                  </a>

                  <p>1. שיחת מכירות עם מתווך נדל"ן</p>
                  
                  <ReactAudioPlayer
                      src="https://zipper.ams3.cdn.digitaloceanspaces.com/audio/sales-example.mpeg"
                      controls
                    />
    <p><strong>נציג מכירות:</strong> שלום, "דניאל" [שם המתווך]?</p>
    <p><strong>מתווך (המתווך):</strong> כן מי מדבר?</p>
    <p><strong>נציג מכירות:</strong> מה שלומך אח יקר אני פונה אלייך בקשר לנכסים שאתה מפרסם בקבוצות פייסבוק ויש לי בשבילך הצעה מדהימה שלא תוכל לסרב לה.</p>
    <p><strong>מתווך:</strong> אני מקשיב.</p>
    <p><strong>נציג מכירות:</strong> פיתחנו תוכנה מדהימה לפרסום בקבוצות פייסבוק שדרכה אני יכול לפרסם את כל הנכסים שלך ובכל הקבוצות הרלוונטיות ומבלי שתיגע בכלום. בנוסף, התוכנה עוקבת אחרי כל הנכסים שפורסמו ובמידה ולקוחות יגיבו על הנכסים שלך וישאלו שאלות כמו "אשמח לפרטים" או "מחיר", אז התוכנה תתייג את הפרופיל שלהם ותגיב להם בחזרה את הפרטים שלך ליצירת קשר כמו טלפון או קישור לווצאפ. כל הטוב הזה במחיר מדהים של 10 ש"ח ביום ואני מתחייב לך למחיר הכי זול בעולם.</p>
    <p><strong>מתווך:</strong> נשמע מדהים/מעניין.</p>
    <p><strong>נציג מכירות:</strong> שמח לשמוע וזה אכן הצעה מדהימה/מטורפת וכמובן שהתוצאות ידברו בעד עצמם בשטח. בנוסף, אתה מקבל בחודש הראשון חודש פלוס 3 יום, מהסיבה שהימים הראשונים זה זמן התארגנות לבחור את הקבוצות, להעלות את הנכסים וכל ההקמה של המערכת.</p>
    <p>ואיך מתחילים? זה מאוד פשוט, אני צריך את השם פרטי והשם משפחה שלך, את המייל לפתוח עליו חשבון, את פרטי אשראי לתשלום ועל שם מי להוציא חשבונית ויצאנו לדרך.</p>
    <p>מה השם פרטי? מה השם משפחה? מה המייל שלך? מספר כרטיס? ועל שם מי להוציא חשבונית?</p>
    <p>מעולה, בזמן הקרוב ישלחו לך שם משתמש וסיסמא למערכת וקמפיינר מטעמנו ייצור קשר על מנת להתחיל פעילות. לבינתיים אני שולח לך את המספר של השירות לקוחות שלנו ליצירת קשר והחשבונית נשלחה במייל. הכרטיס נכנס להוראת קבע שאתה יכול לבטל בכל זמן. כמו שציינתי אתה לא מחוייב אף פעם להמשיך לחודש הבא ושבוע הבא אתה כבר יכול לעדכן שנוריד אותך מההוראת קבע ולא תחוייב חודש הבא. כמובן שאנחנו בטוחים שתקבל תוצאות מדהימות בשטח ושתרצה להמשיך איתנו לעוד המון שנים טובות ושנצליח בעזרת השם יתברך ימלך המשך יום מקסים (:</p>

      <hr/>

      <h1>התנגדויות ותשובות בשיחת מכירות</h1>
    <h2>שאלות ותשובות</h2>
    <div>
        <h3>שאלה: כמה פוסטים אתם תפרסמו לי ביום ואם הפרופיל לא מסתכן בחסימה?</h3>
        <p>תשובה: הפרופיל יפרסם עד גבול היכולת את המקסימום פוסטים שהוא יכול ומבלי להסתכן בחסימה.</p>
    </div>
    <div>
        <h3>שאלה: אם יש לנו כמה מתווכים במשרד יש מחיר מיוחד?</h3>
        <p>תשובה: שאלה מעולה, יש לנו מגוון חבילות כמובן שכמה שתרשמו יותר מתווכים המחיר למתווך יורד משמעותית (ראה חבילות באתר).</p>
    </div>
    <div>
        <h3>שאלה: האם התוכנה מפרסמת בשישי שבת?</h3>
        <p>תשובה: התוכנה שומרת שבת וחגי ישראל אבל ביום שישי התוכנה תפרסם עד כניסת שבת ובמוצ"ש היא תפרסם מיד בצאת השבת כך שאתה לא תפספס ימים ותנצל את המקסימום שמגיע לך בחבילה.</p>
    </div>



                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto shift-left'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  דוגמאות שיחות מכירה - לידים קרים
                  </a>

                  <p>2. דוגמא סוכנות נדל"ן</p>
                  
                  <ReactAudioPlayer
                      src="my_audio_file.ogg"
                      autoPlay
                      controls
                    />

    <p><strong>נציג מכירות:</strong> שלום, האם זה [שם הסוכן]?</p>
    <p><strong>סוכן:</strong> כן, זהו [שם הסוכן]. מי מדבר?</p>
    <p><strong>נציג מכירות:</strong> שלום [שם הסוכן], שמי [שמך] מ[שם החברה]. אני מבין שהתחרותיות בשוק הרכב דורשת מך להישאר קדימה תמיד. יש לי הצעה מדהימה שעשויה לשפר משמעותית את החשיפה והמכירות שלך דרך פייסבוק.</p>
    <p><strong>סוכן:</strong> אני מקשיב.</p>
    <p><strong>נציג מכירות:</strong> נהדר! הבוט שלנו לפרסום אוטומטי בפייסבוק מותאם במיוחד כדי להקל על המאמצים שלך במדיה החברתית. הוא מפרסם אוטומטית את המלאי שלך בקבוצות ודפים של רכב, מבטיח שהרכבים שלך מוצגים בפני קהל רחב ומתאים.</p>
    <p><strong>סוכן:</strong> איך זה עובד בדיוק?</p>
    <p><strong>נציג מכירות:</strong> תהליך פשוט מאוד. אתה רק צריך לספק את הפרטים ותמונות של הרכבים שברצונך למכור, והבוט מתזמן פרסומים יומיים בקבוצות המתמקדות ברכב. הוא גם מגיב לתגובות ושאלות, מה שמעלה את רמת המעורבות והחשיפה של המודעות שלך.</p>
    <p><strong>סוכן:</strong> זה נשמע מעולה, איך אני מתחיל?</p>
    <p><strong>נציג מכירות:</strong> אשלח לך קישור עם כל הפרטים והוראות להרשמה. ההתקנה פשוטה ואנחנו כאן לסייע בכל שלב. זה הזמן לנצל את הטכנולוגיה לטובת העסק שלך ולהגדיל מכירות באופן משמעותי.</p>


                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto shift-right'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  מקורות לגיוס לידים
                  </a>

                  <p>בכדי להרחיב את מעגל הלקוחות שלכם, אנו מציעים מגוון דרכים לגיוס לידים איכותיים:</p>
                  
                  <ul>
                    <li><strong>פייסבוק:</strong> חיפוש אנשים בעלי עניין רלוונטי דרך פרופילים או קבוצות ויצירת קשר דרך הודעות או טלפון, במידה ומספר הטלפון זמין.</li>
                    <li><strong>וואטסאפ:</strong> שליחת הודעות ישירות למספרים שהתקבלו דרך חיבורים אחרים או דרך קמפיינים ממומנים.</li>
                    <li><strong>טיקטוק:</strong> פרסום וידאו שמבליט את השירותים או המוצרים שלכם ומעודד פעולת מעקב או יצירת קשר.</li>
                    <li><strong>אימייל:</strong> שליחת מיילים ממוקדים לרשימות תפוצה שנאספו בהתאם להסכמות קיימות, עם מידע רלוונטי והצעות לשיתוף פעולה.</li>
                    <li><strong>שיטות נוספות:</strong> השתתפות באירועים מקצועיים, וובינרים, והרצאות שבהן אפשר להכיר פוטנציאל רב ליצירת קשרים חדשים.</li>
                  </ul>
                  <p>השימוש בכלים אלה יאפשר לכם להגדיל את מאגר הלקוחות ולשפר את ההצלחה של העסק שלכם בתחום הדיגיטלי והפיזי.</p>
                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {AffiliateTutorialWrapper}
