// @ts-nocheck
import React, {FC, useEffect, useRef, useState} from 'react'
import {Step6} from './steps/Step6'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
// @ts-nocheck
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {KTSVG} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import {useIntl} from 'react-intl'

const Horizontal: FC = (props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const intl = useIntl()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >

            {<div className='mb-13 text-center'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'AUTH.REGISTER.REGISTER'})}</h1>

              <div className='text-muted fw-bold fs-5'>
                {intl.formatMessage({id: 'AUTH.REGISTER.IF_YOU_NEED_MORE_INFO'})}{' '}
                <a href='#' className='link-primary fw-bolder'>
                  {intl.formatMessage({id: 'AUTH.REGISTER.CONTACT_US'})}
                </a>
                .
              </div>
            </div>}


          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>1. {intl.formatMessage({id: 'AUTH.REGISTER.CHOOSE_PACKAGE_TYPE'})}</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>2. {intl.formatMessage({id: 'AUTH.REGISTER.BUSINESS_INFO'})}</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>3. {intl.formatMessage({id: 'AUTH.REGISTER.BILLING_DETAILS'})}</h3>
            </div>
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {({ values, setFieldValue }) => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>

                <div className='current' data-kt-stepper-element='content'>
                  <Step6 type={props.type} values={values} setFieldValue={setFieldValue}/>
                </div>

                <div  data-kt-stepper-element='content'>
                  <Step2 values={values}/>
                </div>


                <div data-kt-stepper-element='content'>
                  <Step4 values={values} isSubmitButton={isSubmitButton}/>
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                        {intl.formatMessage({id: 'AUTH.REGISTER.BACK'})}
                    </button>
                  </div>

                  <div>
                    {!isSubmitButton && <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && intl.formatMessage({id: 'AUTH.REGISTER.CONTINUE'}) }
                        {isSubmitButton && intl.formatMessage({id: 'AUTH.REGISTER.SUBMIT'}) }
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                      </span>
                    </button>}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export {Horizontal}
