// @ts-nocheck

import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import React, {FC, useState, useEffect} from 'react'
import axios, {AxiosResponse} from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL

const AffiliateAddNewClientWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [loadingCardcom, setLoadingCardcom] = useState(true);
  const [requestData, setRequestData] = useState({});

  var monthly_sales_amount = 15;

  var tiered_commission_structure = [
    {
      name: "Novice",
      range: {
        from: 1,
        to: 9,
      },
      commission: 150,
    },
    {
      name: "Beginner",
      range: {
        from: 10,
        to: 29,
      },
      commission: 180,
    },
    {
      name: "Master",
      range: {
        from: 30,
        to: 49,
      },
      commission: 200,
    },
    {
      name: "Legend",
      range: {
        from: 50,
      },
      commission: 220,
    },
  ];

  function getTier(monthly_sales_amount) {
    for (let tier of tiered_commission_structure) {
      const { from, to } = tier.range;
      if (monthly_sales_amount >= from && (to === undefined || monthly_sales_amount <= to)) {
        return tier;
      }
    }
    // If no tier is found, return the first tier
    return tiered_commission_structure[0];
  }
  
  // Example usage:
  var userTier = getTier(monthly_sales_amount);

  useEffect(() => {
    (async () => {
      try {
      } catch (e) {
      }
    })()

  }, []);

  return (
    <>

    <div className="alert alert-primary" role="alert">
      Your Affiate Link is https://autopost.co.il?aff=sdfdsf
    </div>
    <div className="alert alert-primary" role="alert">
      Your Affiate Link is https://console.autopost.co.il/auth/registration?aff=8PcAMr
    </div>


    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='success'
              iconColor='white'
              title='7'
              description='Sales'
            />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='info'
              iconColor='white'
              title='7'
              description='Clicks'
            />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='warning'
              iconColor='white'
              title='7'
              description='Active Follow Ups'
            />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='danger'
              iconColor='white'
              title='7'
              description='Hot Leads'
            />
      </div>
    </div>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>

      <div className={`card primary`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Tiered commission structure</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}

          {/* begin::Menu 1 */}
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-100px'></th>
                <th className='p-0 min-w-200px'></th>
                {/*<th className='p-0 min-w-100px'></th>*/}
                {/*<th className='p-0 min-w-40px'></th>*/}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tiered_commission_structure.map((tier) =><tr className={userTier == tier ? 'table-primary' : ''}>
                <td>
                  <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {tier.range.from}-{tier.range.to} clients /month
                  </a>
                  <span className='text-muted fw-semibold d-block fs-7'>{tier.name}</span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                  ₪{tier.commission} / Client
                  </a>
                </td>
                {/*<td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>100%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '100%'}}
                      ></div>
                    </div>
                  </div>
                </td>*/}
              </tr>)}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>

        
      </div>
      <div className='col-xl-6'>

      <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-primary`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>Sales Summary</h3>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>You Balance</span>
            <span className='fw-bold fs-2x pt-1'>$37,563.00</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Sales
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>100 Regions</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>$2,5b</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Revenue
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Quarter 2/3</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>$1,7b</div>
                <KTSVG
                  path='/media/icons/duotune/general/gen024.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/electronics/elc005.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Growth
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>80% Rate</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>$8,8m</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Dispute
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>3090 Refunds</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>$270m</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr065.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>



      </div>
    </div>


      {/* end::Row */}
    </>
  )
}
export {AffiliateAddNewClientWrapper}
