/* eslint-disable jsx-a11y/anchor-is-valid */

import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ContentFilter} from './ContentFilter'

const ContentTable = () => {
  return (
    <div className='card'>
      <div className='card-header justify-content-end border-0 pt-5'>
        <div className='card-toolbar'>
          <ContentFilter />
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mb-0'>
            <tbody>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                  </div>
                </td>
                <td>
                  <span className='text-dark fw-bold text-hover-primary d-block fs-6'>VIN RSS</span>
                </td>
                <td>
                  <span className='text-dark d-block fs-6'>
                    <i>Sending Monday, Tuesday, Wednesday and Thursday</i>
                  </span>
                </td>

                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <table className='table table-row-dashed table-row-gray-300 align-middle mt-5 gs-0 gy-4 mb-0'>
            <tbody>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                  </div>
                </td>
                <td>
                  <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Single Post
                  </span>
                </td>
                <td>
                  <span className='text-dark d-block fs-6'>Sent March 6th 4:53 PM</span>
                </td>
                <td>
                  <span className='text-dark d-block fs-6'>
                    <a href='#'>View Post Content</a>
                  </span>
                </td>

                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr016.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                    </div>
                  </div>
                </td>
                <td>
                  <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Single Post
                  </span>
                </td>
                <td>
                  <span className='text-dark d-block fs-6'>Sent March 6th 4:53 PM</span>
                </td>
                <td>
                  <span className='text-dark d-block fs-6'>
                    <a href='#'>View Post Content</a>
                  </span>
                </td>

                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr016.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {ContentTable}
