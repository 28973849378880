/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import './index.scss'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_live_51MgAPsBq8YvMeMmTDRM2XiJXxkEg8MvMS3tOYuPTKAGaVNafzW2PSVrXSbBHBRFe3ylbOOVStTAvHPrEnsK83PUd00ztqbergw");
// const stripePromise = loadStripe("pk_test_51MgAPsBq8YvMeMmTiATutDpbphIXsvFSZCFDGyxUXDwmOvFc9hMDw5oHT9BK0J5YW5cEzWlaPENWfBo7Pe11NZpr00iaiZ9Efa");


const PaymentsWrapper: FC = () => {
  return (
    <>
      <div className='row gy-5 gx-xl-8 addon'>


        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>


      </div>
    </>
  )
}
export {PaymentsWrapper}
