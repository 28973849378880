// @ts-nocheck

import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import React, {FC, useState, useEffect} from 'react'
import axios, {AxiosResponse} from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL

const AffiliateWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [loadingCardcom, setLoadingCardcom] = useState(true);
  const [requestData, setRequestData] = useState({});

  var monthly_sales_amount = 15;

  var tiered_commission_structure = [
    {
      name: intl.formatMessage({id: "Novice"}),
      range: {
        from: 1,
        to: 9,
      },
      commission: 150,
    },
    {
      name: intl.formatMessage({id: "Beginner"}),
      range: {
        from: 10,
        to: 29,
      },
      commission: 180,
    },
    {
      name: intl.formatMessage({id: "Master"}),
      range: {
        from: 30,
        to: 49,
      },
      commission: 200,
    },
    {
      name: intl.formatMessage({id: "Legend"}),
      range: {
        from: 50,
      },
      commission: 220,
    },
  ];

  function getTier(monthly_sales_amount) {
    for (let tier of tiered_commission_structure) {
      const { from, to } = tier.range;
      if (monthly_sales_amount >= from && (to === undefined || monthly_sales_amount <= to)) {
        return tier;
      }
    }
    // If no tier is found, return the first tier
    return tiered_commission_structure[0];
  }
  
  // Example usage:
  var userTier = getTier(monthly_sales_amount);

  useEffect(() => {
    (async () => {
      try {
      } catch (e) {
      }
    })()

  }, []);

  return (
    <>
    <div direction="rtl" style={{direction: 'rtl'}}>

    <h1>ברוכים הבאים לתוכנית השותפים של AutoPost!</h1>
    <p>
      כאן תוכל לעקוב בקלות אחר הביצועים שלך, לראות את הרווחים הפוטנציאליים ולנהל את ההתקדמות שלך בצורה יעילה.
    </p>

    <h2>קישור השותפים</h2>
  <p>
    הקישור הזה ייחודי רק לך וישמש אותך כדי להפנות לקוחות חדשים ולהרוויח עמלות. כל מי שירכוש את התוכנה דרך הקישור שלך, אתה תקבל עליו תשלום.
  </p>



    <div className="alert alert-primary" role="alert">
    {intl.formatMessage({id: "Landing page affilate link"})} https://autopost.co.il?aff={currentUser.affiliate_code}
    </div>
    <div className="alert alert-primary" role="alert">
    {intl.formatMessage({id: "Payemnt page affilate link"})} https://console.autopost.co.il/auth/registration?aff={currentUser.affiliate_code}
    </div>

    <h3>בלוח המחוונים תמצא מידע חיוני שיסייע לך לעקוב אחר הביצועים שלך:</h3>

    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='success'
              iconColor='white'
              title={currentUser.affiliate_sales || 0}
              description={intl.formatMessage({id: "Sales"})}
              description={'מכירות – מספר הלקוחות שהגיעו דרך הקישור שלכם והשלימו רכישה.'}
            />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='info'
              iconColor='white'
              title={currentUser.affiliate_clicks || 0}
              description={intl.formatMessage({id: "Clicks"})}
              description={'קליקים – כמות הלחיצות על הקישור שלכם.'}
            />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='warning'
              iconColor='white'
              title={currentUser.affiliate_active_follow_up || 0}
              description={intl.formatMessage({id: "Active Follow Ups"})}
              description={'פולו אפ – מעקב אחר הלקוחות שגילו עניין אך לא השלימו רכישה.'}
            />
      </div>
      <div className='col-xl-3'>
        <StatisticsWidget5
              className='card-xl-stretch mb-5 mb-xl-8'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='danger'
              iconColor='white'
              title={currentUser.affiliate_hot_leads || 0}
              description={intl.formatMessage({id: "Hot Leads"})}
              description={'לידים חמים – לקוחות פוטנציאליים שממשיכים להתעניין במוצר.'}
            />
      </div>
    </div>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6'>

      <div className={`card primary`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: "Tiered commission structure"})}</span>
        </h3>
        

        <div className='card-toolbar'>
          
          <p>
           תוכנית השותפים שלנו מציעה מבנה עמלות מדורג, כך שככל שתביא יותר לקוחות, כך תגדיל את הרווחים שלך.
          </p>

          {/* begin::Menu */}

          {/* begin::Menu 1 */}
          {/* end::Menu 1 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-100px'></th>
                <th className='p-0 min-w-200px'></th>
                {/*<th className='p-0 min-w-100px'></th>*/}
                {/*<th className='p-0 min-w-40px'></th>*/}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tiered_commission_structure.map((tier) =><tr className={userTier == tier ? 'table-primary' : ''}>
                <td>
                  <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {tier.range.from}-{tier.range.to} {intl.formatMessage({id: "clients /month"})}
                  </a>
                  <span className='text-muted fw-semibold d-block fs-7'>{tier.range.from}-{tier.range.to} לקוחות בחודש: תקבל ₪{tier.commission} ש"ח עבור כל לקוח.</span>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                  ₪{tier.commission} / {intl.formatMessage({id: "Client"})}
                  </a>
                </td>
                {/*<td>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>100%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '100%'}}
                      ></div>
                    </div>
                  </div>
                </td>*/}
              </tr>)}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>

        
      </div>
      <div className='col-xl-6'>

      <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-primary`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{intl.formatMessage({id: "Sales Summary"})}</h3>
          </div>
          <p>בסיכום המכירות, תוכל לראות את הסכום שהרווחת עד כה.</p>

          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{intl.formatMessage({id: "Your Balance"})}</span>
            <span className='fw-bold fs-2x pt-1'>₪0.00</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {intl.formatMessage({id: "Sales"})}
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>0</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/electronics/elc005.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Growth
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>80% Rate</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>$0</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  {intl.formatMessage({id: "Dispute"})}
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>0 {intl.formatMessage({id: "Refunds"})}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>₪0</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr065.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>



      </div>
    </div>
    </div>


      {/* end::Row */}
    </>
  )
}
export {AffiliateWrapper}
