/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {PlansWrapper} from '../../../../pages/plans/PlansWrapper'
import {useIntl} from 'react-intl'

const Step6: FC = (props) => {
  const intl = useIntl()

  return (
    <>
    <div className='w-100'>
      {<PlansWrapper {...props}/>}
    </div>
    </>
  )
}

export {Step6}
