import * as Yup from 'yup'
import {useIntl, injectIntl} from 'react-intl'

export interface ICreateAccount {


  business_name: string
  contact_name: string
  contact_phone: string
  business_type: string
  invoice_name: string

}

const createAccountSchemas = [
  Yup.object({
    // business_type: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    business_name: Yup.string().required().label('business_name'),
    contact_name: Yup.string().required().label('contact_name'),
    contact_email: Yup.string().email().required().label('email'),
    contact_phone: Yup.string().required().label('contact_phone'),
    business_type: Yup.string().required().label('business_type'),
    invoice_name: Yup.string().required().label('invoice_name'),
  }),
  Yup.object({
    
  }),
]

const inits: ICreateAccount = {

  business_name: '',
  contact_name: '',
  contact_phone: '',
  business_type: '',
  invoice_name: '',


  //accountType: 'personal',
  //accountTeamSize: '50+',
  //accountName: '',
  //accountPlan: '1',
  //businessName: 'Keenthemes Inc.',
  //businessDescriptor: 'KEENTHEMES',
  //businessType: '1',
  //businessDescription: '',
  //businessEmail: 'corp@support.com',
  //nameOnCard: 'Max Doe',
  //cardNumber: '4111 1111 1111 1111',
  //cardExpiryMonth: '1',
  //cardExpiryYear: '2025',
  //cardCvv: '123',
  //saveCard: '1',
}

export {createAccountSchemas, inits}
