// @ts-nocheck

import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import moment from 'moment'
import React, {FC, useState, useEffect, useRef} from 'react'
import axios, {AxiosResponse} from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ReactAudioPlayer from 'react-audio-player';

import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL

const AffiliateContentWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({name: '', _id: '', posts: []});

  useEffect(() => {
    axios.get(`${API_URL}/api/affiliate/content/posts`)
      .then((response: AxiosResponse) => {
        setCampaigns(response.data);
        setSelectedCampaign(response.data[0]);
      });
  }, []);

  return (
    <>










      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>


                <h1>שותף יקר</h1>
  <p>בדף זה נציג לך את התוכן הפרסומי שמוכן עבורך לשימוש. התוכן הזה יאפשר לך ליצור לידים איכותיים ולהגביר את המכירות שלך</p>
  <h2>חלק 1: איך להשתמש בתוכן הפרסומי?</h2>
  <p>בחירת פוסט: יצרנו מספר פוסטים שיווקים, הקלטות מוכנות שאפשר לשלוח ללקוחות, סרטוני תדמית, וסרטוני המלצה של לקוחות קיימים. ותוכל לבחור את התוכן שהכי מתאים למוקד השיווקי הנוכחי שלך, יש לך אפשרות גם להכין תכנים בצורה עצמאית.</p>
  <h3>איפה מפרסמים?</h3>
  <p>אתם יכולים לפרסם את הקישור שלכם בכל מקום שתרצו</p>
  <ul>
    <li>רשתות חברתיות: פרסום בקבוצות פייסבוק או בממומן, אינסטגרם, טיקטוק לינקדאין, וקבוצות ווצאפ.</li>
    <li>אתרים ובלוגים: כתיבת פוסטים או הכנסת הקישור שלכם.</li>
    <li>וידאו ודוא"ל: יצירת סרטונים או שליחת דיוור לרשימות תפוצה.</li>
  </ul>

  <h2>איך מביאים לידים מתוך קבוצות פייסבוק?</h2>
  <p>כדי להביא לידים מתוך קבוצות פייסבוק בצורה אפקטיבית, יש כמה שיטות וצעדים שיכולים לעזור לך.</p>
  <p>לפני שמתחילים, חשוב להבין את סוג הקבוצות שאתה פועל בהן ואת סוג הקהל שנמצא בקבוצות אלו. חפש קבוצות בפייסבוק שהן רלוונטיות לתחום.</p>
  <p>לדוגמא: מכירה, השכרה, רכב, צימרים, בעלי עסקים, דרושים</p>
  <p>פנייה טלפונית - אחת השיטות האפקטיביות ביותר היא לחפש פוסטים שיש בהם את פרטי הקשר של המשתמש (כמו מספר טלפון, אם זמין בפרופיל או בפוסט עצמו) ולפנות אליו בשיחת טלפון עם ההצעה. השיחה הישירה תאפשר לכם להסביר בצורה טובה יותר את הערך שאתם מציעים.</p>

  <p>לפרסם את התכנים בקבוצות בתור פוסטים ולהגיב בחזרה לכל מי שהתעניין בפרטים</p>
  <p>להגיב ללקוחות פוטנציאלים על הפוסט שלהם בתגובות</p>
  <p>פנייה אישית בהודעות פרטיות, תוכל לפנות ישירות למשתמשים דרך הודעות פרטיות.</p>

  <p>קבוצות פייסבוק מאפשרות לכם למצוא המון לידים איכותיים אם תעשו זאת בצורה נכונה. המפתח הוא למצוא פוסטים רלוונטיים, לפנות בצורה אישית ואדיבה, להציע פתרון אמיתי לבעיה, ולעקוב אחרי השיחות בצורה מסודרת.</p>
  <p>כל זה יעזור לכם להפוך את הלידים ללקוחות מרוצים.</p>
  <p>השתמשו במערכת לניהול לידים כדי לעקוב אחרי כל הלידים שאתם יוצרים מקבוצות פייסבוק.</p>
  <p>כך תוכלו לעקוב אחרי השיחות, ההתקדמות, והסטטוס של כל ליד – האם הם מעוניינים, האם הם צריכים פולו-אפ, או אם ביצעתם מכירה או שהם לא מעוניינים כלל.</p>




                </div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>




                  <span
                    className='fw-semibold text-muted fs-5'
                    dangerouslySetInnerHTML={{__html: 'כלי השיווק שלכם לתוכנית השותפים'}}
                  ></span>






<p>כשותפים שלנו, אתם מקבלים גישה לתכנים שיווקיים בלעדיים שיעזרו לכם לקדם ביעילות את המוצרים והשירותים שלנו. הנה כמה דרכים שבהן תוכלו להשתמש בתכנים כדי להגדיל את המכירות שלכם:</p>
  
  <h3>🎥 סרטוני המלצות של לקוחות מרוצים</h3>
  <p>בסרטונים אלו מופיעים לקוחות אמיתיים המשתפים חוויות חיוביות. תוכלו להשתמש בסרטונים ברשתות חברתיות, בדפי נחיתה, או במיילים כדי לבנות אמון ולהדגיש את היתרונות של המוצרים שלנו.</p>
  
  <h3>🎙️ הקלטות אודיו של נאומי מכירה</h3>
  <p>הקלטות אודיו מקצועיות שמדגישות את הערך של המוצרים. שילוב ההקלטות בפרסומות שלכם או כדוגמה בשיחות מכירה יכול לעזור לכם להניע את הלקוחות לפעולה.</p>
  
  <h3>איך כדאי להשתמש בתכנים האלה?</h3>
  <ul>
    <li>שתפו סרטוני המלצות בקמפיינים ממומנים ברשתות חברתיות כדי למשוך תשומת לב ולבנות אמון מיידי.</li>
    <li>השתמשו בהקלטות האודיו ליצירת פוסטים ממוקדים ברשתות החברתיות או בהודעות מייל ישירות ללקוחות.</li>
    <li>שלבו את הסרטונים וההקלטות בדפי נחיתה, מודעות, או בלוגים כדי להעשיר את התוכן השיווקי ולהגביר את אחוזי ההמרה.</li>
  </ul>
  <p>התוכן מוכן לשימוש – עכשיו תורכם לקחת את הכלים האלו ולעשות איתם קסמים!</p>



                </div>

                <img src={toAbsoluteUrl('/media/svg/brand-logos/whatsapp.svg')} alt='' className='align-self-end h-100px' />
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>


      <div direction="rtl" style={{direction: 'rtl'}}>



        
        <div className='row g-5 g-xl-8'>


          <div className='col-xl-6 card-xl-stretch mb-xl-8'>
            <div className='card'>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                <a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
                  <div className='symbol symbol-75px mb-6'>
                    <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
                  </div>
                  <div className='fs-5 fw-bolder mb-2'>{'סרטוני המלצות לקוחות מרוצים'}</div>
                </a>
                <div className='fs-7 fw-bold text-gray-400 mt-auto'>{'43 סרטונים  - קצבי mp4'}</div>
                <div className='fs-7 fw-bold text-gray-400 mt-auto'><a className="btn btn-primary">להורדת הסרטונים לחץ כאן 📥</a></div>
                <div className='fs-7 fw-bold text-gray-400 mt-auto'><a className="btn btn-default" target="_blank" href="https://aff.autopost.co.il/#videos">לצפייה בסרטונים ▶️</a></div>
              </div>
            </div>
          </div>
          <div className='col-xl-6 card-xl-stretch mb-xl-8'>
            <div className='card'>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                <a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
                  <div className='symbol symbol-75px mb-6'>
                    <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
                  </div>
                  <div className='fs-5 fw-bolder mb-2'>{'הקלטה שיחת מכירה ליד קר כללי'}</div>
                </a>
                <div className='fs-7 fw-bold text-gray-400 mt-auto'>{'קובץ MP3'}</div>
                <div className='fs-7 fw-bold text-gray-400 mt-auto'><a className="btn btn-primary">להורדת הקלטת הסבר על התוכנה 📥</a></div>

                <ReactAudioPlayer
                      src="https://zipper.ams3.cdn.digitaloceanspaces.com/audio/sales-example.mpeg"
                      controls
                    />

              </div>
            </div>
          </div>



        </div>
      </div>














      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>

                  <span
                    className='fw-semibold text-muted fs-5'
                    dangerouslySetInnerHTML={{__html: 'פוסטים פרסום בפייסבוק'}}
                  ></span>


<p><strong>שותפים יקרים,</strong></p>
    <p>אנו שמחים לספק לכם תוכן פרסומי מוכן לשימוש עבור הבוט לפרסום בפייסבוק שלנו. התוכן נועד לעזור לכם להתמקד בקהל שלכם בפייסבוק וליצור לידים יקרי ערך.</p>
    <h2>איך להשתמש בתוכן שסיפקנו:</h2>
    <ol>
        <li><strong>בחירת פוסט:</strong> יצרנו מספר טקסטים לפוסטים שמדגישים תכונות ויתרונות שונים של הבוט לפרסום. בחרו את הפוסט שהכי מדבר אליכם או שמתאים למוקד השיווקי הנוכחי שלכם.</li>
        <li><strong>התאמה אישית:</strong> אתם מוזמנים להתאים אישית את ההודעה כך שתשמע יותר כמו הקול שלכם, או להתמקד יותר בקהל הספציפי שלכם. הוספת מגע אישי יכולה להגביר מאוד את המעורבות.</li>
        <li><strong>פרסום ישיר או תזמון:</strong> אתם יכולים לפרסם את הפוסטים באופן ישיר בפייסבוק או לתזמן אותם לפרסום בזמנים שבהם הקהל שלכם הכי פעיל. השתמשו בכלים כמו Facebook Insights לזיהוי השעות הטובות ביותר לפרסום.</li>
    </ol>
    <p><strong>מטרת הפרסומים:</strong> המטרה של הפוסטים היא ליצור עניין ולהגביר את המעורבות בקרב הקהל שלכם, ולהפוך אותם ללידים שיכולים להפוך ללקוחות פוטנציאליים. התוכן שאנחנו מספקים מתוכנן למשוך תשומת לב ולעורר עניין סביב הבוט לפרסום אוטומטי, ולהציג את היתרונות של שימוש בו.</p>
    <p>השתמשו בתוכן הזה כדי להגדיל את כמות הלידים שלכם, מה שיביא ליותר הכנסות. אנחנו כאן כדי לתמוך בכם בכל שלב בדרך להצלחה!</p>



                </div>

                <img src={toAbsoluteUrl('/media/svg/brand-logos/facebook-2.svg')} alt='' className='align-self-end h-100px' />
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex overflow-auto h-55px" style={{direction: 'rtl'}}>
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
          {campaigns.map( (campaign) => <li className="nav-item">
            <a className={`nav-link text-active-primary me-6 ${campaign._id === selectedCampaign._id ? 'active' : ''}`} onClick={() => {
              setSelectedCampaign(campaign);
            }}>{campaign.name}</a>
          </li>)}
        </ul>
      </div>

      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>


          {selectedCampaign.posts.map((post) => <div className='col-xl-6 card-xl-stretch mb-xl-8'>
            <div className={`card`}>
              {/* begin::Body */}
              <div className='card-body pb-0'>
                {/* begin::Header */}
                <div className='d-flex align-items-center mb-5'>
                  {/* begin::User */}
                  <div className='d-flex align-items-center flex-grow-1'>
                    {/* begin::Avatar */}
                    <div className='symbol symbol-45px ms-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                    </div>
                    {/* end::Avatar */}

                    {/* begin::Info */}
                    <div className='d-flex flex-column'>
                      <a className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                        שמעון כהן
                      </a>

                      <span className='text-gray-400 fw-semibold'>{moment().subtract(1, 'hour').fromNow()}</span>
                    </div>
                    {/* end::Info */}
                  </div>
                  {/* end::User */}

                  {/* begin::Menu */}
                  <div className='my-0'>
                  </div>
                  {/* end::Menu */}
                </div>
                {/* end::Header */}

                {/* begin::Post */}
                <div className='mb-5'>
                  {/* begin::Text */}
                  <p className='text-gray-800 fw-normal mb-5'>
                    {post.post_content}
                  </p>
                  {/* end::Text */}

                  {/* begin::Toolbar */}
                  <div className='d-flex align-items-center mb-5'>
                    <a
                      href='#'
                      className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                    >
                      <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
                      120
                    </a>

                    <a
                      href='#'
                      className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                    >
                      <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
                      15
                    </a>
                  </div>
                  {/* end::Toolbar */}
                </div>
                {/* end::Post */}

                {/* begin::Separator */}
                <div className='separator mb-4'></div>
                {/* end::Separator */}

                {/* edit::Reply input */}
              </div>
              {/* end::Body */}
            </div>
          </div>)}




        </div>
      </div>









    </>
  )
}



// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const VideoGallery = ({ videos }) => {
    // const [playingVideo, setPlayingVideo] = useState(null);
    const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
    const currentVideoRef = useRef(null); 

    // const handlePlay = (index) => {
    //     setPlayingVideo(index);
    // };
    const handlePlay = (index, videoElement) => {
      // Pause the previous video if one is playing
      if (currentVideoRef.current && currentVideoRef.current !== videoElement) {
          currentVideoRef.current.pause();
      }
      // Update the ref to the new video and set the playing video index
      currentVideoRef.current = videoElement;
      setPlayingVideoIndex(index);
  };

    // const handlePause = () => {
    //     setPlayingVideo(null);
    // };
    const handlePause = () => {
      setPlayingVideoIndex(null);
  };
  
  const downloadAllVideos = (videos) => {
    videos.forEach((video, index) => {
        setTimeout(() => {
            const link = document.createElement('a');
            link.href = video.downloadLink;
            link.download = video.downloadLink.split('/').pop(); // Extracts the file name from URL
            link.click();
        }, index * 1000); // 1000 ms delay between each download
    });
};
    return (
        <div className="container-fluid mt-4">
            <div className="row justify-content-center">
                {videos.map((video, index) => (
                    <div key={index} className="col-md-4 mb-4">
                        <div className="card shadow-sm">
                        {video.src.includes('youtube.com') ? (
                                <iframe
                                    className="card-img-top"
                                    width="100%"
                                    height="300px"
                                    src={video.src.replace("watch?v=", "embed/")}
                                    title={`YouTube video ${index}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    onPlay={() => handlePlay(index)}
                                    onPause={handlePause}
                                ></iframe>
                            ) : (
                                <video
                                    className="card-img-top"
                                    controls
                                    onPlay={(e) => handlePlay(index ,e.target)}
                                    onPause={handlePause}
                                    src={video.src}
                                    style={{
                                        outline: playingVideoIndex === index ? '2px solid #007bff' : 'none',
                                        position: "relative",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "300px",
                                        objectFit: "contain" // Ensures the video is fully visible
                                    }}
                                />
                            )}
                            {/* <video
                                className="card-img-top"
                                controls
                                onPlay={() => handlePlay(index)}
                                onPause={handlePause}
                                src={video.src}
                                style={{
                                    outline: playingVideo === index ? '2px solid #007bff' : 'none'
                                }}
                            /> */}
                            <div className="card-body text-center">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => window.open(video.downloadLink, '_blank')}
                                >
                                    להורדה
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Sample data to use with the component
const sampleVideos = [
  { url: 'https://www.youtube.com/watch?v=0HeGvvtRCNo', src: 'https://www.youtube.com/watch?v=0HeGvvtRCNo', title: "0HeGvvtRCNo", thumbnail: "https://img.youtube.com/vi/0HeGvvtRCNo/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/0HeGvvtRCNo.mp4'},
  { url: 'https://www.youtube.com/watch?v=ao6MyItVtYA', src: 'https://www.youtube.com/watch?v=ao6MyItVtYA', title: "ao6MyItVtYA", thumbnail: "https://img.youtube.com/vi/ao6MyItVtYA/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/ao6MyItVtYA.mp4'},
  { url: 'https://www.youtube.com/watch?v=DodFDnWLz6w', src: 'https://www.youtube.com/watch?v=DodFDnWLz6w', title: "DodFDnWLz6w", thumbnail: "https://img.youtube.com/vi/DodFDnWLz6w/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/DodFDnWLz6w.mp4'},
  { url: 'https://www.youtube.com/watch?v=KWI50zK-YRY', src: 'https://www.youtube.com/watch?v=KWI50zK-YRY', title: "KWI50zK-YRY", thumbnail: "https://img.youtube.com/vi/KWI50zK-YRY/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/KWI50zK-YRY.mp4'},
  { url: 'https://www.youtube.com/watch?v=RY_5WAGL3mE', src: 'https://www.youtube.com/watch?v=RY_5WAGL3mE', title: "RY_5WAGL3mE", thumbnail: "https://img.youtube.com/vi/RY_5WAGL3mE/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/RY_5WAGL3mE.mp4'},
  { url: 'https://www.youtube.com/watch?v=ugxHSjlHwLs', src: 'https://www.youtube.com/watch?v=ugxHSjlHwLs', title: "ugxHSjlHwLs", thumbnail: "https://img.youtube.com/vi/ugxHSjlHwLs/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/ugxHSjlHwLs.mp4'},
  { url: 'https://www.youtube.com/watch?v=0X40qyH57RA', src: 'https://www.youtube.com/watch?v=0X40qyH57RA', title: "0X40qyH57RA", thumbnail: "https://img.youtube.com/vi/0X40qyH57RA/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/0X40qyH57RA.mp4'},
  { url: 'https://www.youtube.com/watch?v=BlbIoThmxwQ', src: 'https://www.youtube.com/watch?v=BlbIoThmxwQ', title: "BlbIoThmxwQ", thumbnail: "https://img.youtube.com/vi/BlbIoThmxwQ/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/BlbIoThmxwQ.mp4'},
  { url: 'https://www.youtube.com/watch?v=F5bew0c8WGw', src: 'https://www.youtube.com/watch?v=F5bew0c8WGw', title: "F5bew0c8WGw", thumbnail: "https://img.youtube.com/vi/F5bew0c8WGw/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/F5bew0c8WGw.mp4'},
  { url: 'https://www.youtube.com/watch?v=lAq0QOjnQMM', src: 'https://www.youtube.com/watch?v=lAq0QOjnQMM', title: "lAq0QOjnQMM", thumbnail: "https://img.youtube.com/vi/lAq0QOjnQMM/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/lAq0QOjnQMM.mp4'},
  { url: 'https://www.youtube.com/watch?v=S0FUKoF9X14', src: 'https://www.youtube.com/watch?v=S0FUKoF9X14', title: "S0FUKoF9X14", thumbnail: "https://img.youtube.com/vi/S0FUKoF9X14/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/S0FUKoF9X14.mp4'},
  { url: 'https://www.youtube.com/watch?v=WLebQzwEEaI', src: 'https://www.youtube.com/watch?v=WLebQzwEEaI', title: "WLebQzwEEaI", thumbnail: "https://img.youtube.com/vi/WLebQzwEEaI/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/WLebQzwEEaI.mp4'},
  { url: 'https://www.youtube.com/watch?v=1BqJNZrFjB8', src: 'https://www.youtube.com/watch?v=1BqJNZrFjB8', title: "1BqJNZrFjB8", thumbnail: "https://img.youtube.com/vi/1BqJNZrFjB8/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/1BqJNZrFjB8.mp4'},
  { url: 'https://www.youtube.com/watch?v=bMeZZP70edE', src: 'https://www.youtube.com/watch?v=bMeZZP70edE', title: "bMeZZP70edE", thumbnail: "https://img.youtube.com/vi/bMeZZP70edE/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/bMeZZP70edE.mp4'},
  { url: 'https://www.youtube.com/watch?v=g0hwSDp4BuQ', src: 'https://www.youtube.com/watch?v=g0hwSDp4BuQ', title: "g0hwSDp4BuQ", thumbnail: "https://img.youtube.com/vi/g0hwSDp4BuQ/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/g0hwSDp4BuQ.mp4'},
  { url: 'https://www.youtube.com/watch?v=MFMZxWXBr3k', src: 'https://www.youtube.com/watch?v=MFMZxWXBr3k', title: "MFMZxWXBr3k", thumbnail: "https://img.youtube.com/vi/MFMZxWXBr3k/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/MFMZxWXBr3k.mp4'},
  { url: 'https://www.youtube.com/watch?v=S8TIUKoiPTo', src: 'https://www.youtube.com/watch?v=S8TIUKoiPTo', title: "S8TIUKoiPTo", thumbnail: "https://img.youtube.com/vi/S8TIUKoiPTo/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/S8TIUKoiPTo.mp4'},
  { url: 'https://www.youtube.com/watch?v=wZ6sXP1wu0Y', src: 'https://www.youtube.com/watch?v=wZ6sXP1wu0Y', title: "wZ6sXP1wu0Y", thumbnail: "https://img.youtube.com/vi/wZ6sXP1wu0Y/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/wZ6sXP1wu0Y.mp4'},
  { url: 'https://www.youtube.com/watch?v=4AoBYbigjIU', src: 'https://www.youtube.com/watch?v=4AoBYbigjIU', title: "4AoBYbigjIU", thumbnail: "https://img.youtube.com/vi/4AoBYbigjIU/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/4AoBYbigjIU.mp4'},
  { url: 'https://www.youtube.com/watch?v=c2wjLoEvm-Y', src: 'https://www.youtube.com/watch?v=c2wjLoEvm-Y', title: "c2wjLoEvm-Y", thumbnail: "https://img.youtube.com/vi/c2wjLoEvm-Y/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/c2wjLoEvm-Y.mp4'},
  { url: 'https://www.youtube.com/watch?v=I8lyTAmUD38', src: 'https://www.youtube.com/watch?v=I8lyTAmUD38', title: "I8lyTAmUD38", thumbnail: "https://img.youtube.com/vi/I8lyTAmUD38/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/I8lyTAmUD38.mp4'},
  { url: 'https://www.youtube.com/watch?v=_mFpUjygcQ0', src: 'https://www.youtube.com/watch?v=_mFpUjygcQ0', title: "_mFpUjygcQ0", thumbnail: "https://img.youtube.com/vi/_mFpUjygcQ0/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/_mFpUjygcQ0.mp4'},
  { url: 'https://www.youtube.com/watch?v=sRKqvICZadI', src: 'https://www.youtube.com/watch?v=sRKqvICZadI', title: "sRKqvICZadI", thumbnail: "https://img.youtube.com/vi/sRKqvICZadI/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/sRKqvICZadI.mp4'},
  { url: 'https://www.youtube.com/watch?v=Yl7SRegIflw', src: 'https://www.youtube.com/watch?v=Yl7SRegIflw', title: "Yl7SRegIflw", thumbnail: "https://img.youtube.com/vi/Yl7SRegIflw/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/Yl7SRegIflw.mp4'},
  { url: 'https://www.youtube.com/watch?v=4r0YsbG9XSs', src: 'https://www.youtube.com/watch?v=4r0YsbG9XSs', title: "4r0YsbG9XSs", thumbnail: "https://img.youtube.com/vi/4r0YsbG9XSs/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/4r0YsbG9XSs.mp4'},
  { url: 'https://www.youtube.com/watch?v=ccbiufW2tKY', src: 'https://www.youtube.com/watch?v=ccbiufW2tKY', title: "ccbiufW2tKY", thumbnail: "https://img.youtube.com/vi/ccbiufW2tKY/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/ccbiufW2tKY.mp4'},
  { url: 'https://www.youtube.com/watch?v=jcq6tJr9qlA', src: 'https://www.youtube.com/watch?v=jcq6tJr9qlA', title: "jcq6tJr9qlA", thumbnail: "https://img.youtube.com/vi/jcq6tJr9qlA/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/jcq6tJr9qlA.mp4'},
  { url: 'https://www.youtube.com/watch?v=MzDFpm8BT70', src: 'https://www.youtube.com/watch?v=MzDFpm8BT70', title: "MzDFpm8BT70", thumbnail: "https://img.youtube.com/vi/MzDFpm8BT70/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/MzDFpm8BT70.mp4'},
  { url: 'https://www.youtube.com/watch?v=StE4w8r6Qak', src: 'https://www.youtube.com/watch?v=StE4w8r6Qak', title: "StE4w8r6Qak", thumbnail: "https://img.youtube.com/vi/StE4w8r6Qak/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/StE4w8r6Qak.mp4'},
  { url: 'https://www.youtube.com/watch?v=YuUx8zcUoV0', src: 'https://www.youtube.com/watch?v=YuUx8zcUoV0', title: "YuUx8zcUoV0", thumbnail: "https://img.youtube.com/vi/YuUx8zcUoV0/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/YuUx8zcUoV0.mp4'},
  { url: 'https://www.youtube.com/watch?v=_9PkH9NjpFE', src: 'https://www.youtube.com/watch?v=_9PkH9NjpFE', title: "_9PkH9NjpFE", thumbnail: "https://img.youtube.com/vi/_9PkH9NjpFE/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/_9PkH9NjpFE.mp4'},
  { url: 'https://www.youtube.com/watch?v=D-77ROjC4R8', src: 'https://www.youtube.com/watch?v=D-77ROjC4R8', title: "D-77ROjC4R8", thumbnail: "https://img.youtube.com/vi/D-77ROjC4R8/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/D-77ROjC4R8.mp4'},
  { url: 'https://www.youtube.com/watch?v=jIWKHBizKEg', src: 'https://www.youtube.com/watch?v=jIWKHBizKEg', title: "jIWKHBizKEg", thumbnail: "https://img.youtube.com/vi/jIWKHBizKEg/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/jIWKHBizKEg.mp4'},
  { url: 'https://www.youtube.com/watch?v=QOv4WFdWadY', src: 'https://www.youtube.com/watch?v=QOv4WFdWadY', title: "QOv4WFdWadY", thumbnail: "https://img.youtube.com/vi/QOv4WFdWadY/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/QOv4WFdWadY.mp4'},
  { url: 'https://www.youtube.com/watch?v=tkgU6Fwv9VU', src: 'https://www.youtube.com/watch?v=tkgU6Fwv9VU', title: "tkgU6Fwv9VU", thumbnail: "https://img.youtube.com/vi/tkgU6Fwv9VU/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/tkgU6Fwv9VU.mp4'},
  { url: 'https://www.youtube.com/watch?v=zES5NuDdKro', src: 'https://www.youtube.com/watch?v=zES5NuDdKro', title: "zES5NuDdKro", thumbnail: "https://img.youtube.com/vi/zES5NuDdKro/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/zES5NuDdKro.mp4'},
  { url: 'https://www.youtube.com/watch?v=ADDJE4HtfVc', src: 'https://www.youtube.com/watch?v=ADDJE4HtfVc', title: "ADDJE4HtfVc", thumbnail: "https://img.youtube.com/vi/ADDJE4HtfVc/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/ADDJE4HtfVc.mp4'},
  { url: 'https://www.youtube.com/watch?v=dhWQ3FLu50c', src: 'https://www.youtube.com/watch?v=dhWQ3FLu50c', title: "dhWQ3FLu50c", thumbnail: "https://img.youtube.com/vi/dhWQ3FLu50c/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/dhWQ3FLu50c.mp4'},
  { url: 'https://www.youtube.com/watch?v=kgn2n5PV_34', src: 'https://www.youtube.com/watch?v=kgn2n5PV_34', title: "kgn2n5PV_34", thumbnail: "https://img.youtube.com/vi/kgn2n5PV_34/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/kgn2n5PV_34.mp4'},
  { url: 'https://www.youtube.com/watch?v=RoPDABOH6mU', src: 'https://www.youtube.com/watch?v=RoPDABOH6mU', title: "RoPDABOH6mU", thumbnail: "https://img.youtube.com/vi/RoPDABOH6mU/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/RoPDABOH6mU.mp4'},
  { url: 'https://www.youtube.com/watch?v=tsptJv9GemE', src: 'https://www.youtube.com/watch?v=tsptJv9GemE', title: "tsptJv9GemE", thumbnail: "https://img.youtube.com/vi/tsptJv9GemE/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/tsptJv9GemE.mp4'},
  { url: 'https://www.youtube.com/watch?v=ZKbEyrxFY48', src: 'https://www.youtube.com/watch?v=ZKbEyrxFY48', title: "ZKbEyrxFY48", thumbnail: "https://img.youtube.com/vi/ZKbEyrxFY48/1.jpg" , downloadLink: 'https://zipper.ams3.digitaloceanspaces.com/recommendation-videos/ZKbEyrxFY48.mp4'},
];




const VideoRecommendationsWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({name: '', _id: '', posts: []});

  return (
    <>

<div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <h1>המלצות וידאו עבור שותפים</h1>
                  <p>
                  כאן תוכלו לצפות, להוריד ולהשתמש בסרטוני וידאו של לקוחות מרוצים שממליצים על המוצר. 
                    סרטונים אלו נועדו לסייע לכם בקידום המוצר בצורה משכנעת ואמינה.
                  </p>
                  
                  <h2>איך תוכלו להשתמש בסרטונים?</h2>
                  
                  <ul>
                    <li>
                      <strong>שיתוף ברשתות החברתיות</strong>: פרסמו את סרטוני ההמלצות בעמודים שלכם בפייסבוק, אינסטגרם או כל רשת חברתית אחרת כדי 
                      להגביר את החשיפה ולהגביר את האמון של לקוחות פוטנציאליים.
                    </li>
                    <li>
                      <strong>שילוב באתרי נחיתה</strong>: תוכלו לשלב את סרטוני ההמלצות בעמודי הנחיתה שלכם, כדי להמחיש את הערך והיתרונות של המוצר בצורה חיה וברורה.
                    </li>
                    <li>
                      <strong>קמפיינים פרסומיים</strong>: השתמשו בסרטונים בקמפיינים ממומנים בגוגל, פייסבוק או יוטיוב כדי למשוך תשומת לב וליצור רושם ראשוני חזק.
                    </li>
                  </ul>
                  
                  <p>
                    הקפידו להתאים את הסרטונים לקהל היעד שלכם, ולהשתמש בהם בצורה יצירתית ומקצועית. בהצלחה במכירות!
                  </p>
                </div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>




<div direction="rtl" style={{direction: 'rtl'}}>



        
<div className='row g-5 g-xl-8'>


  <div className='col-xl-12 card-xl-stretch mb-xl-8'>
    <div className='card'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        <a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} alt='' />
          </div>
          <div className='fs-5 fw-bolder mb-2'>{'סרטוני המלצות לקוחות מרוצים'}</div>
        </a>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>{'43 סרטונים  - קצבי mp4'}</div>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'><a href='https://ams3.digitaloceanspaces.com/zipper/recommendation-videos/all_recommendation_videos.zip' className="btn btn-primary">להורדת הסרטונים לחץ כאן 📥</a></div>
      </div>
    </div>
  </div>



</div>
  <div className='row g-5 g-xl-8'>


   {/* להוסיף כאן ריבועים עם על הסרטונים וכפתור הורדה לכל אחד */}
   <div>
            <h1 className="text-center mt-4">סרטוני המלצות</h1>
            <VideoGallery videos={sampleVideos} />
        </div>


  </div>
</div>




    </>
  )
}

const AdvertisingPostsWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({name: '', _id: '', posts: []});

  useEffect(() => {
    axios.get(`${API_URL}/api/affiliate/content/posts`)
      .then((response: AxiosResponse) => {
        setCampaigns(response.data);
        setSelectedCampaign(response.data[0]);
      });
  }, []);

  return (
    <>

      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>






                <div>
  <h1>פוסטים מוכנים לפייסבוק עבור שותפים</h1>
  <p>
    כאן תוכלו למצוא פוסטים מוכנים לפרסום בפייסבוק שיעזרו לכם לקדם את המוצר בצורה אפקטיבית ומהירה. הפוסטים עוצבו ונכתבו בצורה שיווקית, 
    כך שכל מה שעליכם לעשות הוא להעתיק, להדביק ולפרסם!
  </p>
  
  <h2>איך להשתמש בפוסטים המוכנים?</h2>
  
  <ul>
  <li><strong>העתיקו את התוכן</strong>: בחרו את אחד הפוסטים, העתיקו את הטקסט והתמונה המצורפת (במידה ויש), והדביקו בעמוד הפייסבוק שלכם או בקבוצות בהן אתם חברים.</li>    <li>
      <strong>התאימו את הפוסט לצרכים שלכם</strong>: אם תרצו, תוכלו לערוך את הטקסט ולהתאים אותו לשפה האישית שלכם או לקהל היעד שלכם.
    </li>
    <li>
      <strong>פרסום מיידי</strong>: הפוסטים מוכנים לפרסום מיידי כך שתוכלו להתחיל למשוך לקוחות פוטנציאליים באופן מיידי. 
      מומלץ לשלב הפוסטים עם תגובות ושיתופים כדי להגדיל את האינטראקציה.
    </li>

    <li>
      <strong>מעבר בין קטגוריות</strong>: בעמוד זה תוכלו לעבור בין קטגוריות שונות של פוסטים באמצעות טאבים (לשוניות) שמאפשרים גישה מהירה 
      לפוסטים המוכנים לפי נושאים, כגון: תיווך, רכב, כוח אדם ועוד.
    </li>


  </ul>
  
  <p>
    הפוסטים נכתבו בצורה שמעודדת מעורבות ופעולה, כך שתוכלו להגדיל את המכירות במהירות ובקלות. בהצלחה!
  </p>
</div>




                </div>

                <img src={toAbsoluteUrl('/media/svg/brand-logos/facebook-2.svg')} alt='' className='align-self-end h-100px' />
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex overflow-auto h-55px" style={{direction: 'rtl'}}>
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
          {campaigns.map( (campaign) => <li className="nav-item">
            <a className={`nav-link text-active-primary me-6 ${campaign._id === selectedCampaign._id ? 'active' : ''}`} onClick={() => {
              setSelectedCampaign(campaign);
            }}>{campaign.name}</a>
          </li>)}
        </ul>
      </div>

      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>


          {selectedCampaign.posts.map((post) => <div className='col-xl-6 card-xl-stretch mb-xl-8'>
            <div className={`card`}>
              {/* begin::Body */}
              <div className='card-body pb-0'>
                {/* begin::Header */}
                <div className='d-flex align-items-center mb-5'>
                  {/* begin::User */}
                  <div className='d-flex align-items-center flex-grow-1'>
                    {/* begin::Avatar */}
                    <div className='symbol symbol-45px ms-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                    </div>
                    {/* end::Avatar */}

                    {/* begin::Info */}
                    <div className='d-flex flex-column'>
                      <a className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                        שמעון כהן
                      </a>

                      <span className='text-gray-400 fw-semibold'>{moment().subtract(1, 'hour').fromNow()}</span>
                    </div>
                    {/* end::Info */}
                  </div>
                  {/* end::User */}

                  {/* begin::Menu */}
                  <div className='my-0'>
                  </div>
                  {/* end::Menu */}
                </div>
                {/* end::Header */}

                {/* begin::Post */}
                <div className='mb-5'>
                  {/* begin::Text */}
                  <p className='text-gray-800 fw-normal mb-5'>
                    {post.post_content}
                  </p>
                  {/* end::Text */}

                  {/* begin::Toolbar */}
                  <div className='d-flex align-items-center mb-5'>
                    <a
                      href='#'
                      className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                    >
                      <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
                      120
                    </a>

                    <a
                      href='#'
                      className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                    >
                      <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
                      15
                    </a>
                  </div>
                  {/* end::Toolbar */}
                </div>
                {/* end::Post */}

                {/* begin::Separator */}
                <div className='separator mb-4'></div>
                {/* end::Separator */}

                {/* edit::Reply input */}
              </div>
              {/* end::Body */}
            </div>
          </div>)}




        </div>
      </div>




    </>
  )
}
const ColdCallingWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({name: '', _id: '', posts: []});

  return (
    <>


<div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>


                <h2>איך מביאים לידים/לקוחות מתוך קבוצות פייסבוק?</h2>
                  <p>כדי להביא לידים מתוך קבוצות פייסבוק בצורה אפקטיבית, יש כמה שיטות וצעדים שיכולים לעזור לך.</p>
  <p>לפני שמתחילים, חשוב להבין את סוג הקבוצות שאתה פועל בהן ואת סוג הקהל שנמצא בקבוצות אלו. חפש קבוצות בפייסבוק שהן רלוונטיות לתחום.</p>
  <p>לדוגמא: מכירה, השכרה, רכב, צימרים, בעלי עסקים, דרושים</p>
  <p>פנייה טלפונית - אחת השיטות האפקטיביות ביותר היא לחפש פוסטים שיש בהם את פרטי הקשר של המשתמש (כמו מספר טלפון, אם זמין בפרופיל או בפוסט עצמו) ולפנות אליו בשיחת טלפון עם ההצעה. השיחה הישירה תאפשר לכם להסביר בצורה טובה יותר את הערך שאתם מציעים.</p>


  <p>קבוצות פייסבוק מאפשרות לכם למצוא המון לידים איכותיים אם תעשו זאת בצורה נכונה. המפתח הוא למצוא פוסטים רלוונטיים, לפנות בצורה אישית ואדיבה, להציע פתרון אמיתי לבעיה, ולעקוב אחרי השיחות בצורה מסודרת.</p>
  <p>כל זה יעזור לכם להפוך את הלידים ללקוחות מרוצים.</p>
  <p>השתמשו במערכת לניהול לידים כדי לעקוב אחרי כל הלידים שאתם יוצרים מקבוצות פייסבוק.</p>
  <p>כך תוכלו לעקוב אחרי השיחות, ההתקדמות, והסטטוס של כל ליד – האם הם מעוניינים, האם הם צריכים פולו-אפ, או אם ביצעתם מכירה או שהם לא מעוניינים כלל.</p>




                </div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>



      <div direction="rtl" style={{direction: 'rtl'}}>
      <div className='row g-5 g-xl-8'>
<div className='col-xl-6 mx-auto'>            
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  דוגמאות שיחות מכירה - לידים קרים
                  </a>

                  <p>1. שיחת מכירות עם מתווך נדל"ן</p>
                  
                  <ReactAudioPlayer
                      src="https://zipper.ams3.cdn.digitaloceanspaces.com/audio/sales-example.mpeg"
                      controls
                    />


                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>

      <div direction="rtl" style={{direction: 'rtl'}}>
<div className='row g-5 g-xl-8'>
<div className='col-xl-6 mx-auto'>            
  
  <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                  <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  ריכזנו עבורכם את כל היתרונות של המוצר שאתם משווקים
                  </a>

                  <ul>
  <li>🛠 התוכנה המתקדמת בעולם - התוכנה המתקדמת בעולם לפרסום אוטומטי בקבוצות פייסבוק.</li>
  <li>🤖 פרסום אוטומטי בכל הקבוצות - עם התוכנה שלנו כל הפוסטים של הלקוחות יתפרסמו בצורה אוטומטית בכל הקבוצות פייסבוק הרלוונטיות, מ-7 בבוקר ועד 11 בלילה.</li>
  <li>💬 ניהול תגובות אוטומטי ותיוגים למתעניינים - המערכת שלנו לא רק מפרסמת את הפוסטים שלהם בקבוצות, אלא גם מנהלת את התגובות! כל מי שיגיב על הפוסטים, וירשום אשמח לפרטים, מחיר או כל שאלה, אנחנו נתייג את הפרופיל שלו ונגיב לו בחזרה את הפרטים של הלקוח.</li>
  <li>📩 מענה אוטומטי להודעות במסנג'ר - כל הודעה שתגיע למסנג'ר של הלקוח אנחנו נגיב בחזרה את הפרטים שלו ליצירת קשר.</li>
  <li>📤 פרסום גם במרקטפלייס של פייסבוק - בנוסף אנחנו דואגים לפרסם את הפוסטים של הלקוח גם במרקטפלייס של פייסבוק.</li>
  <li>🗂 מאגר הקבוצות הגדול בישראל - ברשותנו מאגר של כל הקבוצות שיש בישראל מפולחות לפי קטגוריות, תחומים, אזורים ועוד.</li>
  <li>👨‍💻 קמפיינר אישי שמנהל הכל - הלקוח מקבל קמפיינר צמוד שמנהל עבורו את כל הפרסום בצורה מקצועית ויעילה ושדואג לבצע עבורו את כל העבודה מהתחלה ועד הסוף, וחוץ מלספק לנו את התכנים לפרסום הלקוח לא יצטרך לגעת בכלום.</li>
  <li>💬 שירות לקוחות - שירות לקוחות זמין מדי יום בין השעות 9:00 ועד 17:00.</li>
  <li>💰 מחיר חסר תקדים של 300 ש"ח בחודש - והחלק הטוב ביותר? שכל זה במחיר חסר תקדים של 300 ש"ח בחודש בלבד – ואנחנו מתחייבים לכל הלקוחות למחיר הזול בעולם.</li>
</ul>                  


</div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>


      <div direction="rtl" style={{direction: 'rtl'}}>
      <div className='row g-5 g-xl-8'>
<div className='col-xl-6 mx-auto'>            
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                <a href='#' className='fw-bold text-dark fs-4 mb-2 text-hover-primary'>
                  תסריט שיחה לדוגמא
                  </a>

    <p><strong>נציג מכירות:</strong> שלום, "דניאל" [שם המתווך]?</p>
    <p><strong>מתווך (המתווך):</strong> כן מי מדבר?</p>
    <p><strong>נציג מכירות:</strong> מה שלומך אח יקר אני פונה אלייך בקשר לנכסים שאתה מפרסם בקבוצות פייסבוק ויש לי בשבילך הצעה מדהימה שלא תוכל לסרב לה.</p>
    <p><strong>מתווך:</strong> אני מקשיב.</p>
    <p><strong>נציג מכירות:</strong> פיתחנו תוכנה מדהימה לפרסום בקבוצות פייסבוק שדרכה אני יכול לפרסם את כל הנכסים שלך ובכל הקבוצות הרלוונטיות ומבלי שתיגע בכלום. בנוסף, התוכנה עוקבת אחרי כל הנכסים שפורסמו ובמידה ולקוחות יגיבו על הנכסים שלך וישאלו שאלות כמו "אשמח לפרטים" או "מחיר", אז התוכנה תתייג את הפרופיל שלהם ותגיב להם בחזרה את הפרטים שלך ליצירת קשר כמו טלפון או קישור לווצאפ. כל הטוב הזה במחיר מדהים של 10 ש"ח ביום ואני מתחייב לך למחיר הכי זול בעולם.</p>
    <p><strong>מתווך:</strong> נשמע מדהים/מעניין.</p>
    <p><strong>נציג מכירות:</strong> שמח לשמוע וזה אכן הצעה מדהימה/מטורפת וכמובן שהתוצאות ידברו בעד עצמם בשטח. בנוסף, אתה מקבל בחודש הראשון חודש פלוס 3 יום, מהסיבה שהימים הראשונים זה זמן התארגנות לבחור את הקבוצות, להעלות את הנכסים וכל ההקמה של המערכת.</p>
    <p>ואיך מתחילים? זה מאוד פשוט, אני צריך את השם פרטי והשם משפחה שלך, את המייל לפתוח עליו חשבון, את פרטי אשראי לתשלום ועל שם מי להוציא חשבונית ויצאנו לדרך.</p>
    <p>מה השם פרטי? מה השם משפחה? מה המייל שלך? מספר כרטיס? ועל שם מי להוציא חשבונית?</p>
    <p>מעולה, בזמן הקרוב ישלחו לך שם משתמש וסיסמא למערכת וקמפיינר מטעמנו ייצור קשר על מנת להתחיל פעילות. לבינתיים אני שולח לך את המספר של השירות לקוחות שלנו ליצירת קשר והחשבונית נשלחה במייל. הכרטיס נכנס להוראת קבע שאתה יכול לבטל בכל זמן. כמו שציינתי אתה לא מחוייב אף פעם להמשיך לחודש הבא ושבוע הבא אתה כבר יכול לעדכן שנוריד אותך מההוראת קבע ולא תחוייב חודש הבא. כמובן שאנחנו בטוחים שתקבל תוצאות מדהימות בשטח ושתרצה להמשיך איתנו לעוד המון שנים טובות ושנצליח בעזרת השם יתברך ימלך המשך יום מקסים (:</p>

      <hr/>

      <h1>התנגדויות ותשובות בשיחת מכירות</h1>
    <h2>שאלות ותשובות</h2>
    <div>
        <h3>שאלה: כמה פוסטים אתם תפרסמו לי ביום ואם הפרופיל לא מסתכן בחסימה?</h3>
        <p>תשובה: הפרופיל יפרסם עד גבול היכולת את המקסימום פוסטים שהוא יכול ומבלי להסתכן בחסימה.</p>
    </div>
    <div>
        <h3>שאלה: אם יש לנו כמה מתווכים במשרד יש מחיר מיוחד?</h3>
        <p>תשובה: שאלה מעולה, יש לנו מגוון חבילות כמובן שכמה שתרשמו יותר מתווכים המחיר למתווך יורד משמעותית (ראה חבילות באתר).</p>
    </div>
    <div>
        <h3>שאלה: האם התוכנה מפרסמת בשישי שבת?</h3>
        <p>תשובה: התוכנה שומרת שבת וחגי ישראל אבל ביום שישי התוכנה תפרסם עד כניסת שבת ובמוצ"ש היא תפרסם מיד בצאת השבת כך שאתה לא תפספס ימים ותנצל את המקסימום שמגיע לך בחבילה.</p>
    </div>



                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

const EmailContentWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({name: '', _id: '', posts: []});

  return (
    <>

      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>


                <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333', padding: '20px', maxWidth: '600px', margin: 'auto' }}>

      <h3 style={{ color: '#34495e' }}>איך להשתמש במיילים שתקבלו?</h3>
      <ol style={{ paddingLeft: '20px' }}>
        <li>
          <strong>קבלו את המיילים המוכנים שלנו:</strong> תקבלו מאיתנו מיילים שיווקיים שנכתבו בצורה מקצועית וממוקדת, כך שתוכלו לשלוח אותם ישירות לרשימות התפוצה שלכם.
        </li>
        <li>
          <strong>התאימו את התוכן לקהל שלכם:</strong> אם תרצו, תוכלו להוסיף מגע אישי או התאמות קלות כדי לוודא שהמייל מתאים בצורה מיטבית לקהל היעד שלכם.
        </li>
        <li>
          <strong>הוסיפו את קישור השותפים שלכם:</strong> כל מייל יכיל מקום ברור להוספת קישור השותפים הייחודי שלכם. כך תוכלו לוודא שכל קליק ורכישה יעניקו לכם עמלה ישירה.
        </li>
        <li>
          <strong>שלחו ללקוחות שלכם:</strong> הפיצו את המייל לרשימת התפוצה שלכם ותנו לתוכן השיווקי שלנו לעבוד בשבילכם!
        </li>
        <li>
          <strong>התחילו להרוויח:</strong> כל מכירה שמתבצעת דרך קישור השותפים שלכם תזכה אתכם בעמלה. ככל שתשלחו את המיילים לקהל רחב יותר, כך תוכלו להרוויח יותר.
        </li>
      </ol>

      <p style={{ fontWeight: 'bold', color: '#e74c3c', textAlign: 'center' }}>
        לא לשכוח להשתמש בקישור השותפים שלכם במקום הרלוונטי
      </p>
    </div>
                  <h3>אימייל 1</h3>

                  <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333', padding: '20px', maxWidth: '600px', margin: 'auto', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h2 style={{ textAlign: 'center', color: '#2c3e50' }}>אוטומציה מלאה לפרסום בפייסבוק – רק ב-300 ש"ח לחודש!</h2>
      <p>שלום <strong>[שם הלקוח]</strong>,</p>
      <p>נמאס לכם לנהל את הפוסטים שלכם בפייסבוק ידנית? יש לנו את הפתרון המושלם בשבילכם! עם התוכנה המתקדמת שלנו, תוכלו לנהל את כל פרסום הקבוצות וההודעות שלכם בצורה אוטומטית לחלוטין.</p>
      
      <h3>הנה מה שתקבלו עם התוכנה שלנו:</h3>
      <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
        <li>
          🚀 <strong>התוכנה המתקדמת ביותר לפרסום אוטומטי בקבוצות פייסבוק</strong><br />
          תפרסמו את התכנים שלכם בצורה אוטומטית בכל הקבוצות הרלוונטיות, מ-7 בבוקר ועד 11 בלילה.
        </li>
        <li>
          🤖 <strong>ניהול תגובות ותיוגים אוטומטי</strong><br />
          המערכת שלנו תדאג גם לתגובות! כל מי שיגיב לפוסטים שלכם וישאל שאלה או יבקש פרטים, אנחנו נתג את הפרופיל שלו וניתן לו מענה עם כל המידע הדרוש.
        </li>
        <li>
          📩 <strong>מענה אוטומטי להודעות במסנג'ר</strong><br />
          הודעות נכנסות למסנג'ר? אין בעיה, אנחנו נטפל בהן עבורכם ונשלח ללקוחות את הפרטים ליצירת קשר.
        </li>
        <li>
          📤 <strong>פרסום במרקטפלייס של פייסבוק</strong><br />
          התכנים שלכם יפורסמו גם במרקטפלייס של פייסבוק כדי למקסם את החשיפה שלכם.
        </li>
        <li>
          🗂 <strong>מאגר הקבוצות הגדול ביותר בישראל</strong><br />
          ברשותנו מאגר ענק של כל קבוצות הפייסבוק בישראל, מסווגות לפי קטגוריות, תחומים ואזורים – כך שתגיעו בדיוק לקהל הנכון.
        </li>
        <li>
          👨‍💻 <strong>קמפיינר אישי שינהל את הכל עבורכם</strong><br />
          לא צריך לגעת בכלום! קמפיינר מקצועי ינהל עבורכם את כל הפרסומים, מתחילת התהליך ועד סופו, ואתם רק תספקו לנו את התכנים.
        </li>
        <li>
          💬 <strong>שירות לקוחות זמין</strong><br />
          השירות שלנו זמין עבורכם מדי יום בין השעות 9:00 ועד 17:00 לכל שאלה או תמיכה.
        </li>
        <li>
          💰 <strong>מחיר חסר תקדים – רק 300 ש"ח בחודש</strong><br />
          והחלק הכי טוב? כל זה במחיר שלא תמצאו בשום מקום אחר – רק 300 ש"ח בחודש!
        </li>
      </ul>

      <p>אל תחמיצו את ההזדמנות הזו – אוטומציה לפרסום בפייסבוק שתקל עליכם ותביא לכם תוצאות, במחיר משתלם במיוחד!</p>

      <p>לפרטים נוספים והרשמה, השיבו להודעה זו או <a href="#" style={{ color: '#3498db', textDecoration: 'none' }}>לחצו כאן</a>.</p>

      <p>בברכה,<br />[השם שלכם]<br />צוות [שם החברה]</p>
    </div>


    <h3>אימייל 2</h3>

    <div style={{ fontFamily: 'Verdana, sans-serif', color: '#4a4a4a', padding: '25px', maxWidth: '650px', margin: 'auto', border: '2px solid #f1f1f1', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
      <h2 style={{ textAlign: 'center', color: '#2e86de' }}>אוטומציה מקצועית לפרסום בפייסבוק – החל מ-300 ש"ח לחודש!</h2>
      <p>שלום <strong>[שם הלקוח]</strong>,</p>
      <p>בזבוז זמן על פרסום ידני בפייסבוק? יש לנו את הכלי המושלם בשבילך! עם הפתרון האוטומטי שלנו, כל הפרסומים שלך בקבוצות פייסבוק יתנהלו בצורה מקצועית ויעילה ללא מגע יד אדם.</p>
      
      <h3 style={{ color: '#34495e' }}>מה כולל השירות שלנו?</h3>
      <ol style={{ paddingLeft: '20px' }}>
        <li>
          🚀 <strong>פרסום אוטומטי 24/7</strong><br />
          מערכת חכמה שתפרסם את התכנים שלכם מסביב לשעון בכל קבוצות הפייסבוק הרלוונטיות.
        </li>
        <li>
          💬 <strong>מענה אוטומטי לתגובות</strong><br />
          ניהול שוטף של התגובות – כל שאלה או תגובה יקבלו מענה מקצועי ויעיל.
        </li>
        <li>
          📩 <strong>מענה מידי בהודעות פרטיות</strong><br />
          כל הודעה שתגיע למסנג'ר תטופל באופן מיידי על ידי המערכת שלנו, כולל שליחת פרטי יצירת קשר.
        </li>
        <li>
          📤 <strong>פרסום גם במרקטפלייס של פייסבוק</strong><br />
          נרחיב את החשיפה שלכם גם לפרסום במרקטפלייס של פייסבוק.
        </li>
        <li>
          👨‍💻 <strong>מנהל קמפיין אישי</strong><br />
          קמפיינר צמוד שידאג לכל תהליך הפרסום, ויביא לכם תוצאות מקסימליות.
        </li>
      </ol>

      <p style={{ color: '#2e86de', fontWeight: 'bold' }}>המחיר המשתלם ביותר – רק 300 ש"ח לחודש!</p>

      <p>השירות שלנו מתאים לעסקים שרוצים להרחיב את הנוכחות שלהם בפייסבוק ולמקסם תוצאות, בקלות ובמהירות.</p>

      <p>לפרטים נוספים, <a href="#" style={{ color: '#e74c3c', textDecoration: 'none' }}>צרו איתנו קשר כאן</a>.</p>

      <p>בברכה,<br />[השם שלכם]<br />צוות [שם החברה]</p>
    </div>




    <h3>אימייל 3</h3>
    <div style={{ fontFamily: 'Tahoma, sans-serif', color: '#2d3436', padding: '30px', maxWidth: '680px', margin: 'auto', backgroundColor: '#ffffff', border: '3px solid #dfe6e9', borderRadius: '12px' }}>
      <h1 style={{ color: '#e17055', textAlign: 'center' }}>הפתרון המושלם לפרסום אוטומטי בפייסבוק!</h1>
      <p>שלום <strong>[שם הלקוח]</strong>,</p>
      <p>תדמיינו עולם שבו כל הפרסום בפייסבוק מתנהל בצורה אוטומטית, מדויקת, ויעילה. עכשיו זה אפשרי עם המערכת המתקדמת שלנו!</p>

      <div style={{ backgroundColor: '#f5f6fa', padding: '20px', borderRadius: '8px', border: '1px solid #dcdde1' }}>
        <h3 style={{ color: '#0984e3' }}>מה תקבלו?</h3>
        <ul style={{ paddingLeft: '20px', lineHeight: '1.8' }}>
          <li>📢 <strong>פרסום אוטומטי בקבוצות פייסבוק</strong> – תפרסמו את כל התכנים שלכם בכל הקבוצות שאתם צריכים, בלי מאמץ.</li>
          <li>⚡ <strong>תגובתיות אוטומטית למסנג'ר</strong> – כל הודעה שמגיעה תיענה מיד.</li>
          <li>🔥 <strong>חשיפה במרקטפלייס</strong> – לא רק קבוצות, גם במרקטפלייס של פייסבוק נחשוף את התכנים שלכם!</li>
          <li>👥 <strong>מאגר קבוצות עצום</strong> – גישה לקבוצות הכי רלוונטיות בישראל, מסודרות לפי נושאים ותחומים.</li>
          <li>🎯 <strong>קמפיינר אישי</strong> – מומחה לפרסום שידאג לכם מא' עד ת'.</li>
        </ul>
      </div>

      <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#d63031', textAlign: 'center' }}>כל זה ב-300 ש"ח לחודש בלבד!</p>

      <p>המערכת שלנו חוסכת זמן יקר ומביאה תוצאות בטוחות – תנו לנו לדאוג לפרסום שלכם בצורה הטובה ביותר.</p>

      <p style={{ textAlign: 'center' }}><a href="#" style={{ backgroundColor: '#74b9ff', color: '#fff', padding: '10px 20px', borderRadius: '5px', textDecoration: 'none' }}>הצטרפו עכשיו!</a></p>

      <p>בברכה,<br />[השם שלכם]<br />צוות [שם החברה]</p>
    </div>



                </div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>




    </>
  )
}

const AdvertisementVideosWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({name: '', _id: '', posts: []});

  return (
    <>

      <div direction="rtl" style={{direction: 'rtl'}}>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6 mx-auto'>
            <div className={`card card-xl-stretch mb-xl-8`}>
              {/* begin::Body */}
              <div className='card-body d-flex align-items-center pt-3 pb-0'>
                <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>


                  <h1>שותף יקר</h1>
                  <p>בדף זה נציג לך את התוכן הפרסומי שמוכן עבורך לשימוש. התוכן הזה יאפשר לך ליצור לידים איכותיים ולהגביר את המכירות שלך</p>




                </div>

              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>




    </>
  )
}



export {AffiliateContentWrapper, VideoRecommendationsWrapper, AdvertisingPostsWrapper, ColdCallingWrapper, EmailContentWrapper, AdvertisementVideosWrapper}
