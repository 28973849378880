// @ts-nocheck fff

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'
import axios from 'axios'
import {useState, useEffect} from 'react'
import {WithContext as ReactTags, SEPARATORS} from 'react-tag-input';

const API_URL = process.env.REACT_APP_API_URL

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
    return num.toString();
  }
}

const TablesWidget10: React.FC<Props> = ({campaign, className}) => {
  const intl = useIntl()



  const [loadingPosts, setLoadingPosts] = useState(false);
  const [campaignGroups, setCampaignGroups] = useState(campaign.groups || []);
  const [filterBy, setFilterBy] = useState('keywords');
  const [sortBy, setSortBy] = useState('');
  const [filter, setFilter] = useState('');

  const handleSelectSortByChange = (event) => {
    setSortBy(event.target.value);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };


  const [tags, setTags] = React.useState<Array<Tag>>([
    /*{ id: 'Thailand', text: 'Thailand', className: '' },
    { id: 'India', text: 'India', className: '' },
    { id: 'Vietnam', text: 'Vietnam', className: '' },
    { id: 'Turkey', text: 'Turkey', className: '' },*/
  ]);

  const handleDelete = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const onTagUpdate = (index: number, newTag: Tag) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1, newTag);
    setTags(updatedTags);
  };

  const handleAddition = (tag: Tag) => {
    setTags((prevTags) => {
      return [...prevTags, tag];
    });
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const onClearAll = () => {
    setTags([]);
  };


  const suggestions = [
    { id: "1", name: "mango" },
    { id: "2", name: "pineapple"},
    { id: "3", name: "orange" },
    { id: "4", name: "pear" }
];

  const toggleGroup = (group_id) => {
    const index = campaignGroups.indexOf(group_id);
    
    if (index === -1) {
      campaignGroups.push(group_id);
    } else {
      campaignGroups.splice(index, 1);
    }
    setCampaignGroups(JSON.parse(JSON.stringify(campaignGroups)))
  }

  const addGroups = (groups) => {
    var groups_to_add = groups.map((a) => a._id);

    setCampaignGroups(JSON.parse(JSON.stringify([...new Set(campaignGroups.concat(groups_to_add))])))
  }
  const save = async (groups) => {
    // alert(123)
      if (campaign._id) {
        try {
          var data = await axios.post(`${API_URL}/api/save-campaign-groups`, {campaignGroups: campaignGroups, _id: campaign._id});
        } catch (e) {
          alert('error')
        }
  
      }


  }
  const removeGroups = (groups) => {
    var groups_to_remove = groups.map((a) => a._id);

    setCampaignGroups(JSON.parse(JSON.stringify(campaignGroups.filter(item => !groups_to_remove.includes(item)))))
  }

  const [groups, setGroups] = useState([]);
  const [count, setCount] = useState(0);


  useEffect(() => {
    (async () => {
      try {
        setLoadingPosts(true)
        var data = await axios.post(`${API_URL}/api/groups`, {sort_by: sortBy, filter: filter, search_tags: tags, filter_by: filterBy, groups: campaignGroups});
        setGroups(data.data.groups);
        setCount(data.data.count);
        setLoadingPosts(false);
      } catch (e) {
        alert('error')
      }

   })();
  }, [tags, filterBy, sortBy, filter]);

  useEffect(() => {
    (async () => {
      
      setCampaignGroups(campaign.groups || []);

    })();
  }, [campaign]);

  // useEffect(() => {
  //   (async () => {
  //     if (campaign._id) {
  //       try {
  //         var data = await axios.post(`${API_URL}/api/save-campaign-groups`, {campaignGroups: campaignGroups, _id: campaign._id});
  //       } catch (e) {
  //         alert('error')
  //       }
  
  //     }
  //  })();
  // }, [campaignGroups]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: 'Select Groups'})}</span>
          {/*<span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span>*/}
          <span className='text-muted mt-1 fw-semibold fs-7'>{intl.formatMessage({id: 'x groups selected with x members'}, { groups: campaignGroups.length, members: 20 })}</span>
        </h3>
        
        {/*<div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            New Member
          </a>
        </div>*/}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive' style={{direction: 'rtl'}}>





<div className="">
  <div className="group-search">
    <div>
      <div className="sss">
        <div className="row filter-section-parant">
          <div className="col-md-6 sort-by-option">
            <label>מיין לפי</label>
            <select className="form-control" value={sortBy} onChange={handleSelectSortByChange}>
              <option value="">מיין לפי</option>
              <option value="users_in_group_count">כמות פרופילים בקבוצה</option>
              <option value="follow_count">כמות עוקבים בקבוצה</option>
            </select>
          </div>
          <div className="col-md-6 filter-by-option">
            <label>סטטוס הקבוצה</label>
            <select className="form-control" value={filter} onChange={handleFilterChange}>
              <option value="">הכל</option>
              <option value="automatic_approval">פרסום חופשי</option>
              <option value="not_automatic_approval">לא פרסום חופשי</option>
              <option value="closed_groups">קבוצות סגורות</option>
            </select>
          </div>
          {/*<div className="col-md-4 filter-by-profile dddd">
            <label>ספריה</label>

            <select className="form-control">
              <option>הכל</option>
              <option value="automatic_approval">פרסום חופשי</option>
              <option value="not_automatic_approval">לא פרסום חופשי</option>
              <option value="closed_groups">קבוצות סגורות</option>
            </select>
          </div>*/}
        </div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="search-section" style={{marginTop: '20px'}}>
      <div className="input-group" style={{paddingBottom: '10px'}}>
        <div aria-labelledby="fruits" className="rti--container">
      {<div>
        <ReactTags
          
          tags={tags}
          separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
          handleDelete={handleDelete}
          placeholder={'בחר מילת חיפוש ולחץ על ENTER'}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          onTagUpdate={onTagUpdate}
          inputFieldPosition="bottom"
          onClearAll={onClearAll}
          maxTags={10}
        />
        </div>}

        </div>
      </div>
    </div>
  </div>
  <div className="search-section" style={{marginTop: '20px'}}></div>
  <div className="col-md-12s">
    <div className="group-info group-info-banner" style={{
      background: '#d9e8ed',
      lineHeight: '33px',
      padding: '5px 10px 5px 0',
      borderRadius: '32px',
    }}>
      <strong>מציג:</strong> {groups.length} קבוצות  מתוך {count}
      <span className="by-selected-section" style={{float: 'left'}}>איזה קבוצות להציג? &nbsp;
        <label className="options" label="Female">הכל
          <input type="checkbox" checked={filterBy === 'keywords'} onClick={() => setFilterBy('keywords')}/>
        </label>
        <label className="options" label="Female">מסומן
          <input type="checkbox" checked={filterBy === 'selected'} onClick={() => setFilterBy('selected')}/>
        </label>
        <label className="options" label="Female">לא מסומן
          <input type="checkbox"  checked={filterBy === 'not_selected'}onClick={() => setFilterBy('not_selected')}/>
        </label>
      </span>
    </div>
  </div>
</div>






        <div className="d-flex align-items-center flex-shrink-0">
            <span className="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">
            {intl.formatMessage({id: 'Filter By'})}:
            </span>
            {/*<div className="flex-shrink-0 ">
              <ul className="nav" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${filterBy === 'keywords' ? 'active': ''}`}
                    onClick={() => setFilterBy('keywords')}
                  >
                    {intl.formatMessage({id: 'Keywords'})}
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${filterBy === 'selected' ? 'active': ''}`}
                    onClick={() => setFilterBy('selected')}
                  >
                    {intl.formatMessage({id: 'Selected Groups'})}
                  </a>
                </li>
              </ul>
            </div>*/}
            <div className="flex-shrink-0 ">
              <ul className="nav" role="tablist">
                <li className="nav-item" role="presentation" style={{
                  position: 'relative',
                  width: '120px',
                  height: '49px',
                  lineHeight: '49px',
                }}

                 onClick={() => addGroups(groups.filter((a) => !campaignGroups.find((b) => a._id == b )))}
                >
                  <span className={`position-absolute bi bi-check-square-fill text-success'}`} style={{
                    right: '-29px',
                    top: '0px',
                    fontSize: '24px',
                    zIndex: 1,
                  }}></span>
                  סמן הכל ({groups.filter((a) => !campaignGroups.find((b) => a._id == b )).length})
                </li>
                <li className="nav-item" role="presentation" style={{
                  position: 'relative',
                  width: '120px',
                  height: '49px',
                  lineHeight: '49px',
                }}
                 onClick={() => removeGroups(groups.filter((a) => campaignGroups.find((b) => a._id == b )))}
                >
                  <span className={`position-absolute bi bi-square text-success'}`} style={{
                    right: '-29px',
                    top: '0px',
                    fontSize: '24px',
                    zIndex: 1,
                  }}></span>
                  הורד הכל ({groups.filter((a) => campaignGroups.find((b) => a._id == b )).length})
                </li>
              </ul>
            </div>
            <div className="flex-shrink-0 ">
            </div>
          </div>


















{/* begin::Grid */}
<div style={{ height: '500px', overflow: 'scroll' }}>
  <div className="row g-4">
    {loadingPosts && (
      <div className="col-12">
        <span className='indicator-progress' style={{ display: 'block' }}>
          Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      </div>
    )}

    {!loadingPosts && groups.map((group, index) => (
      <div className="col-md-3" key={index}>
        <div 
          className={`card position-relative ${campaignGroups.includes(group._id) ? 'bg-primary-selected bg-light' : 'bg-light'}`}
          onClick={() => toggleGroup(group._id)}
          style={{ cursor: 'pointer', padding: '22px' }}
        >
          <span className={`position-absolute top-0 end-0 p-2 ${campaignGroups.includes(group._id) ? 'bi bi-check-square-fill text-success' : 'bi bi-square text-secondary'}`} style={{ fontSize: '24px', zIndex: 1 }}></span>
          <img className="card-img-top" src={group.image_url} alt={group.name} style={{ opacity: campaignGroups.includes(group._id) ? 0.7 : 1, borderRadius: '50%', width: '180px', height: '180px' }} />
          <div className="card-body">
            <h5 className="card-title">
              <a href={`http://facebook.com/${group.group_id}`} target="_blank" className={`${campaignGroups.includes(group._id) ? 'text-dark' : 'text-dark'} fw-bold text-hover-primary fs-6`}>
                {group.name}
              </a>
            </h5>
            <p className="card-text">{formatNumber(group.follow_count)} followers</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>
{/* end::Grid */}



  
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

                        <div className="modal-footer">
                          <button onClick={save.bind(this)} type="button" className="btn btn-primary" data-bs-dismiss="modal" >
                            {intl.formatMessage({id: 'Save'})}
                          </button>
                        </div>


    </div>
  )
}

export {TablesWidget10}
