/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../../_metronic/helpers'
import {StepProps} from '../IAppModels'
import {useIntl} from 'react-intl'

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const intl = useIntl()

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_NAME'})}</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique app name'
            ></i>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='appname'
            placeholder=''
            value={data.appBasic.appName}
            onChange={(e) =>
              updateData({
                appBasic: {
                  appName: e.target.value,
                  appCategory: data.appBasic.appCategory,
                },
              })
            }
          />
          {!data.appBasic.appName && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                {intl.formatMessage({id: 'GENERAL.CAMPAIGN_NAME_REQUIRED'})}
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row'>


          {/* begin::Label */}
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY'})}</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your app category'
            ></i>
          </label>
          {data.appBasic.appCategory === 'other' && <span>
            <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-danger me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                {intl.formatMessage({id: 'GENERAL.CAMPAIGN_OTHER_ERROR'})}
              </div>
            </div>

            {/*<div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                {intl.formatMessage({id: 'GENERAL.CAMPAIGN_OTHER_ERROR'})}
              </div>
            </div>*/}


          </span>}
          {/* end::Label */}
          <div>
            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_REALESTATE'})}</span>
                  <span className='fs-7 text-muted'>
                    {intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_REALESTATE_SLOGEN'})}
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appCategory'
                  value='realestate'
                  checked={data.appBasic.appCategory === 'realestate'}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appCategory: 'realestate',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_HR'})}</span>
                  <span className='fs-7 text-muted'>
                    {intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_HR_SLOGEN'})}
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appCategory'
                  value='hr'
                  checked={data.appBasic.appCategory === 'hr'}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appCategory: 'hr',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_CARS'})}</span>
                  <span className='fs-7 text-muted'>
                    {intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_CARS_SLOGEN'})}
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appCategory'
                  value='cars'
                  checked={data.appBasic.appCategory === 'cars'}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appCategory: 'cars',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_SHORT_TERM_RENTAL'})}</span>
                  <span className='fs-7 text-muted'>
                    {intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_SHORT_TERM_RENTAL_SLOGEN'})}
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appCategory'
                  value='short-term-rental'
                  checked={data.appBasic.appCategory === 'short-term-rental'}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appCategory: 'short-term-rental',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>{intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_OTHER'})}</span>
                  <span className='fs-7 text-muted'>
                    {intl.formatMessage({id: 'GENERAL.CAMPAIGN_CATEGORY_OTHER_SLOGEN'})}
                  </span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='appCategory'
                  value='other'
                  checked={data.appBasic.appCategory === 'other'}
                  onChange={() =>
                    updateData({
                      appBasic: {
                        appName: data.appBasic.appName,
                        appCategory: 'other',
                      },
                    })
                  }
                />
              </span>
            </label>
            {/*end::Option */}


          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {Step1}
