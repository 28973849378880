// @ts-nocheck

import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import moment from 'moment'
import 'moment/locale/he'; // Import Hebrew locale
import {phone} from 'phone'
import React, {FC, useState, useEffect} from 'react'
import axios, {AxiosResponse} from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ReactAudioPlayer from 'react-audio-player';
import { Link, useParams, useNavigate } from 'react-router-dom';

import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6,
} from '../../../_metronic/partials/widgets'

const API_URL = process.env.REACT_APP_API_URL

const AffiliateLeadsWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  moment.locale('he');

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [leads, setLeads] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [phoneSearchMap, setPhoneSearchMap] = useState({});
  const [phoneObj, setPhoneObj] = useState({});
  const [newComment, setNewComment] = useState('');
  const [savingInfo, setSavingInfo] = useState(false);
  const [selectedLead, setSelectedLead] = useState({});

  const [leadData, setLeadData] = useState({
    name: '',
    lead_source: 'social_media',
    lead_warm_status: 'cold',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeadData({ ...leadData, [name]: value });
  };
  const handleChangeLead = (e) => {
    const { name, value } = e.target;
    setSelectedLead({ ...selectedLead, [name]: value });
  };
  const handleChangeComment = (e) => {
    const { name, value } = e.target;
    setNewComment(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(leadData); // Handle form submission logic

    leadData.phone = phoneObj.phoneNumber;

    let new_lead = await axios.post(`${API_URL}/api/affiliate/lead/create`, leadData);

    navigate(`/affiliate/leads/${new_lead.data._id}`);

    setLeadData({
      name: '',
      lead_source: 'social_media',
      lead_warm_status: 'cold'  
    });

    fetchLeads();
    setSearchText('')
  };

  const handleSubmitLead = async (e) => {
    e.preventDefault();

    setSavingInfo(true)
    await axios.post(`${API_URL}/api/affiliate/lead/edit/${selectedLead._id}`, selectedLead);
    setSavingInfo(false)
    fetchLeads();
  };
  const handleSubmitFirstPaymentStep = async (e) => {
    e.preventDefault();

    setSavingInfo(true)
    var cardcom_result = await axios.post(`${API_URL}/api/affiliate/lead/first-payment-step/${selectedLead._id}`, selectedLead);
    selectedLead.cardcom_url = cardcom_result.data.url;
    setSelectedLead(selectedLead);
    setSavingInfo(false)
    fetchLeads();
  };
  const handleSubmitComment = async (e) => {
    e.preventDefault();

    setSavingInfo(true)
    var lead = await axios.post(`${API_URL}/api/affiliate/lead/add-comment/${selectedLead._id}`, {comment: newComment});
    setSelectedLead(lead.data);
    setNewComment('');
    setSavingInfo(false)
    fetchLeads();
  };

  async function fetchLeads() {
    var _leads = await axios.get(`${API_URL}/api/affiliate/leads`);
    setLeads(_leads.data);
    if (!id && _leads.data.length > 0) {
      navigate(`/affiliate/leads/${_leads.data[0]._id}`);
    }
  }

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {




    if (id) {
      async function GetLead() {
        var lead = await axios.get(`${API_URL}/api/affiliate/lead/${id}`);
        setSelectedLead(lead.data);
      }
      GetLead();

    } else {
      setSelectedLead({});
    }
    
    if (!id && leads.length > 0) {
      navigate(`/affiliate/leads/${leads[0]._id}`);
    }

    // fetchLeads();
  }, id);
  
  useEffect(() => {
    async function SearchLead() {
      var _phone = phone(searchText, {country: 'IL'});
      
      if (_phone.isValid) {
        var _leads = await axios.get(`${API_URL}/api/affiliate/leads/search?phone=${_phone.phoneNumber}`);
        phoneSearchMap[_phone.phoneNumber] = _leads.data;
        setPhoneSearchMap(JSON.parse(JSON.stringify(phoneSearchMap)));
      }
      setPhoneObj(_phone);
      // alert(searchText)
      // var _leads = await axios.get(`${API_URL}/api/affiliate/leads`);
      // setLeads(_leads.data);
    }
    SearchLead();
  }, [searchText]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };


  return (
    <>
      <div direction="rtl" style={{direction: 'rtl'}}>

{/*
      <h1>שלום לשותפים היקרים!</h1>
  <p>בדף זה נציג לכם את עמוד ניהול הלידים בתוכנית השותפים של AutoPost. כאן תוכלו לנהל את כל הלידים שנוצרו בעקבות פעילות השיווק שלכם ולהפוך אותם ללקוחות פוטנציאליים.</p>
  <p>בעמוד הזה תמצאו את כל הלידים שלכם. יש לכם אפשרות לחפש לידים לפי מספר טלפון, לראות את כל המידע החשוב כולל שם, מספר טלפון, מקור הליד, וסוג הליד – קר או חם.</p>
  
  <h3>ליד לדוגמה:</h3>
  <p>דניאל ג'מיל</p>
  <p>טלפון: 0524444444</p>
  <p>מקור הליד: רשתות חברתיות</p>
  <p>סטטוס: ליד קר</p>

  <p>לחיצה על ליד מסוים תוביל אתכם לעמוד עם פרטי הלקוח. כאן תוכלו לעדכן את סטטוס הליד – האם מדובר בליד קר, חם, או במצב אחר. אם ליד מסוים לא ענה או אם אתם צריכים לעקוב אחריו (פולו-אפ), תוכלו לעדכן את הסטטוס בהתאם.</p>
  <p>בנוסף, תוכלו להוסיף הערות לגבי הליד, שיסייעו לכם במעקב עתידי.</p>

  <p>במידה והלקוח מעוניין להעביר תשלום</p>
  <p>כאן תוכלו למלא את פרטי הלקוח לקבלת חשבונית, כולל שם, אימייל וסוג העסק. לאחר מכן, בלחיצת כפתור, תעברו לשלב הבא – שזה הזנת פרטי כרטיס האשראי לסגירת העסקה.</p>

  <p>בשלב זה תוכלו לשלוח הקלטות, הסכם ללקוח, סרטוני המלצות וקישור לתשלום עם הקישור הייחודי שלכם אוטומטית ללקוחות שמעוניינים בפרטים נוספים</p>
  <ul>
    <li>שליחת אודיו מכירה ישירות אוטומטי ב-WhatsApp.</li>
    <li>שליחת חוזה אוטומטי ללקוח.</li>
    <li>שליחת סרטוני המלצה אוטומטי לחיזוק האמון.</li>
    <li>שליחת קישור תשלום לסגירת העסקה</li>
  </ul>

  <p>לכל ליד יש ציר זמן שמציג את כל הפעולות שנעשו עד כה – מתי נוצר הליד, הערות שהוספתם, ועוד. זה עוזר לכם לראות את ההתקדמות ולוודא שאתם עוקבים אחרי כל ליד.</p>

  <p>בשדה זה תוכל לראות את הלידים שהוספת למערכת ולנהל אותם</p>

*/}
      <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <form className='w-100 position-relative' autoComplete='off'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder={intl.formatMessage({id: 'Enter phone number..'})}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </form>
          </div>

          <div className='card-body pt-5' id='kt_chat_contacts_body'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >

                  {phoneObj.isValid && !phoneSearchMap[phoneObj.phoneNumber]?._id && <div className="ms-5 alert alert-success" role="alert">
                      <h4 className="alert-heading">{intl.formatMessage({id: 'Lead available!'})}</h4>
                      <p>{intl.formatMessage({id: 'The phone number you entered was not found in our system. and you are free to add it as a lead.'})}</p>
                      <hr/>
      <form onSubmit={handleSubmit}>
        {/* Name Input */}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Phone'})}</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={phoneObj.phoneNumber || ''}
            required
            disabled
          />
        </div>

        <div className="mb-3">
          <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Name'})}</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={leadData.name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Lead Source Select */}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Lead Source'})}</label>
          <select
            className="form-select"
            id="lead_source"
            name="lead_source"
            value={leadData.lead_source}
            onChange={handleChange}
            required
          >
              <option value="social_media">{intl.formatMessage({id: 'Social Media'})}</option>
              <option value="website">{intl.formatMessage({id: 'Website'})}</option>
              <option value="referral">{intl.formatMessage({id: 'Referral'})}</option>
              <option value="cold_בalling">{intl.formatMessage({id: 'Cold Calling'})}</option>
          </select>
        </div>

        {/* Lead Type Select */}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Lead Type'})}</label>
          <select
            className="form-select"
            id="lead_warm_status"
            name="lead_warm_status"
            value={leadData.lead_warm_status}
            onChange={handleChange}
            required
          >
            <option value="cold">{intl.formatMessage({id: 'Cold Lead'})}</option>
            <option value="hot">{intl.formatMessage({id: 'Hot Lead'})}</option>
          </select>
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">{intl.formatMessage({id: 'Add Lead'})}</button>
      </form>



                  </div>}

                  {phoneObj.isValid && phoneSearchMap[phoneObj.phoneNumber]?._id && <div className="ms-5 alert alert-danger" role="alert">
                      <h4 className="alert-heading">{intl.formatMessage({id: 'Lead not available'})}</h4>
                      <p>{intl.formatMessage({id: 'Unfortunately, the lead you are looking for belongs to another affilate account.'})}</p>
                      <hr/>
                      <p className="mb-0">{intl.formatMessage({id: 'Please try again or contact support if you believe there is a mistake.'})}</p>
                  </div>}

              {leads.map((lead) => <>
                <Link to={`/affiliate/leads/${lead._id}`}>
              <div className={`d-flex flex-stack py-4 ${lead._id === id ? 'bg-secondary' : ''}`}>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px symbol-circle'>
                    <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                    {lead.name[0]}
                    </span>
                  </div>

                  <div className='ms-5'>
                    <a className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                      {lead.name}
                    </a>
                    <div className='fw-bold text-gray-400'>{lead.phone}</div>
                    <div className='fw-bold text-gray-400'>{lead.email}</div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>{moment(lead.created_at).fromNow()}</span>
                  <span className='badge badge-light-success'>{intl.formatMessage({id:lead.lead_source})}</span>
                  <span className='badge badge-light-danger'>{intl.formatMessage({id:lead.lead_warm_status})}</span>
                </div>
              </div>
              </Link>

              <div className='separator separator-dashed d-none'></div>
              </>)}
            </div>
          </div>
        </div>
      </div>

      {selectedLead._id && <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a
                  className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                >
                  {selectedLead.name}
                </a>

                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px ms-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>מקור - {selectedLead.lead_source} - {moment(selectedLead.created_at).fromNow()}</span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='me-n3'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-2'></i>
                </button>
              </div>
            </div>
          </div>

          <hr/>

          {/*<div className="d-flex align-items-center flex-shrink-0">
            <span className="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">
              {intl.formatMessage({id: 'Send Message by whatsapp'})}:
            </span>
            <div className="flex-shrink-0 ">
              <ul className="nav" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 active"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="true"
                    role="tab"
                  >
                    {intl.formatMessage({id: 'Message 1'})}
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1"
                    data-bs-toggle="tab"
                    href=""
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    {intl.formatMessage({id: 'Message 1'})}
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    {intl.formatMessage({id: 'Message 1'})}
                  </a>
                </li>
              </ul>
            </div>
          </div><hr/>*/}

          


          {/*<div className="d-flex align-items-center flex-shrink-0">
            <span className="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">
              {intl.formatMessage({id: 'Status'})}:
            </span>
            <div className="flex-shrink-0 ">
              <ul className="nav" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 active"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="true"
                    role="tab"
                  >
                    {intl.formatMessage({id: 'No Answer'})} 1
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1"
                    data-bs-toggle="tab"
                    href=""
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    {intl.formatMessage({id: 'Not Interested'})}
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="false"
                    role="tab"
                    tabIndex={-1}
                  >
                    {intl.formatMessage({id: 'Follow Up'})}
                  </a>
                </li>
              </ul>
            </div>
          </div><hr/>*/}


          

          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6'>


              {selectedLead.cardcom_url && (
                <iframe src={selectedLead.cardcom_url} width={'100%'} height={'1300px'}/>
              )}

              {!selectedLead.cardcom_url && <>
                <h2>{intl.formatMessage({id: 'Close client and take payment'})}</h2>
                <form onSubmit={handleSubmitFirstPaymentStep}>
                  {/* Name Input */}

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label"><strong>{intl.formatMessage({id: 'Invoice Name'})}</strong></label>
                    <input
                      type="text"
                      className="form-control"
                      id="invoice_name"
                      name="invoice_name"
                      value={selectedLead.invoice_name}
                      onChange={handleChangeLead}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label"><strong>{intl.formatMessage({id: 'Email'})}</strong></label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={selectedLead.email}
                      onChange={handleChangeLead}
                    />
                  </div>

                  {/* Lead Source Select */}
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label"><strong>{intl.formatMessage({id: 'Business Type'})}</strong></label>
                    <select
                      className="form-select"
                      id="business_type"
                      name="business_type"
                      value={selectedLead.business_type}
                      onChange={handleChangeLead}
                    >



                      <option value=""></option>
                      <option value="realestate">{intl.formatMessage({id: 'Realestate'})}</option>
                      <option value="cars">{intl.formatMessage({id: 'Cars'})}</option>
                      <option value="hr">{intl.formatMessage({id: 'HR'})}</option>
                      <option value="short-term-rental">{intl.formatMessage({id: 'Short Term Rental'})}</option>
                      <option value="other">{intl.formatMessage({id: 'Other'})}</option>
                    </select>
                  </div>


                  {/* Submit Button */}

                  {!savingInfo && <button type="submit" className="btn btn-primary">{intl.formatMessage({id: 'Next Step'})}</button>}
                
                  {savingInfo && <button type="button" className="btn btn-primary">
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'Please wait...'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>}


                </form>
                </>}

              </div>
              <div className='col-lg-6'>


              <h2>{intl.formatMessage({id: 'Lead information'})}</h2>
              <form onSubmit={handleSubmitLead}>
                {/* Name Input */}
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Phone'})}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={selectedLead.phone || ''}
                    required
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Name'})}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={selectedLead.name}
                    onChange={handleChangeLead}
                    required
                  />
                </div>


                {/* Lead Source Select */}
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Lead Source'})}</label>
                  <select
                    className="form-select"
                    id="lead_source"
                    name="lead_source"
                    value={selectedLead.lead_source}
                    onChange={handleChangeLead}
                    required
                  >
                    <option value="social_media">{intl.formatMessage({id: 'Social Media'})}</option>
                    <option value="website">{intl.formatMessage({id: 'Website'})}</option>
                    <option value="referral">{intl.formatMessage({id: 'Referral'})}</option>
                    <option value="cold_בalling">{intl.formatMessage({id: 'Cold Calling'})}</option>
                  </select>
                </div>

                {/* Lead Type Select */}
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Lead Type'})}</label>
                  <select
                    className="form-select"
                    id="lead_warm_status"
                    name="lead_warm_status"
                    value={selectedLead.lead_warm_status}
                    onChange={handleChangeLead}
                    required
                  >
                    <option value="cold">{intl.formatMessage({id: 'Cold Lead'})}</option>
                    <option value="hot">{intl.formatMessage({id: 'Hot Lead'})}</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Status'})}</label>
                  <select
                    className="form-select"
                    id="status"
                    name="status"
                    value={selectedLead.status}
                    onChange={handleChangeLead}
                    required
                  >
                    <option value=""></option>
                    <option value="no_answer">{intl.formatMessage({id: 'No Answer'})}</option>
                    <option value="follow_up">{intl.formatMessage({id: 'Follow Up'})}</option>
                    <option value="not_interested">{intl.formatMessage({id: 'Not Interested'})}</option>
                  </select>
                </div>

                {selectedLead.status === 'follow_up' && <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Follow Up'})}</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="follow_up_time"
                    name="follow_up_time"
                    value={formatDate(new Date(selectedLead.follow_up_time))}
                    onChange={handleChangeLead}
                    required
                  />
                </div>}

                {/* Submit Button */}
                {!savingInfo && <button type="submit" className="btn btn-primary">{intl.formatMessage({id: 'Save'})}</button>}
                
                {savingInfo && <button type="button" className="btn btn-primary">
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Please wait...'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>}







              </form>

              <hr/>

              <h2>{intl.formatMessage({id: 'Timeline'})}</h2>
              



              <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          <div className='timeline-item'>
            {/* begin::Label */}
            <div className='timeline-label fw-bold text-gray-800 fs-6'>{moment(selectedLead.created_at).fromNow()}</div>
            {/* end::Label */}
            {/* begin::Badge */}
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-warning fs-1'></i>
            </div>
            {/* end::Badge */}
            {/* begin::Text */}
            <div className='fw-mormal timeline-content text-muted ps-3'>
            {intl.formatMessage({id: 'Lead created'})}
            </div>
            {/* end::Text */}
          </div>



          {selectedLead && selectedLead?.log?.map((log) => (<div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>{moment(log.created_at).fromNow()}</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            <div className='timeline-content d-flex'>
              <span className='fw-bold text-gray-800 ps-3'>
                <strong>{intl.formatMessage({id: 'Comment'})}:</strong><br/>
                {log.description}
              </span>
            </div>
          </div>))}
        </div>
      </div>




              <form onSubmit={handleSubmitComment}>
                {/* Name Input */}
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">{intl.formatMessage({id: 'Comment'})}</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={newComment}
                    onChange={handleChangeComment}
                    required
                  />
                </div>

                {/* Submit Button */}
                {!savingInfo && <button type="submit" className="btn btn-primary">{intl.formatMessage({id: 'Add Comment'})}</button>}
                
                {savingInfo && <button type="button" className="btn btn-primary">
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Please wait...'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>}


              </form>


<hr/>
{/*
<div className="d-flex justify-content-center mt-4">
  <button
    className="btn btn-success mx-2"
    onClick={() => sendMessage('Hello, how are you?')}
  >
    <i className="fab fa-whatsapp"></i> {intl.formatMessage({id: 'Send general audio sales pitch'})} 
  </button>
</div>
<div className="d-flex justify-content-center mt-4">
  <button
    className="btn btn-success mx-2"
    onClick={() => sendMessage('Hello, how are you?')}
  >
    <i className="fab fa-whatsapp"></i> {intl.formatMessage({id: 'Send Contract'})} 
  </button>
</div>
<div className="d-flex justify-content-center mt-4">
  <button
    className="btn btn-success mx-2"
    onClick={() => sendMessage('Hello, how are you?')}
  >
    <i className="fab fa-whatsapp"></i> {intl.formatMessage({id: 'Send Recommendations Videos'})} 
  </button>
</div>
<div className="d-flex justify-content-center mt-4">
  <button
    className="btn btn-success mx-2"
    onClick={() => sendMessage('Hello, how are you?')}
  >
    <i className="fab fa-whatsapp"></i> {intl.formatMessage({id: 'Send Payment Link'})} 
  </button>
</div>
<hr/>
*/}



              </div>
            </div>
          </div>


















        </div>
      </div>}
    </div>



      </div>
    </>
  )
}
export {AffiliateLeadsWrapper}
