/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import './index.scss'
import {ContentTable} from './ContentTable'

const ContentWrapper: FC = () => {
  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 mb-md-5 mb-xl-10'>
          <ContentTable />
        </div>
      </div>
    </>
  )
}

export {ContentWrapper}
