// @ts-nocheck fff

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import ImgsViewer from 'react-images-viewer'
import './index.scss'
import {GroupWidget} from './GroupWidget'
import {GroupTable} from './GroupTable'
import {useAuth} from '../../../app/modules/auth'
import {Link, useLocation, useMatch, useNavigate} from 'react-router-dom'
import { ReactMediaLibrary } from "react-media-library";
import {useIntl} from 'react-intl'
import {useDropzone} from 'react-dropzone';
import styled_c from 'styled-components';
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1, CreateCampaignModal} from '../../../_metronic/partials'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'
import {getUserByToken, login} from '../../modules/auth/core/_requests'

import {
  TablesWidget1,
  TablesWidget2,
  TablesWidget3,
  TablesWidget4,
  TablesWidget5,
  TablesWidget6,
  TablesWidget7,
  TablesWidget8,
  TablesWidget9,
  TablesWidget10,
  TablesWidget11,
  TablesWidget12,
  TablesWidget13,
} from '../../../_metronic/partials/widgets'

import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';


const newCampaignSchema = Yup.object().shape({
  name: Yup.string()
    // .email('Wrong email format')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})



const API_URL = process.env.REACT_APP_API_URL
const CancelToken = axios.CancelToken;


  const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
  }

  const Container = styled_c.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;


  function StyledDropzone(props) {
    const intl = useIntl()

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject
    } = useDropzone({
      accept: {'image/*': [], 'video/*': []},
      onDropAccepted: async (acceptedFiles) => {

        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = async () => {

            let fd = new FormData();
            fd.append('file',file);

            var res = await axios.post(`${API_URL}/upload-file`, fd, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            props.post.media.push(res.data.location);
            props.update()
          };
          reader.readAsArrayBuffer(file);
        });



      },
      onDropRejected: async (a) => {
        alert('Error uploading file')
      }
    });
    
    return (
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>{intl.formatMessage({id: 'GENERAL.DRAG_FILES'})}</p>
      </Container>
    );
  }



const initialValues = {};

const GroupsWrapper: FC = () => {
  const {auth, currentUser, logout, getBot, setCurrentBot, currentBot, setBot, saveBot, googleLogin, getQRCode, setCurrentUser} = useAuth()
  const intl = useIntl()
  const [showCreateCampaignModal, setShowCreateCampaignModal] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: newCampaignSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log(values);

      (async () => {
        try {
          var new_campaign = await axios.post(`${API_URL}/create-campaign`, {
            api_token: auth?.api_token || '',
            new_campaign: values,
          });

          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)

          return navigate(`/campaigns/${new_campaign.data._id}`);
        } catch (e) {
          console.log(e);
          alert(1234)
        }
      })()


    },
  })

  const location = useLocation();
  const navigate = useNavigate();

  let [campaigns, setCampaigns] = useState(currentUser.campaigns);
  let [galleryItems, setGalleryItems] = useState([]);
  let [currImg, setCurrImg] = useState(0);

  let unallocated = campaigns.reduce((count, camp) => {
    count -= camp.publish_percentage || 0;
    count = Math.max(count, 0);
    return count;
  }, 100);

  const [display, setDisplay] = useState(false);
  let fileLibraryList: any;
  let setFileLibraryList: any;
  let selectedCampaign: any;
  let setSelectedCampaign: any;
  let selectedPost: any;
  let setSelectedPost: any;

  [fileLibraryList, setFileLibraryList] = useState([]);
  [selectedCampaign, setSelectedCampaign] = useState({});
  [selectedPost, setSelectedPost] = useState({});

  useEffect(() => {
    // TODO Get file list from database
    setFileLibraryList([
      {
        _id: 1,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 2,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 3,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 4,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      }
    ]);
  }, []);

  async function uploadCallback(
    fileBase64: any,
    fileMeta: any
  ): Promise<any> {
    // TODO Upload file to backend APIs
  }

  function selectCallback(item: any) {
    // Hide modal
    setDisplay(false);
    console.info(item);
  }

  async function deleteCallback(item: any) {
  }


  useEffect(() => {
    getBot();
  }, [currentUser, currentBot])

  let sourceToken: any;
  let setSourceToken: any;
  let posts: any;
  let setPosts: any;
  const [campaignID, setCampaignID] = useState('');
  const [loadingPosts, setLoadingPosts] = useState(false);
  [posts, setPosts] = useState([]);
  [sourceToken, setSourceToken] = useState();


  // alert(JSON.stringify(id))


  const match = useMatch("campaigns/:id");

  useEffect(() => {
    if (match) {
      const { id } = match.params;
      setCampaignID(id || '');


      setSelectedCampaign(currentUser.campaigns.find((c) => c._id == id));
      

      // ... business logic using tenentId
    } else {
      if (currentUser.campaigns.length > 0) {
        return navigate(`/campaigns/${currentUser.campaigns[0]._id}`)
      }
    }
  }, [match, currentUser]);

  useEffect(() => {
    const source = CancelToken.source();

    try {
      sourceToken.cancel('Operation canceled by the user.');
    } catch (e) {

    }

    setSourceToken(sourceToken);

    (async () => {
      try {
        setLoadingPosts(true);
        setPosts([]);
        var posts = await axios.post(`${API_URL}/posts`, {
          api_token: auth?.api_token || '',
          campaign_id: campaignID,
        }, {
          cancelToken: source.token
        });

        posts.data.map((post) => {
          post.media = (post.media_url || '').split(',').filter((a) => a).map((a) => a.split('ams3.digitaloceanspaces').join('ams3.cdn.digitaloceanspaces'));
        })
        

        setPosts(posts.data);
      } catch (e) {

      }
      setLoadingPosts(false);
    })()

  }, [campaignID]);



  let deleteCampaign = async (campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/delete-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      return navigate(`/campaigns`);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let requestCampaignApproval = async (campaign) => {
    try {

      campaign.loading = true;
      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));

      var new_campaign = await axios.post(`${API_URL}/request-approve-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      campaign.request_for_approval = new Date();

      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let enableCampaign = async (campaign, enabled) => {
    try {

      campaign.loading = true;
      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));

      var new_campaign = await axios.post(`${API_URL}/enable-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
        enabled: enabled,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      campaign.enabled = enabled;

      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deletePost = async (post, campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/delete-post`, {
        api_token: auth?.api_token || '',
        post: post,
        campaign: campaign,
      });
      posts.splice(posts.indexOf(post), 1)
      setPosts([...posts]);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deletePosts = async (campaign) => {
    try {
      var posts_to_delete = posts.filter((a) => a.selected);
      
      var new_campaign = await axios.post(`${API_URL}/delete-posts`, {
        api_token: auth?.api_token || '',
        ids: posts_to_delete.map((a) => a._id),
        campaign: campaign,
      });

      for (var i = 0; i < posts_to_delete.length; i++) {
        posts.splice(posts.indexOf(posts_to_delete[i]), 1)
      }
      setPosts([...posts]);
      
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deplicatePosts = async (post, campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/deplicate-posts`, {
        api_token: auth?.api_token || '',
        ids: posts.filter((a) => a.selected).map((a) => a._id),
        campaigns: campaigns.filter((a) => a.selected).map((a) => a._id),
      });
      // posts.splice(posts.indexOf(post), 1)
      setPosts([...posts]);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }

  let savePost = async (post, campaign) => {
    post.loading = true;
    setPosts([...posts]);

    try {
      var _post = await axios.post(`${API_URL}/save-post`, {
        api_token: auth?.api_token || '',
        post: post,
        campaign: campaign,
      });

      post.updated = false;
      if (!post._id) {
        post._id = _post.data._id;
      }

    } catch (e) {
      console.log(e);
      alert(1234)
    }

    post.loading = false;
    setPosts([...posts]);
  }




  return (
    <>
    <ReactMediaLibrary
      isOpen={display}
      multiple={true}
      multiSelect={true}
      onClose={() => setDisplay(false)}
      fileUploadCallback={() => {}}
      filesSelectCallback={() => {}}
      fileLibraryList={fileLibraryList}
    />

    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {intl.formatMessage({id: 'GENERAL.CAMPIAGNS'})} - {selectedCampaign?.name}
                  </a>

                  {selectedCampaign?.approved && <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-1 svg-icon-primary'
                  />}

                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <PieChart
                    series={[
                      {
                        data: campaigns.filter((a) => a.publish_percentage).map((camp:any) => {
                          return { id: camp._id, value: camp.publish_percentage, label: camp.name }
                        }),
                        // { id: 2, value: 20, label: 'series C' },
                      },
                    ]}
                    width={200}
                    height={100}
                  />
                </div>
              </div>

              <div className='d-flex my-4'>
                {/*<a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr012.svg'
                    className='svg-icon-3 d-none'
                  />

                  <span className='indicator-label'>Follow</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a>*/}
                  {/*onClick={() => setShowCreateCampaignModal(true)}*/}

                  {selectedCampaign?._id && <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_8"
                >
                  {intl.formatMessage({id: 'GENERAL.DEFINE_GROUPS'})}
                </button>}


                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_1"


                >
                  {intl.formatMessage({id: 'GENERAL.ADD_NEW_CAMPAIGN'})}
                </button>
                {/*<button
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_2"
                >
                  {intl.formatMessage({id: 'GENERAL.DELETE_CAMPAIGN'})}
                </button>*/}

                <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                      >

                        <div className="modal-header">
                          <h5 className="modal-title">{intl.formatMessage({id: 'GENERAL.NEW_CAMPAIGN'})}</h5>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">{intl.formatMessage({id: 'GENERAL.CAMPAIGN_NAME'})}</label>
                            <input
                              type="text"
                              {...formik.getFieldProps('name')}
                              className={clsx(
                                'form-control bg-transparent',
                                {'is-invalid': formik.touched.name && formik.errors.name},
                                {
                                  'is-valid': formik.touched.name && !formik.errors.name,
                                }
                              )}
                              placeholder={intl.formatMessage({id: 'GENERAL.CAMPAIGN_NAME'})}
                            />



                            {formik.touched.name && formik.errors.name && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert'>{formik.errors.name}</span>
                              </div>
                            )}



                          </div>
                        </div>
                        <div className="modal-footer">
                          <button disabled={formik.isSubmitting || !formik.isValid} type="submit" className="btn btn-primary" data-bs-dismiss="modal" >
                            {intl.formatMessage({id: 'GENERAL.CREATE_CAMPAIGN'})}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="modal fade" tabIndex={-1} id="kt_modal_8">
                  <div className="modal-dialog">
                    <div className="modal-content">

                      
                      {selectedCampaign && <TablesWidget10 campaign={selectedCampaign}/>}
                    </div>
                  </div>
                </div>

                <div className="modal fade" tabIndex={-1} id="kt_modal_2">
                  <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header alert-dismissible bg-light-danger">
                          <h5 className="modal-title"></h5>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>

                        <div className="modal-body alert-dismissible bg-light-danger">

                        <div>
                          <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">{intl.formatMessage({id: 'GENERAL.DELETE_CAMPAIGN'})} <span style={{color: 'red'}}>{selectedCampaign?.name}</span></h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                              {intl.formatMessage({id: 'GENERAL.WANRING_DELETE_CAMPAIGN'})}.<br/>
                            </div>
                            <div className="d-flex flex-center flex-wrap">
                              <button className="btn btn-outline btn-outline-danger btn-active-danger m-2" data-bs-dismiss="modal">{intl.formatMessage({id: 'GENERAL.CANCEL'})}</button>
                              <button className="btn btn-danger m-2" data-bs-dismiss="modal" onClick={deleteCampaign.bind(this, selectedCampaign)}>{intl.formatMessage({id: 'GENERAL.OK_GOT_IT'})}</button>
                            </div>
                          </div>
                      </div>


                        </div>



                    </div>
                  </div>
                </div>

                <div className="modal fade" tabIndex={-1} id="kt_modal_3">
                  <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header alert-dismissible bg-light-danger">
                          <h5 className="modal-title"></h5>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>

                        <div className="modal-body alert-dismissible bg-light-danger">

                        <div>
                          <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">{intl.formatMessage({id: 'GENERAL.DELETE_POST'})} <span style={{color: 'red'}}>{selectedPost?.name}</span></h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                              {intl.formatMessage({id: 'GENERAL.WANRING_DELETE_POST'})}.<br/>
                            </div>
                            <div className="d-flex flex-center flex-wrap">
                              <button className="btn btn-outline btn-outline-danger btn-active-danger m-2" data-bs-dismiss="modal">{intl.formatMessage({id: 'GENERAL.CANCEL'})}</button>
                              <button className="btn btn-danger m-2" data-bs-dismiss="modal" onClick={deletePost.bind(this, selectedPost, selectedCampaign)}>{intl.formatMessage({id: 'GENERAL.OK_GOT_IT'})}</button>
                            </div>
                          </div>
                      </div>


                        </div>



                    </div>
                  </div>
                </div>

                <div className="modal fade" tabIndex={-1} id="kt_modal_4">
                  <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header alert-dismissible bg-light-danger">
                          <h5 className="modal-title"></h5>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>

                        <div className="modal-body alert-dismissible bg-light-danger">

                        <div>
                          <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">{intl.formatMessage({id: 'GENERAL.DELETE_POSTS'})} <span style={{color: 'red'}}>({posts.filter((a) => a.selected).length})</span></h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                              {intl.formatMessage({id: 'GENERAL.WANRING_DELETE_POSTS'})}.<br/>
                            </div>
                            <div className="d-flex flex-center flex-wrap">
                              <button className="btn btn-outline btn-outline-danger btn-active-danger m-2" data-bs-dismiss="modal">{intl.formatMessage({id: 'GENERAL.CANCEL'})}</button>
                              <button className="btn btn-danger m-2" data-bs-dismiss="modal" onClick={deletePosts.bind(this, selectedCampaign)}>{intl.formatMessage({id: 'GENERAL.OK_GOT_IT'})}</button>
                            </div>
                          </div>
                      </div>


                        </div>



                    </div>
                  </div>
                </div>

                <div className="modal fade" tabIndex={-1} id="kt_modal_5">
                  <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header alert-dismissible bg-light-primary">
                          <h5 className="modal-title"></h5>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>

                        <div className="modal-body alert-dismissible bg-light-primary">

                        <div>
                          <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">{intl.formatMessage({id: 'GENERAL.DEPLICATE_POSTS'})} <span style={{color: 'red'}}>({posts.filter((a) => a.selected).length})</span></h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                              {intl.formatMessage({id: 'GENERAL.WANRING_DEPLICATE_POSTS'})}.<br/>
                            </div>
                            <div className="mb-9">
            {campaigns.map((camp:any) => (<div className='nav-item'>
                <label>
                  {camp.name}

                  <input className="form-check-input" type="checkbox" checked={camp.selected} onClick={(e) => {
                    camp.selected = !camp.selected;
                    setCampaigns([...campaigns]);
                  }}/>
                </label>

            </div>))}

                            </div>
                            <div className="d-flex flex-center flex-wrap">
                              <button className="btn btn-outline btn-outline-danger btn-active-danger m-2" data-bs-dismiss="modal">{intl.formatMessage({id: 'GENERAL.CANCEL'})}</button>
                              <button className="btn btn-primary m-2" data-bs-dismiss="modal" onClick={deplicatePosts.bind(this, selectedCampaign)}>{intl.formatMessage({id: 'GENERAL.DUPLICATE'})}</button>
                            </div>
                          </div>
                      </div>


                        </div>



                    </div>
                  </div>
                </div>



                {/*<div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                </div>*/}
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8-'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{selectedCampaign?.post_count}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'GENERAL.POSTS'})}</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{selectedCampaign?.today_scheduled_count}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'GENERAL.SCHEDULED_TODAY'})}</div>
                  </div>

                  {/*<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>0</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'GENERAL.LEADS'})}</div>
                  </div>*/}
                </div>


                {!selectedCampaign?.request_for_approval && <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>{intl.formatMessage({id: 'GENERAL.REQUEST_APPROVAL_TITLE'})}</h4>
                      <div className='fs-6 text-gray-600'>
                        {intl.formatMessage({id: 'GENERAL.REQUEST_APPROVAL'})}
                        {<button className='fw-bolder btn btn-primary' onClick={requestCampaignApproval.bind(this, selectedCampaign)}>
                          {!selectedCampaign.loading && intl.formatMessage({id: 'GENERAL.REQUEST_APPROVAL_BUTTON'})}
                          
                          {selectedCampaign.loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>}




                      </div>
                    </div>
                  </div>
                </div>}

                {selectedCampaign.enabled && selectedCampaign?.request_for_approval && <div className='notice d-flex bg-light-success rounded border-primary border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>{intl.formatMessage({id: 'GENERAL.SUCCESS_POST_CAMPAIGN'})}</h4>
                      <div className='fs-6 text-gray-600'>

                        {<button className='fw-bolder btn btn-warning' onClick={enableCampaign.bind(this, selectedCampaign, false)}>
                          {!selectedCampaign.loading && intl.formatMessage({id: 'GENERAL.PAUSE_CAMPAIGN'})}
                          
                          {selectedCampaign.loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>}

                      </div>
                    </div>
                  </div>
                </div>}

              {!selectedCampaign.enabled && selectedCampaign?.request_for_approval && <div className='notice d-flex bg-light-warning rounded border-primary border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>{intl.formatMessage({id: 'GENERAL.SUSPENDED_CAMPAIGN'})}</h4>
                      <div className='fs-6 text-gray-600'>

                        {<button className='fw-bolder btn btn-primary' onClick={enableCampaign.bind(this, selectedCampaign, true)}>
                          {!selectedCampaign.loading && intl.formatMessage({id: 'GENERAL.ENABLE_CAMPAIGN'})}
                          
                          {selectedCampaign.loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>}

                      </div>
                    </div>
                  </div>
                </div>}

              </div>





                  {/*<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'GENERAL.POST_FOOTER_CONTENT'})}</div>

                    <div className='d-flex align-items-center'>
                      <textarea
                        className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                        rows={1}
                        placeholder={intl.formatMessage({id: 'GENERAL.POST_FOOTER_CONTENT_PLACEHOLDER'})}
                        style={{height: 80, direction: 'rtl'}}
                      ></textarea>
                    </div>

                  </div>*/}

              {/*<div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>*/}
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {currentUser?.campaigns.map((camp:any) => (<li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/campaigns/${camp._id}` && 'active')
                }
                to={`/campaigns/${camp._id}`}
              >
                {camp.name}
              </Link>
            </li>))}
          </ul>
        </div>
      </div>
    </div>

    <div>
      <input className="form-check-input" type="checkbox" onClick={(e) => {
        posts.filter((a) => a.selected = e.target.checked);
        setPosts([...posts]);
      }}/>
      <span>{posts.filter((a) => a.selected).length} {intl.formatMessage({id: 'GENERAL.POSTS_SELECTED'})}</span>



        {posts.filter((a) => a.selected).length ? <button type="button" className="btn btn-sm btn-danger" onClick={() => {

        }} data-bs-toggle="modal" data-bs-target="#kt_modal_4">
          {intl.formatMessage({id: 'GENERAL.DELETE'})} ({posts.filter((a) => a.selected).length}) <KTSVG
              path='/media/icons/duotune/general/gen027.svg'
              className='svg-icon-1 svg-icon-primary'
            />
        </button> : ''}

        {posts.filter((a) => a.selected).length ? <button type="button" className="btn btn-sm btn-warning" onClick={() => {

        }} data-bs-toggle="modal" data-bs-target="#kt_modal_5">
          {intl.formatMessage({id: 'GENERAL.DUPLICATE'})} ({posts.filter((a) => a.selected).length}) <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className='svg-icon-1 svg-icon-primary'
            />
        </button> : ''}

        {false ? <button type="button" className="btn btn-sm btn-primary" onClick={() => {

        }}>
          {intl.formatMessage({id: 'GENERAL.EDIT_GROUP'})} ({posts.filter((a) => a.selected).length}) <KTSVG
              path='/media/icons/duotune/general/gen054.svg'
              className='svg-icon-1 svg-icon-primary'
            />
        </button> : ''}

    </div>



    {campaignID && loadingPosts && <span className='indicator-progress' style={{display: 'block'}}>
      Please wait...{' '}
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>}


    {campaignID && !loadingPosts && <div className={`row g-5 g-xxl-8`}>
      {posts.map((post: any) => (<div className={`col-md-4 ${posts.find((p) => p.updated && p._id != post._id) ? 'other-updated' : ''}`}>
        <div className={`card mb-5 mb-xxl-8`} style={{
          boxShadow: (post.selected ? '0 0 1px 1px #2ecc71' : ''),
        }}>

        <div className="form-check">
          <input className="form-check-input" type="checkbox" checked={post.selected} onClick={() => {
              post.selected = !post.selected;
              setPosts([...posts]);
          }}/>
        </div>


          {/* begin::Body */}
          <div className='card-body pb-0'>
            {/* begin::Header */}
            <div className='d-flex align-items-center mb-5'>
              {/* begin::User */}
              <div className='d-flex align-items-center flex-grow-1'>
                {/* begin::Avatar */}
                <div className='symbol symbol-45px me-5'>
                  <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                </div>
                {/* end::Avatar */}

                {/* begin::Info */}
                <div className='d-flex flex-column'>
                  <a className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                    Nick Logan                   {/*<button type="button" className="btn btn-sm btn-danger">
                    {intl.formatMessage({id: 'GENERAL.DELETE_POST'})}
                  </button>*/}

                  </a>


                  <span className='text-gray-400 fw-semibold'>1 hour ago</span>
                </div>
                {/* end::Info */}
              </div>
              {/* end::User */}

              {/* begin::Menu */}
              <div className='my-0'>
                {post._id && <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-danger btn-active-light-danger'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'

                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_3"

                  onClick={() => {
                    setSelectedPost(post);
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon  svg-icon-2x svg-icon-danger' style={{color: 'red !important'}}/>
                </button>}
              </div>
              {/* end::Menu */}
            </div>
            {/* end::Header */}

            {/* begin::Post */}
            <div className='mb-5'>
              {/* begin::Text */}
              <p className='text-gray-800 fw-normal mb-5'>
              
              <hr/>
              <label className="fw-bold">{intl.formatMessage({id: 'GENERAL.POST_NAME'})} ({intl.formatMessage({id: 'GENERAL.NOT_REQUIRED'})}):</label>
              <input placeholder={intl.formatMessage({id: 'GENERAL.POST_NAME'})}  className="form-control border-0 p-0 pe-10 resize-none min-h-25px" value={post.name} onChange={(e) => {
                  post.name = e.target.value;
                  post.updated = true;
                  setPosts([...posts]);
                }}
              />
              <hr/>

              <label className="fw-bold">{intl.formatMessage({id: 'GENERAL.POST_CONTENT'})}:</label>
              <textarea
                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                rows={1}
                placeholder={intl.formatMessage({id: 'GENERAL.POST_CONTENT-PLACEHOLDER'})}
                value={post.post_content}
                style={{height: 100, direction: 'rtl'}}
                onChange={(e) => {
                  post.post_content = e.target.value;
                  post.updated = true;
                  setPosts([...posts]);
                }}
              ></textarea>

              </p>
              {/* end::Text */}

              {/* begin::Toolbar */}
              {/*<div className='d-flex align-items-center mb-5'>
                <a
                  href='#'
                  className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                >
                  <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
                  120
                </a>

                <a
                  href='#'
                  className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                >
                  <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
                  15
                </a>
              </div>*/}
              {/* end::Toolbar */}
            </div>
            {/* end::Post */}

            {/* begin::Separator */}
            <div className='separator mb-4'></div>
            {/* end::Separator */}

            {/* begin::Reply input */}
            <hr/>
            <div>
              {post.media.map((media, index) => (<div className="image-input image-input-outline image-input-changed" id="kt_image_1" style={{
                width: '33%',
                overflow: 'hidden',
              }}>
                <div onClick={() => {
                  var b = post.media.map((a) => {
                    return `https://api.fbzipper.com/generate-thumbnail?url=${a}&large=true`
                  })
                  setGalleryItems(b);
                }} className="image-input-wrapper" style={{backgroundImage: `url("https://api.fbzipper.com/generate-thumbnail?url=${media}")`}} />
                <label style={{
                  position: 'absolute',
                  top: '-6px',
                  right: '-11px',
                }} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar" onClick={() => {
                    post.media.splice(index, 1);
                    post.updated = true;
                    setPosts([...posts]);
                  }}>
                  <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-40 svg-icon-danger mb-3' />
                </label>
              </div>))}
            </div>


            {/*<button className={`btn btn-primary`} onClick={() => setDisplay(true)}>{intl.formatMessage({id: 'GENERAL.UPLOAD_IMAGE'})} <KTSVG
                    path='/media/icons/duotune/communication/com008.svg'
                    className='svg-icon-3 mb-3'
                  /></button>*/}

            
            <label className="fw-bold">{intl.formatMessage({id: 'GENERAL.ASSETS'})} ({intl.formatMessage({id: 'GENERAL.NOT_REQUIRED'})}):</label>
            <StyledDropzone post={post} update={(() => {
              post.updated = true;
              setPosts([...posts]);
            })}/>

            <hr/>
            <label className="fw-bold">{intl.formatMessage({id: 'GENERAL.REPLY_TEXT'})} ({intl.formatMessage({id: 'GENERAL.NOT_REQUIRED'})}):</label>
            <textarea
              className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
              rows={1}
              placeholder={intl.formatMessage({id: 'GENERAL.REPLY_TEXT'})}
              value={post.reply_text}
              style={{height: 100, direction: 'rtl'}}
              onChange={(e) => {
                post.reply_text = e.target.value;
                post.updated = true;
                setPosts([...posts]);
              }}
            ></textarea>



            {/*<form className='position-relative mb-6'>
              <textarea
                className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
                rows={1}
                placeholder=''
                disabled={true}
              ></textarea>

              <div className='position-absolute top-0 end-0 me-n5'>
                <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                  <KTSVG
                    path='/media/icons/duotune/communication/com008.svg'
                    className='svg-icon-3 mb-3'
                  />
                </span>

                <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                  <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-2 mb-3' />
                  
                </span>
              </div>
            </form>*/}
            {/* edit::Reply input */}
          


      {/*post.updated && <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className={`btn btn-primary btn-sm`}
        >
          {!post.loading && <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>}
          {post.loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>*/}

      <div className="btn-group" role="group" aria-label="Basic example">
        {post.updated && <button type="button" onClick={savePost.bind(this, post, selectedCampaign)} className="btn btn-sm btn-primary">
          {!post.loading && <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SAVE'})}</span>}
          {post.loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>}
      </div>

          

          </div>


          {/* end::Body */}
        </div>
      </div>))}



      <div className={`col-md-4 ${posts.find((p) => p.updated) ? 'other-updated' : ''}`}>
        <div className={`card mb-5 mb-xxl-8`}>
          {/* begin::Body */}
          <div className='card-body pb-0'>
            {/* begin::Post */}
            <div className='mb-5'>
              {/* begin::Text */}
              <p className='text-gray-800 fw-normal mb-5 text-center'>
                <hr/>

                {<button type="button" className="btn btn-sm btn-primary" onClick={() => {
                  posts.push({
                    is_new: true,
                    updated: true,
                    media: [],
                  })
                  setPosts([...posts]);
                }}>
                  {intl.formatMessage({id: 'GENERAL.ADD_NEW_POST'})} <KTSVG
                      path='/media/icons/duotune/general/gen035.svg'
                      className='svg-icon-1 svg-icon-primary'
                    />
                </button>}


                <hr/>
              </p>
            </div>
          </div>
          {/* end::Body */}
        </div>
      </div>


      <CreateCampaignModal show={showCreateCampaignModal} handleClose={() => setShowCreateCampaignModal(false)} />

    </div>}

    <div class="ffff">
  {<ImgsViewer
    key={'DDDDD'}
    imgs={galleryItems.map((a) => { return {src: a} })}
    isOpen={galleryItems.length > 0}
    currImg={currImg}
    onClickPrev={() => {
      setCurrImg(currImg-1)
    }}
    onClickNext={() => {
      setCurrImg(currImg+1)
    }}
    onClose={() => {
      setGalleryItems([]);
    }}
  />}
    </div>


    </>
  )
}

export {GroupsWrapper}
