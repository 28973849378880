// @ts-nocheck

import {useAuth} from '../../../app/modules/auth'
import {useIntl} from 'react-intl'
import React, {FC, useState, useEffect} from 'react'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const PaymentInformationWrapper = () => {

  const {currentUser, logout} = useAuth();
  const intl = useIntl();

  const [loadingCardcom, setLoadingCardcom] = useState(true);
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        var data = await axios.get(`${API_URL}/api/generate-token`);

        setLoadingCardcom(false);
        setRequestData(data.data);
      } catch (e) {
        console.log(e);
        alert('error')
      }
    })()

  }, []);

  return (
    <div className='row'>

      

      <div className='col-md-6 offset-md-3 col-lg-4 offset-lg-4 fv-row'>
        <div className='card mb-5 mb-xl-10'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Add Payment</h3>
            </div>
          </div>
          <div className='collapse show'>

            {loadingCardcom && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
            {!loadingCardcom && (
              <iframe src={requestData.url} width={'100%'} height={'1500px'}/>
            )}
          </div>
        </div>
      </div>
      {JSON.stringify(currentUser.payment_tokens)}
    </div>
  )
}
export {PaymentInformationWrapper}
