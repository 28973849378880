// @ts-nocheck fff

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useRef, useCallback} from 'react'
import './index.scss'
import {GroupWidget} from './GroupWidget'
import {GroupTable} from './GroupTable'
import {useAuth} from '../../../app/modules/auth'
import {Link, useLocation, useMatch, useNavigate} from 'react-router-dom'
import { ReactMediaLibrary } from "react-media-library";
import {useIntl} from 'react-intl'
import {useDropzone} from 'react-dropzone';
import styled_c from 'styled-components';
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1, CreateCampaignModal} from '../../../_metronic/partials'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'
import {getUserByToken, login} from '../../modules/auth/core/_requests'


import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';


const newCampaignSchema = Yup.object().shape({
  name: Yup.string()
    // .email('Wrong email format')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

let timeout;

const API_URL = process.env.REACT_APP_API_URL
const CancelToken = axios.CancelToken;

const plans = [
  {
    title: 'Startup',
    subTitle: 'Best for startups',
    description: 'Optimal for 10+ team size and new startup',
    priceMonth: '1500',
    priceAnnual: '15,000',
    default: true,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: false,
      },
      {
        title: 'Accounting Module',
        supported: false,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },

  {
    title: 'Advanced',
    subTitle: 'Best for 100+ team size',
    description: 'Optimal for 100+ team size and grown company',
    priceMonth: '339',
    priceAnnual: '3399',
    default: false,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: true,
      },
      {
        title: 'Accounting Module',
        supported: true,
      },
      {
        title: 'Network Platform',
        supported: false,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: false,
      },
    ],
  },

  {
    title: 'Enterprise',
    subTitle: 'Best value for 1000+ team',
    description: 'Optimal for 1000+ team and enterpise',
    priceMonth: '999',
    priceAnnual: '9999',
    label: 'Most popular',
    default: false,
    custom: false,
    features: [
      {
        title: 'Up to 10 Active Users',
        supported: true,
      },
      {
        title: 'Up to 30 Project Integrations',
        supported: true,
      },
      {
        title: 'Analytics Module',
        supported: true,
      },
      {
        title: 'Finance Module',
        supported: true,
      },
      {
        title: 'Accounting Module',
        supported: true,
      },
      {
        title: 'Network Platform',
        supported: true,
      },
      {
        title: 'Unlimited Cloud Space',
        supported: true,
      },
    ],
  },

  {
    title: 'Custom',
    subTitle: 'Requet a custom license',
    default: false,
    custom: true,
  },
]


  const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
  }

  const Container = styled_c.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  `;


  function StyledDropzone(props) {
    const intl = useIntl()

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject
    } = useDropzone({
      accept: {'image/*': []},
      onDropAccepted: async (acceptedFiles) => {

        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = async () => {

            let fd = new FormData();
            fd.append('file',file);

            var res = await axios.post(`${API_URL}/upload-file`, fd, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            props.post.media.push(res.data.location);
            props.update()
          };
          reader.readAsArrayBuffer(file);
        });



      },
      onDropRejected: async (a) => {
        alert('Error uploading file')
      }
    });
    
    return (
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>{intl.formatMessage({id: 'GENERAL.DRAG_FILES'})}</p>
      </Container>
    );
  }



const initialValues = {};

const StatsWrapper: FC = () => {
  const {auth, currentUser, logout, getBot, setCurrentBot, currentBot, setBot, saveBot, googleLogin, getQRCode, setCurrentUser} = useAuth()
  const intl = useIntl()
  const [showCreateCampaignModal, setShowCreateCampaignModal] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: newCampaignSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log(values);

      (async () => {
        try {
          var new_campaign = await axios.post(`${API_URL}/create-campaign`, {
            api_token: auth?.api_token || '',
            new_campaign: values,
          });

          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)

          return navigate(`/campaigns/${new_campaign.data._id}`);
        } catch (e) {
          console.log(e);
          alert(1234)
        }
      })()


    },
  })

  const location = useLocation();
  const navigate = useNavigate();

  let [campaigns, setCampaigns] = useState(currentUser.campaigns);

  let unallocated = campaigns.reduce((count, camp) => {
    count -= camp.publish_percentage || 0;
    count = Math.max(count, 0);
    return count;
  }, 100);

  const [display, setDisplay] = useState(false);
  let fileLibraryList: any;
  let setFileLibraryList: any;
  let selectedCampaign: any;
  let setSelectedCampaign: any;
  let selectedPost: any;
  let setSelectedPost: any;

  [fileLibraryList, setFileLibraryList] = useState([]);
  [selectedCampaign, setSelectedCampaign] = useState({});
  [selectedPost, setSelectedPost] = useState({});

  useEffect(() => {
    // TODO Get file list from database
    setFileLibraryList([
      {
        _id: 1,
        title: "Me and my dog",
        size: 294880,
        fileName: "img_3549.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:29.866Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 2,
        title: "2019 Summer Vacation",
        size: 864483,
        fileName: "201702.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:12:45.018Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 3,
        title: "Our new baby",
        size: 586458,
        fileName: "271092.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:19:33.498Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      },
      {
        _id: 4,
        title: "My new car",
        size: 894665,
        fileName: "photo-107.jpg",
        type: "image/jpeg",
        createdAt: new Date("2019-10-17T03:28:39.723Z"),
        thumbnailUrl:
          "https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
      }
    ]);
  }, []);

  async function uploadCallback(
    fileBase64: any,
    fileMeta: any
  ): Promise<any> {
    // TODO Upload file to backend APIs
  }

  function selectCallback(item: any) {
    // Hide modal
    setDisplay(false);
    console.info(item);
  }

  async function deleteCallback(item: any) {
  }


  useEffect(() => {
    getBot();
  }, [currentUser, currentBot])

  let sourceToken: any;
  let setSourceToken: any;
  let posts: any;
  let setPosts: any;
  const [campaignID, setCampaignID] = useState('');
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [loadingElement, setLoadingElement] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  [posts, setPosts] = useState([]);
  const [displayAmount, setDisplayAmount] = useState(20);
  [sourceToken, setSourceToken] = useState();




  const [isVisible, setIsVisible] = useState(false);

  const elementRef = useRef(null);




  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    console.log('IntersectionObserver callback triggered.');
    const entry = entries[0];
    if (entry.isIntersecting) {
      console.log('Element is intersecting.');

      // Access someState here
      handleIntersection();
    }
  }, [loadingElement,loadingPost,displayAmount,loadingPosts]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [handleObserver]);
  const handleIntersection = () => {
    // alert(loadingPosts);
    // alert(loadingElement);
    
    if (loadingPosts || loadingElement) {
      return;
    }
    setLoadingElement(true);

    setTimeout(() => {
      setDisplayAmount(displayAmount + 20);
      setLoadingElement(false);
    }, 60000)

    console.log('Element is in view!');
    // Perform actions or trigger methods as needed
  };



  // alert(JSON.stringify(id))


  const match = useMatch("campaigns/:id");

  useEffect(() => {
  }, [match, currentUser]);


  var isInViewport = (offset) => {
    if (!loadingElement) return false;
    const top = loadingElement.getBoundingClientRect().top;
    return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
  }


  useEffect(() => {
    const source = CancelToken.source();

    try {
      sourceToken.cancel('Operation canceled by the user.');
    } catch (e) {

    }

    setSourceToken(sourceToken);

    (async () => {
      try {
        setLoadingPosts(true);
        setPosts([]);
        var posts = await axios.post(`${API_URL}/live-posts`, {
          api_token: auth?.api_token || '',
          campaign_id: campaignID,
        }, {
          cancelToken: source.token
        });        

        setTimeout(() => {
          setPosts(posts.data);
        }, 30000)

      } catch (e) {

      }
      setLoadingPosts(false);
    })()

  }, [campaignID]);



  let deleteCampaign = async (campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/delete-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      return navigate(`/campaigns`);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let requestCampaignApproval = async (campaign) => {
    try {

      campaign.loading = true;
      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));

      var new_campaign = await axios.post(`${API_URL}/request-approve-campaign`, {
        api_token: auth?.api_token || '',
        campaign: campaign,
      });

      const {data: user} = await getUserByToken(auth.api_token)
      setCurrentUser(user)

      campaign.request_for_approval = new Date();

      setSelectedCampaign(JSON.parse(JSON.stringify(campaign)));
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deletePost = async (post, campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/delete-post`, {
        api_token: auth?.api_token || '',
        post: post,
        campaign: campaign,
      });
      posts.splice(posts.indexOf(post), 1)
      setPosts([...posts]);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let _setLoadingPost = async (post) => {
    try {
    clearTimeout(timeout)
    let loadingPost = post;
      post.state_loading = true;
      setLoadingPost(JSON.parse(JSON.stringify(post)));
      timeout = setTimeout(() => {
        post.state_loading = false;
        setLoadingPost(JSON.parse(JSON.stringify(post)));
      }, 20000)
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deletePosts = async (campaign) => {
    try {
      var posts_to_delete = posts.filter((a) => a.selected);
      
      var new_campaign = await axios.post(`${API_URL}/delete-posts`, {
        api_token: auth?.api_token || '',
        ids: posts_to_delete.map((a) => a._id),
        campaign: campaign,
      });

      for (var i = 0; i < posts_to_delete.length; i++) {
        posts.splice(posts.indexOf(posts_to_delete[i]), 1)
      }
      setPosts([...posts]);
      
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }
  let deplicatePosts = async (post, campaign) => {
    try {
      var new_campaign = await axios.post(`${API_URL}/deplicate-posts`, {
        api_token: auth?.api_token || '',
        ids: posts.filter((a) => a.selected).map((a) => a._id),
        campaigns: campaigns.filter((a) => a.selected).map((a) => a._id),
      });
      // posts.splice(posts.indexOf(post), 1)
      setPosts([...posts]);
    } catch (e) {
      console.log(e);
      alert(1234)
    }
  }

  let savePost = async (post, campaign) => {
    post.loading = true;
    setPosts([...posts]);

    try {
      var _post = await axios.post(`${API_URL}/save-post`, {
        api_token: auth?.api_token || '',
        post: post,
        campaign: campaign,
      });

      post.updated = false;
      if (!post._id) {
        post._id = _post.data._id;
      }

    } catch (e) {
      console.log(e);
      alert(1234)
    }

    post.loading = false;
    setPosts([...posts]);
  }

  const [currentState, setCurrentState] = useState<'month' | 'annual'>('month')
  const [selected, setSelected] = useState('Startup')



  return (
    <>
    <ReactMediaLibrary
      isOpen={display}
      multiple={true}
      multiSelect={true}
      onClose={() => setDisplay(false)}
      fileUploadCallback={() => {}}
      filesSelectCallback={() => {}}
      fileLibraryList={fileLibraryList}
    />













    {loadingPosts && <span className='indicator-progress' style={{textAlign: 'center'}} style={{display: 'block'}}>
      {intl.formatMessage({id: 'GENERAL.PLEASE_WAIT'})}..{' '}
      <span style={{width: '130px', height: '130px'}} className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>}







    <div className='row g-5 g-xxl-8'>
      {posts.slice(0, displayAmount).map((post) => (<div key={post.id} className='col-xl-6'>
        <div className='card'>
          <div className='card-body'>
            {/* begin::Header */}
            <div className='d-flex align-items-center mb-5'>
              {/* begin::User */}
              <div className='d-flex align-items-center flex-grow-1'>
                {/* begin::Avatar */}
                <div className='symbol symbol-45px me-5'>
                  <img src={`https://fbzipper.com/api/users/profile/${post._user._id}`} alt='' />
                </div>
                {/* end::Avatar */}

                {/* begin::Info */}
                <div className='d-flex flex-column'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                    {post._user.first_name} {post._user.last_name}
                  </a>

                  <span className='text-gray-400 fw-semibold'>{post._group.name}</span>
                </div>
                {/* end::Info */}
              </div>
              {/* end::User */}

              {/* begin::Menu */}
              
              {post._id !== loadingPost._id && <div className='my-0'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={_setLoadingPost.bind(this, post)}
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>
              </div>}

              {post._id === loadingPost._id && <div className='my-0'>
                <div class="dropdown">
                  <button onClick={_setLoadingPost.bind(this, {})} class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                  <div class="dropdown-menu show" aria-labelledby="dropdownMenuButton">
                    {loadingPost.state_loading && <a class="dropdown-item">{intl.formatMessage({id: 'GENERAL.LOADING_DATA'})}<span className='spinner-border spinner-border-sm align-middle ms-2'></span></a>}
                    {!loadingPost.state_loading && <a class="dropdown-item" target="_blank" href={`https://facebook.com/${post._submitted_post.facebook_post_id}`}>View On Facebook</a>}
                  </div>
                </div>
              </div>}


              {/* end::Menu */}
            </div>
            {/* end::Header */}

            {/* begin::Post */}
            <div className='mb-5'>
              {/* begin::Text */}
              <p className='text-gray-800 fw-normal mb-5'>
                {post._post.post_content}
              </p>
              {/* end::Text */}


{post._post.media_url.split(",").map((url) => {
  if (url.indexOf('cdn') === -1) {
    return url.split('.ams3.').join('.ams3.cdn.')
  } else {
    return url;
  }
}).map((url) => (<div
  className="image-input image-input-outline image-input-changed"
  id="kt_image_1"
>
  <div
    className="image-input-wrapper"
    style={{
      backgroundImage:
        `url("https://api.fbzipper.com/generate-thumbnail?url=${url}")`
    }}
  />
  <label
    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
    data-action="change"
    data-toggle="tooltip"
    data-original-title="Change avatar"
    style={{ position: "absolute", top: "-6px", right: "-11px" }}
  >
  </label>
</div>))}

              {/*<div className='d-flex align-items-center mb-5'>
                <a
                  href='#'
                  className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                >
                  <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
                  120
                </a>

                <a
                  href='#'
                  className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
                >
                  <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
                  15
                </a>
              </div>*/}
            </div>
          </div>
        </div>
      </div>))}



      <div id="elementId" style={{textAlign: 'center'}} ref={elementRef}>
        {loadingElement && <span style={{width: '130px', height: '130px'}} className='spinner-border spinner-border-sm align-middle ms-2'></span>}
      </div>





    </div>
    </>
  )
}

export {StatsWrapper}
