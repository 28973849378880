// @ts-nocheck fff
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import './index.scss'
import {GroupWidget} from './GroupWidget'
import {GroupTable} from './GroupTable'
import {useAuth} from '../../../app/modules/auth'
import {Link, useLocation, useMatch, useNavigate} from 'react-router-dom'
import { ReactMediaLibrary } from "react-media-library";
import {useIntl} from 'react-intl'
import {useDropzone} from 'react-dropzone';
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'
import {getUserByToken, login} from '../../modules/auth/core/_requests'
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';


const newCampaignSchema = Yup.object().shape({
  name: Yup.string()
    // .email('Wrong email format')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const Input = styled(MuiInput)`
  width: 42px;
`;

const API_URL = process.env.REACT_APP_API_URL

const initialValues = {};

const SchedulingDisributionWrapper: FC = () => {
  const {auth, currentUser, logout, getBot, setCurrentBot, currentBot, setBot, saveBot, googleLogin, getQRCode, setCurrentUser} = useAuth()
  const intl = useIntl()

  let [campaigns, setCampaigns] = useState(currentUser.campaigns);

  let unallocated = campaigns.reduce((count, camp) => {
    count -= camp.publish_percentage || 0;
    count = Math.max(count, 0);
    return count;
  }, 100);


  return (
    <>
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {intl.formatMessage({id: 'MENU.SCHEDULING_DISTRIBUTION'})}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>
                {/*<button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_1"
                >
                  {intl.formatMessage({id: 'GENERAL.ADD_NEW_CAMPAIGN'})}
                </button>
                <button
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_2"
                >
                  {intl.formatMessage({id: 'GENERAL.DELETE_CAMPAIGN'})}
                </button>*/}
              </div>
            </div>
          </div>

    <PieChart
      series={[
        {
          data: campaigns.filter((a) => a.publish_percentage).map((camp:any) => {
            return { id: camp._id, value: camp.publish_percentage, label: camp.name }
          }),
          // { id: 2, value: 20, label: 'series C' },
        },
      ]}
      width={400}
      height={200}
    />

        </div>

          <Grid container spacing={2} alignItems="center">
            <Grid item>
              Unallocated
            </Grid>
            <Grid item xs>
              <Slider
                value={unallocated}
                onChange={() => {}}
                aria-labelledby="input-slider"
                disabled={true}
              />
            </Grid>
            <Grid item>
              <Input
                value={unallocated}
                size="small"
                onChange={() => {}}
                onBlur={() => {}}
                disabled={true}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        {campaigns.map((camp:any) => (<div>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {camp.name}
            </Grid>
            <Grid item xs>
              <Slider
                value={camp.publish_percentage || 0}
                onChange={(event: Event, newValue: number | number[]) => {

                  let unallocated = campaigns.reduce((count, _camp) => {
                    if (_camp._id == camp._id) {
                      return count;
                    }
                    count -= _camp.publish_percentage || 0;
                    count = Math.max(count, 0);
                    return count;
                  }, 100);

                  if (newValue > unallocated) {
                    newValue = unallocated;
                  }
                  camp.publish_percentage = newValue;
                  setCampaigns([...campaigns])
                }}
                aria-labelledby="input-slider"
              />
            </Grid>
            <Grid item>
              <Input
                value={camp.publish_percentage || 0}
                size="small"
                onChange={() => {}}
                onBlur={() => {}}
                disabled={true}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </div>))}


      </div>
    </div>

    </>
  )
}

export {SchedulingDisributionWrapper}
