import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import {useState, useEffect} from 'react'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import _QRCode from "react-qr-code";
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const LOGOUT_URL = `${API_URL}/api/logout-whatsapp`
export const RESTART_URL = `${API_URL}/api/restart-whatsapp`
export const CONTACTS_URL = `${API_URL}/api/contacts-whatsapp`

function addOrRemove(array: any, value:any) {
    var index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
}

var tzStrings = [
    {"label":"(GMT-12:00) International Date Line West","value":"Etc/GMT+12"},
    {"label":"(GMT-11:00) Midway Island, Samoa","value":"Pacific/Midway"},
    {"label":"(GMT-10:00) Hawaii","value":"Pacific/Honolulu"},
    {"label":"(GMT-09:00) Alaska","value":"US/Alaska"},
    {"label":"(GMT-08:00) Pacific Time (US & Canada)","value":"America/Los_Angeles"},
    {"label":"(GMT-08:00) Tijuana, Baja California","value":"America/Tijuana"},
    {"label":"(GMT-07:00) Arizona","value":"US/Arizona"},
    {"label":"(GMT-07:00) Chihuahua, La Paz, Mazatlan","value":"America/Chihuahua"},
    {"label":"(GMT-07:00) Mountain Time (US & Canada)","value":"US/Mountain"},
    {"label":"(GMT-06:00) Central America","value":"America/Managua"},
    {"label":"(GMT-06:00) Central Time (US & Canada)","value":"US/Central"},
    {"label":"(GMT-06:00) Guadalajara, Mexico City, Monterrey","value":"America/Mexico_City"},
    {"label":"(GMT-06:00) Saskatchewan","value":"Canada/Saskatchewan"},
    {"label":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco","value":"America/Bogota"},
    {"label":"(GMT-05:00) Eastern Time (US & Canada)","value":"US/Eastern"},
    {"label":"(GMT-05:00) Indiana (East)","value":"US/East-Indiana"},
    {"label":"(GMT-04:00) Atlantic Time (Canada)","value":"Canada/Atlantic"},
    {"label":"(GMT-04:00) Caracas, La Paz","value":"America/Caracas"},
    {"label":"(GMT-04:00) Manaus","value":"America/Manaus"},
    {"label":"(GMT-04:00) Santiago","value":"America/Santiago"},
    {"label":"(GMT-03:30) Newfoundland","value":"Canada/Newfoundland"},
    {"label":"(GMT-03:00) Brasilia","value":"America/Sao_Paulo"},
    {"label":"(GMT-03:00) Buenos Aires, Georgetown","value":"America/Argentina/Buenos_Aires"},
    {"label":"(GMT-03:00) Greenland","value":"America/Godthab"},
    {"label":"(GMT-03:00) Montevideo","value":"America/Montevideo"},
    {"label":"(GMT-02:00) Mid-Atlantic","value":"America/Noronha"},
    {"label":"(GMT-01:00) Cape Verde Is.","value":"Atlantic/Cape_Verde"},
    {"label":"(GMT-01:00) Azores","value":"Atlantic/Azores"},
    {"label":"(GMT+00:00) Casablanca, Monrovia, Reykjavik","value":"Africa/Casablanca"},
    {"label":"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London","value":"Etc/Greenwich"},
    {"label":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","value":"Europe/Amsterdam"},
    {"label":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","value":"Europe/Belgrade"},
    {"label":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris","value":"Europe/Brussels"},
    {"label":"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb","value":"Europe/Sarajevo"},
    {"label":"(GMT+01:00) West Central Africa","value":"Africa/Lagos"},
    {"label":"(GMT+02:00) Amman","value":"Asia/Amman"},
    {"label":"(GMT+02:00) Athens, Bucharest, Istanbul","value":"Europe/Athens"},
    {"label":"(GMT+02:00) Beirut","value":"Asia/Beirut"},
    {"label":"(GMT+02:00) Cairo","value":"Africa/Cairo"},
    {"label":"(GMT+02:00) Harare, Pretoria","value":"Africa/Harare"},
    {"label":"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","value":"Europe/Helsinki"},
    {"label":"(GMT+02:00) Jerusalem","value":"Asia/Jerusalem"},
    {"label":"(GMT+02:00) Minsk","value":"Europe/Minsk"},
    {"label":"(GMT+02:00) Windhoek","value":"Africa/Windhoek"},
    {"label":"(GMT+03:00) Kuwait, Riyadh, Baghdad","value":"Asia/Kuwait"},
    {"label":"(GMT+03:00) Moscow, St. Petersburg, Volgograd","value":"Europe/Moscow"},
    {"label":"(GMT+03:00) Nairobi","value":"Africa/Nairobi"},
    {"label":"(GMT+03:00) Tbilisi","value":"Asia/Tbilisi"},
    {"label":"(GMT+03:30) Tehran","value":"Asia/Tehran"},
    {"label":"(GMT+04:00) Abu Dhabi, Muscat","value":"Asia/Muscat"},
    {"label":"(GMT+04:00) Baku","value":"Asia/Baku"},
    {"label":"(GMT+04:00) Yerevan","value":"Asia/Yerevan"},
    {"label":"(GMT+04:30) Kabul","value":"Asia/Kabul"},
    {"label":"(GMT+05:00) Yekaterinburg","value":"Asia/Yekaterinburg"},
    {"label":"(GMT+05:00) Islamabad, Karachi, Tashkent","value":"Asia/Karachi"},
    {"label":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi","value":"Asia/Calcutta"},
    {"label":"(GMT+05:30) Sri Jayawardenapura","value":"Asia/Calcutta"},
    {"label":"(GMT+05:45) Kathmandu","value":"Asia/Katmandu"},
    {"label":"(GMT+06:00) Almaty, Novosibirsk","value":"Asia/Almaty"},
    {"label":"(GMT+06:00) Astana, Dhaka","value":"Asia/Dhaka"},
    {"label":"(GMT+06:30) Yangon (Rangoon)","value":"Asia/Rangoon"},
    {"label":"(GMT+07:00) Bangkok, Hanoi, Jakarta","value":"Asia/Bangkok"},
    {"label":"(GMT+07:00) Krasnoyarsk","value":"Asia/Krasnoyarsk"},
    {"label":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi","value":"Asia/Hong_Kong"},
    {"label":"(GMT+08:00) Kuala Lumpur, Singapore","value":"Asia/Kuala_Lumpur"},
    {"label":"(GMT+08:00) Irkutsk, Ulaan Bataar","value":"Asia/Irkutsk"},
    {"label":"(GMT+08:00) Perth","value":"Australia/Perth"},
    {"label":"(GMT+08:00) Taipei","value":"Asia/Taipei"},
    {"label":"(GMT+09:00) Osaka, Sapporo, Tokyo","value":"Asia/Tokyo"},
    {"label":"(GMT+09:00) Seoul","value":"Asia/Seoul"},
    {"label":"(GMT+09:00) Yakutsk","value":"Asia/Yakutsk"},
    {"label":"(GMT+09:30) Adelaide","value":"Australia/Adelaide"},
    {"label":"(GMT+09:30) Darwin","value":"Australia/Darwin"},
    {"label":"(GMT+10:00) Brisbane","value":"Australia/Brisbane"},
    {"label":"(GMT+10:00) Canberra, Melbourne, Sydney","value":"Australia/Canberra"},
    {"label":"(GMT+10:00) Hobart","value":"Australia/Hobart"},
    {"label":"(GMT+10:00) Guam, Port Moresby","value":"Pacific/Guam"},
    {"label":"(GMT+10:00) Vladivostok","value":"Asia/Vladivostok"},
    {"label":"(GMT+11:00) Magadan, Solomon Is., New Caledonia","value":"Asia/Magadan"},
    {"label":"(GMT+12:00) Auckland, Wellington","value":"Pacific/Auckland"},
    {"label":"(GMT+12:00) Fiji, Kamchatka, Marshall Is.","value":"Pacific/Fiji"},
    {"label":"(GMT+13:00) Nuku'alofa","value":"Pacific/Tongatapu"}
]

var _getQRCode: any;
var _setQRCode: any;
var _getBot: any;

const SettingsWrapper = () => {
  const {currentUser, logout, getBot, setCurrentBot, currentBot, setBot, saveBot, googleLogin, getQRCode} = useAuth()
  _getQRCode = getQRCode
  _getBot = getBot
  const [loading, setLoading] = useState(false)
  const [QRCode, setQRCode] = useState(getBot().qr_code)
  _setQRCode = setQRCode;
  getBot().settings.timezone = getBot().settings.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  setBot('a', 'a');

  var _saveBot = async () => {
    setLoading(true);

    try {
      await saveBot();
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  var login = useGoogleLogin({
    onSuccess: async (token) => {
      // console.log(token)
      var bot = getBot();
      // bot.google_login.authuser = token.authuser;
      
      var result = await googleLogin(bot, token.code)
      console.log(result);
      bot.google_login.refresh_token = result.data.refresh_token;
    
      setBot('a', 'a')
    },
    scope: 'https://www.googleapis.com/auth/contacts',
    flow: 'auth-code',
  })

  var logout_whatsapp = async () => {
    var bot = getBot();

    const response = await axios.post(LOGOUT_URL, {
      bot
    });
  }
  var restart_whatsapp = async () => {
    var bot = getBot();

    const response = await axios.post(RESTART_URL, {
      bot
    });
  }
  var get_contacts_whatsapp = async () => {
    var bot = getBot();

    const response = await axios.post(CONTACTS_URL, {
      bot
    });
  }

  useEffect(() => {

    const interval = setInterval(async () => {
      try {
        var d = await _getQRCode(_getBot());
        _setQRCode(d.data.qr_code);
      } catch (e) {

      }
    }, 2000 )
    return () => clearInterval(interval);
  }, []);


  return (
    <div className='card mb-5 mb-xl-10'>
      {/*<pre>
        {JSON.stringify(currentBot, null, 4)}
      </pre>*/}
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Settings</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>Bot Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Bot Name'
                  onChange={(e) => {
                    setBot('name', e.target.value)
                  }}
                  value={getBot().name}
                />
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Automation Status</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input 
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={getBot().active}
                    onChange={(e) => {
                      setBot('active', !getBot().active)
                    }}
                    />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Test Mode</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input 
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={getBot().settings.test_mode}
                    onChange={(e) => {
                      getBot().settings.test_mode = !getBot().settings.test_mode;
                      setBot('a', 'a')
                    }}
                    />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Auto Forward Incoming Messages</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input 
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={getBot().settings.auto_forward_incoming_messages}
                    onChange={(e) => {
                      getBot().settings.auto_forward_incoming_messages = !getBot().settings.auto_forward_incoming_messages;
                      setBot('a', 'a')
                    }}
                    />
                </div>
              </div>
            </div>

            {getBot().settings.auto_forward_incoming_messages && <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>Forward To Number</label>

              <div className='col-lg-8 fv-row'>
                <input
                  className='form-control form-control-lg form-control-solid'
                  placeholder='+1 1234 66666'
                  onChange={(e) => {
                      getBot().settings.auto_forward_to_number = e.target.value;
                      setBot('a', 'a')
                  }}
                  value={getBot().settings.auto_forward_to_number}
                />
              </div>
            </div>}



            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>I want to use my phone as master device</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input 
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={getBot().settings.my_phone_is_master}
                    onChange={(e) => {
                      getBot().settings.my_phone_is_master = !getBot().settings.my_phone_is_master;
                      setBot('a', 'a')
                    }}
                    />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Shabbat And Jewish Holiday Mode</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input 
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={getBot().settings.shabbat_mode}
                    onChange={(e) => {
                      getBot().settings.shabbat_mode = !getBot().settings.shabbat_mode;
                      setBot('a', 'a')
                    }}
                    />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>Default Signature</label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Default Signature'
                  onChange={(e) => {
                    getBot().settings.default_signature = e.target.value;
                    setBot('a', 'a')
                  }}
                  value={getBot().settings.default_signature}
                ></textarea>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Scheduling</label>

              <div className='col-lg-8 fv-row'>
                <div className='row mb-5'>
                  <div className='col-lg-3 '>
                    <label className='mb-6'>
                      <span className='fw-bold ps-2 fs-6'>Days</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Sunday') > -1}
                        value="Sunday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Sunday</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Monday') > -1}
                        value="Monday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Monday</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Tuesday') > -1}
                        value="Tuesday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Tuesday</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Wednesday') > -1}
                        value="Wednesday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Wednesday</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Thursday') > -1}
                        value="Thursday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Thursday</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Friday') > -1}
                        value="Friday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Friday</span>
                    </label>
                    <br />
                    <label className='form-check form-check-inline form-check-solid me-5 mb-4'>
                      <input
                        className='form-check-input'
                        name='days[]'
                        type='checkbox'
                        checked={getBot().settings.scheduling_days.indexOf('Saturday') > -1}
                        value="Saturday"
                        onChange={(e) => {
                          addOrRemove(getBot().settings.scheduling_days, e.target.value)
                          setBot('a', 'a')
                        }}
                      />
                      <span className='fw-bold ps-2 fs-6'>Saturday</span>
                    </label>
                  </div>
                  <div className='col-lg-5 '>

                    <label className='mb-6'>
                      <span className='fw-bold ps-2 fs-6'>Timezone</span>
                    </label>
                    <br />
                    <label className='d-flex align-items-center me-5 mb-4'>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        value={getBot().settings.timezone}
                        onChange={(e) => {
                          getBot().settings.timezone = e.target.value;
                          setBot('a', 'a')
                        }}
                      >
                        {tzStrings.map((a) => <option value={a.value}>{a.label}</option>)}
                      </select>
                    </label>


                    <label className='mb-6'>
                      <span className='fw-bold ps-2 fs-6'>Times ({getBot().settings.time_range.to - getBot().settings.time_range.from} hour range)</span>
                    </label>
                    <br />
                    <label className='d-flex align-items-center me-5 mb-4'>
                      <span className='fw-bold ps-2 fs-6 me-5'>Start</span>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        value={getBot().settings.time_range.from}
                        onChange={(e) => {
                          getBot().settings.time_range.from = e.target.value;
                          setBot('a', 'a')
                        }}
                      >
                        <option value={0}>00:00 am</option>
                        <option value={0.5}>00:30 am</option>
                        <option value={1}>01:00 am</option>
                        <option value={1.5}>01:30 am</option>
                        <option value={2}>02:00 am</option>
                        <option value={2.5}>02:30 am</option>
                        <option value={3}>03:00 am</option>
                        <option value={3.5}>03:30 am</option>
                        <option value={4}>04:00 am</option>
                        <option value={4.5}>04:30 am</option>
                        <option value={5}>05:00 am</option>
                        <option value={5.5}>05:30 am</option>
                        <option value={6}>06:00 am</option>
                        <option value={6.5}>06:30 am</option>
                        <option value={7}>07:00 am</option>
                        <option value={7.5}>07:30 am</option>
                        <option value={8}>08:00 am</option>
                        <option value={8.5}>08:30 am</option>
                        <option value={9}>09:00 am</option>
                        <option value={9.5}>09:30 am</option>
                        <option value={10}>10:00 am</option>
                        <option value={10.5}>10:30 am</option>
                        <option value={11}>11:00 am</option>
                        <option value={11.5}>11:30 am</option>
                        <option value={12}>12:00 pm</option>
                        <option value={12.5}>12:30 pm</option>
                        
                        <option value={13}>01:00 pm</option>
                        <option value={13.5}>01:30 pm</option>
                        <option value={14}>02:00 pm</option>
                        <option value={14.5}>02:30 pm</option>
                        <option value={15}>03:00 pm</option>
                        <option value={15.5}>03:30 pm</option>
                        <option value={16}>04:00 pm</option>
                        <option value={16.5}>04:30 pm</option>
                        <option value={17}>05:00 pm</option>
                        <option value={17.5}>05:30 pm</option>
                        <option value={18}>06:00 pm</option>
                        <option value={18.5}>06:30 pm</option>
                        <option value={19}>07:00 pm</option>
                        <option value={19.5}>07:30 pm</option>
                        <option value={20}>08:00 pm</option>
                        <option value={20.5}>08:30 pm</option>
                        <option value={21}>09:00 pm</option>
                        <option value={21.5}>09:30 pm</option>
                        <option value={22}>10:00 pm</option>
                        <option value={22.5}>10:30 pm</option>
                        <option value={23}>11:00 pm</option>
                        <option value={23.5}>11:30 pm</option>
                      </select>
                    </label>
                    <br />
                    <label className='d-flex align-items-center me-5 mb-4'>
                      <span className='fw-bold ps-2 fs-6 me-5'>End</span>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        value={getBot().settings.time_range.to}
                        onChange={(e) => {
                          getBot().settings.time_range.to = e.target.value;
                          setBot('a', 'a')
                        }}
                      >
                        <option disabled={getBot().settings.time_range.from >= 0} value={0}>00:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 0.5} value={0.5}>00:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 1} value={1}>01:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 1.5} value={1.5}>01:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 2} value={2}>02:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 2.5} value={2.5}>02:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 3} value={3}>03:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 3.5} value={3.5}>03:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 4} value={4}>04:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 4.5} value={4.5}>04:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 5} value={5}>05:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 5.5} value={5.5}>05:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 6} value={6}>06:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 6.5} value={6.5}>06:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 7} value={7}>07:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 7.5} value={7.5}>07:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 8} value={8}>08:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 8.5} value={8.5}>08:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 9} value={9}>09:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 9.5} value={9.5}>09:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 10} value={10}>10:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 10.5} value={10.5}>10:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 11} value={11}>11:00 am</option>
                        <option disabled={getBot().settings.time_range.from >= 11.5} value={11.5}>11:30 am</option>
                        <option disabled={getBot().settings.time_range.from >= 12} value={12}>12:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 12.5} value={12.5}>12:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 13} value={13}>01:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 13.5} value={13.5}>01:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 14} value={14}>02:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 14.5} value={14.5}>02:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 15} value={15}>03:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 15.5} value={15.5}>03:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 16} value={16}>04:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 16.5} value={16.5}>04:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 17} value={17}>05:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 17.5} value={17.5}>05:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 18} value={18}>06:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 18.5} value={18.5}>06:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 19} value={19}>07:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 19.5} value={19.5}>07:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 20} value={20}>08:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 20.5} value={20.5}>08:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 21} value={21}>09:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 21.5} value={21.5}>09:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 22} value={22}>10:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 22.5} value={22.5}>10:30 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 23} value={23}>11:00 pm</option>
                        <option disabled={getBot().settings.time_range.from >= 23.5} value={23.5}>11:30 pm</option>
                      </select>
                    </label>
                  </div>
                  <div className='col-lg-4 '>
                    <label className='mb-6'>
                      <span className='fw-bold ps-2 fs-6'>Buffer</span>
                    </label>
                    <br />
                    <label className='d-flex align-items-center me-5 mb-4'>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        value={getBot().settings.buffer_minutes}
                        onChange={(e) => {
                          getBot().settings.buffer_minutes = e.target.value;
                          setBot('a', 'a')
                        }}
                      >
                        <option value={0}>None</option>
                        <option value={5}>5 minutes</option>
                        <option value={10}>10 minutes</option>
                        <option value={15}>15 minutes</option>
                        <option value={20}>20 minutes</option>
                        <option value={25}>25 minutes</option>
                        <option value={35}>35 minutes</option>
                        <option value={35}>35 minutes</option>
                        <option value={45}>45 minutes</option>
                        <option value={45}>45 minutes</option>
                        <option value={55}>55 minutes</option>
                        <option value={55}>55 minutes</option>
                        <option value={60}>60 minutes</option>
                        <option value={90}>90 minutes</option>
                        <option value={120}>120 minutes</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-12'>
                    <a href='#'>Disable the automation</a>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold  fs-6'>Saving Contacts</label>

              <div className='col-lg-8 d-flex align-items-center flex-direction-column'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={getBot().settings.save_contacts}
                    onChange={(e) => {
                          getBot().settings.save_contacts = !getBot().settings.save_contacts;
                          setBot('a', 'a')
                    }}
                  />
                </div>
              </div>
            </div>
            {getBot().settings.save_contacts && <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold  fs-6'></label>

              <div className='col-lg-8 d-flex align-items-center flex-direction-column'>
                {!getBot().google_login.refresh_token && <button
                  type={'button'}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => login()} //error is here
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap'
                >
                  <img
                    alt='Logo'

                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-15px me-3'
                  />
                  Connect with Google
                </button>}
                {getBot().google_login.refresh_token && <button
                  type={'button'}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { getBot().google_login.refresh_token = ''; setBot('a', 'a'); }} //error is here
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap'
                >
                  <img
                    alt='Logo'

                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-15px me-3'
                  />
                  Disconnect
                </button>}
              </div>
            </div>}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>Scan Whatsapp</label>

              <div className='col-lg-8 fv-row'>
                {QRCode && <_QRCode value={QRCode} />}
                {!QRCode && <button type="button" className="btn btn-sm btn-warning" onClick={logout_whatsapp.bind(this)}>Logout</button>}
                {!QRCode && <button type="button" className="btn btn-sm btn-warning" onClick={restart_whatsapp.bind(this)}>Restart</button>}
                {!QRCode && <button type="button" className="btn btn-sm btn-warning" onClick={get_contacts_whatsapp.bind(this)}>Get Contacts</button>}
              </div>

            </div>


          </div>




          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            
        <button
          type='button'
          className='btn btn-primary'
          disabled={false}
          onClick={_saveBot.bind(this)}
        >
          {!loading && <span className='indicator-label'>Save</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {SettingsWrapper}
