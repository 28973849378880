// @ts-nocheck
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {IUpdatePassword, IUpdateEmail, updatePassword, updateEmail} from '../SettingsModel'
import {useIntl} from 'react-intl'
import axios from 'axios';
import { authenticator } from 'otplib';

const API_URL = process.env.REACT_APP_API_URL

const emailFormValidationSchema = Yup.object().shape({
  newUsername: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const passwordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const SignInMethod: React.FC = () => {
  const intl = useIntl()
  const [currentProfile, setCurrentProfile] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);

  const [seed, setSeed] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    authenticator.options = { step: 30 }; // Sets the TOTP code to refresh every 30 seconds
    const generateToken = () => {
      // Convert the base32 encoded seed into the token
      const token = authenticator.generate(seed);
      if (seed.trim()) {
        setToken(token);
      } else {
        setToken('');
      }
    };

    generateToken();
    const interval = setInterval(generateToken, 30000); // Update the token every 30 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [seed]);



  useEffect(() => {
    (async () => {
      try {
        var data = await axios.get(`${API_URL}/api/get-single-profile`);

        setCurrentProfile(data.data);
        setAllProfiles(data.data.all_users);

        formik1.setValues({
          newUsername: data.data.facebook_username || '',
          confirmPassword: data.data.facebook_password,
        });
  
      } catch (e) {
        console.log(e);
        alert('error')
      }

   })();
  }, []);


  const [emailUpdateData, setEmailUpdateData] = useState<IUpdateEmail>(updateEmail)
  const [passwordUpdateData, setPasswordUpdateData] = useState<IUpdatePassword>(updatePassword)

  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [showPasswordForm, setPasswordForm] = useState<boolean>(false)

  const [loading1, setLoading1] = useState(false)

  const formik1 = useFormik<IUpdateEmail>({
    initialValues: {
    },
    validationSchema: emailFormValidationSchema,
    onSubmit: (values) => {
      setLoading1(true);


      values._id = currentProfile._id;
      return axios.post(`${API_URL}/api/set-single-profile`, values).then((data) => {
        setCurrentProfile(data.data)
        setLoading1(false)
        setShowEmailForm(false);


      }).catch(() => {
        alert('error')
      })

    },
  })
  const saveSeed = (profile) => {
        return axios.post(`${API_URL}/api/set-seed`, profile).then((data) => {
      }).catch(() => {
        alert('error')
      })

  }

  const formik2 = useFormik<IUpdateEmail>({
    initialValues: {
    },
    // validationSchema: emailFormValidationSchema,
    onSubmit: (values) => {
      setLoading1(true);
      //return;
      values._id = currentProfile._id;
      values.newUsername = currentProfile.facebook_username;
      values.confirmPassword = currentProfile.facebook_password;

      return axios.post(`${API_URL}/api/set-single-profile`, values).then((data) => {
        setCurrentProfile(data.data)
        setLoading1(false)
        setShowEmailForm(false);

        allProfiles.map((a) => a.showEditForm = false);
        allProfiles.map((a) => a.showEdit2stepAuth = false);
        setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));



      }).catch(() => {
        alert('error')
      })

    },
  })

  const [loading2, setLoading2] = useState(false)

  /*const formik2 = useFormik<IUpdatePassword>({
    initialValues: {
      ...passwordUpdateData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (values) => {
      setLoading2(true);
      values._id = currentProfile._id;
      return axios.post(`${API_URL}/api/set-single-profile`, values).then(() => {
        setLoading2(false)
      }).catch(() => {
        alert('error')
      })
    },
  })*/

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'PROFILE.YOUR_LOGIN_DETAILS'})}</h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div id='kt_signin_email' className={' ' + (showEmailForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>{intl.formatMessage({id: 'PROFILE.LOGIN_DETAILS'})}</div>
              <div className='fw-bold text-gray-600'>{currentProfile?.facebook_username || '****@gmail.com'}</div>
              <div className='fw-bold text-gray-600'>***********</div>
            </div>

            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid ' + (!showEmailForm && 'd-none')}
            >
              <form
                onSubmit={formik1.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='row mb-6'>
                  <div className='col-lg-6 mb-4 mb-lg-0'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='emailaddress' className='form-label fs-6 fw-bolder mb-3'>
                      {intl.formatMessage({id: 'PROFILE.ENTER_USERNAME'})}
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        id='emailaddress'
                        placeholder='username'
                        value={currentProfile?.facebook_username || ''}
                        {...formik1.getFieldProps('newUsername')}
                        
                      />
                      {formik1.touched.newUsername && formik1.errors.newUsername && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik1.errors.newUsername}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='fv-row mb-0'>
                      <label
                        htmlFor='confirmemailpassword'
                        className='form-label fs-6 fw-bolder mb-3'
                      >
                        {intl.formatMessage({id: 'PROFILE.ENTER_PASSWORD'})}
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        id='confirmemailpassword'
                        {...formik1.getFieldProps('confirmPassword')}
                      />
                      {formik1.touched.confirmPassword && formik1.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik1.errors.confirmPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    id='kt_signin_submit'
                    type='submit'
                    className='btn btn-primary  me-2 px-6'
                  >
                    {!loading1 && intl.formatMessage({id: 'PROFILE.UPDATE_CREDS'}) }
                    {loading1 && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    id='kt_signin_cancel'
                    type='button'
                    onClick={() => {
                      setShowEmailForm(false)
                    }}
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    {intl.formatMessage({id: 'PROFILE.CANCEL'})}
                  </button>
                </div>
              </form>
            </div>

            <div id='kt_signin_email_button' className={'ms-auto ' + (showEmailForm && 'd-none')}>
              <button
                onClick={() => {
                  setShowEmailForm(true)
                }}
                className='btn btn-light btn-active-light-primary'
              >
                {intl.formatMessage({id: 'PROFILE.CHANGE_LOGIN_INFO'})}
              </button>
            </div>
          </div>

          </div>
          </div>



      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>

          {/*<div>
          1. open facebook 2 step auth <a className={'btn btn-primary'} href="https://accountscenter.facebook.com/password_and_security/two_factor" target="_blank">OPEN 2 STEP AUTH</a>
          </div>
          <div>
          2. click facebook account
          </div>
          <div>
          3. confirm 2 step auth if needed
          </div>
          <div>
          4. enter password if needed
          </div>
          <div>
          5. Click authentication App
          </div>
          <div>
          6. Under "Connected devices" click "Add"
          </div>
          <div>
          7. enter a name (any name)
          </div>
          <div>
          8. copy the authentication key example "JI2J LZLX EUCI QUVC TOPD S5KR MWGW TDKH"
          </div>
          <div>
          9. click next
          </div>
          <div>
          10. Enter the key here 
          </div>
          <div>
          11. you will get the 6 digit code here
          </div>
          <div>
          12. enter it into facebook
          </div>
          <div>
          13. click next
          </div>
          <div>
          14. DONE.
          </div>*/}
            

        </div>
      </div>

<div className="card card-xl-stretch mb-xl-8">
  <div className="card-header border-0">
    <h3 className="card-title fw-bold text-gray-900">Profiles</h3>
  </div>
  <div className="card-body pt-2">
    {allProfiles.map((profile) => <div className="d-flex align-items-center mb-7">
      <div className="symbol symbol-50px me-5">
        <img src="https://preview.keenthemes.com/metronic8/react/demo1/media/avatars/300-6.jpg" alt="" />
      </div>
      <div className="flex-grow-1">
        {!profile.showEditForm && <>
        <a className="text-gray-900 fw-bold text-hover-primary fs-6">{profile.facebook_username}</a>
        <span className="text-muted d-block fw-semibold">**********</span>
        </>}


            <div
              id='kt_signin_email_edit'
              className={'flex-row-fluid ' + (!profile.showEditForm && 'd-none')}
            >
              <form
                onSubmit={formik2.handleSubmit}
                id='kt_signin_change_email'
                className='form'
                noValidate
              >
                <div className='row mb-6'>
                  <div className='col-lg-6 mb-4 mb-lg-0'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='emailaddress' className='form-label fs-6 fw-bolder mb-3'>
                      {intl.formatMessage({id: 'PROFILE.ENTER_USERNAME'})}
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        id='emailaddress'
                        placeholder='username'
                        value={profile?.facebook_username || ''}
                        onChange={(event) => {
                            profile.facebook_username = event.target.value;
                            setCurrentProfile(profile)
                            setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                        }}
                      />
                      {formik2.touched.newUsername && formik2.errors.newUsername && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.newUsername}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='fv-row mb-0'>
                      <label
                        htmlFor='confirmemailpassword'
                        className='form-label fs-6 fw-bolder mb-3'
                      >
                        {intl.formatMessage({id: 'PROFILE.ENTER_PASSWORD'})}
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        id='confirmemailpassword'
                        value={profile?.facebook_password || ''}
                        onChange={(event) => {
                            profile.facebook_password = event.target.value;
                            setCurrentProfile(profile)
                            setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                        }}
                      />
                      {formik2.touched.confirmPassword && formik2.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik2.errors.confirmPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    id='kt_signin_submit'
                    type='submit'
                    className='btn btn-primary  me-2 px-6'
                  >
                    {!loading1 && intl.formatMessage({id: 'PROFILE.UPDATE_CREDS'}) }
                    {loading1 && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    id='kt_signin_cancel'
                    type='button'
                    onClick={() => {
                      profile.showEditForm = false;
                      setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                    }}
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    {intl.formatMessage({id: 'PROFILE.CANCEL'})}
                  </button>
                </div>
              </form>
            </div>


            <div id='kt_signin_email_button' className={'ms-auto ' + (profile.showEditForm && 'd-none')}>
              <button
                onClick={() => {
                  
                  allProfiles.map((a) => a.showEditForm = false);
                  allProfiles.map((a) => a.showEdit2stepAuth = false);

                  profile.showEditForm = true;
                  console.log(profile)
                  setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                }}
                className='btn btn-light btn-active-light-primary'
              >
                {intl.formatMessage({id: 'PROFILE.CHANGE_LOGIN_INFO'})}
              </button>
            </div>


        {<span className="text-muted d-block fw-semibold">

          {!profile.showEditForm && !profile.showEdit2stepAuth && <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
            <KTSVG
              path='/media/icons/duotune/general/gen048.svg'
              className='svg-icon-2tx svg-icon-primary me-4'
            />
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>{intl.formatMessage({id: 'PROFILE.I_HAVE_2_STEP_AUTH'})}</h4>
                <div className='fs-6 text-gray-600 pe-7'>
                  {intl.formatMessage({id: 'PROFILE.2_STEP_AUTH_TEXT'})}
                </div>
              </div>
              <a
                className='btn btn-primary px-6 align-self-center text-nowrap'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_two_factor_authentication'

                onClick={() => {
                  
                  allProfiles.map((a) => a.showEditForm = false);
                  allProfiles.map((a) => a.showEdit2stepAuth = false);

                  profile.showEdit2stepAuth = true;
                  setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                }}


              >
                {intl.formatMessage({id: 'PROFILE.ENABLE'})}
              </a>
            </div>
          </div>}


          {!profile.showEditForm && profile.showEdit2stepAuth && <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTSVG
              path='/media/icons/duotune/general/gen048.svg'
              className='svg-icon-2tx svg-icon-warning me-4'
            />
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>{intl.formatMessage({id: 'Enable Two-Step Authentication on Facebook'})}</h4>
                <div className='fs-6 text-gray-600 pe-7'>

    <ol>
        <li>Open your browser and navigate to the Facebook Two-Step Authentication page by visiting <a href="https://accountscenter.facebook.com/password_and_security/two_factor" target="_blank">this link</a>.</li>
        <li>Log in to your Facebook account if prompted.</li>
        <li>Once logged in, follow any prompts to confirm your choice to enable two-step authentication.</li>
        <li>If asked, enter your password to access the two-step authentication settings.</li>
        <li>In the two-step authentication section, click on "<strong>Authentication App</strong>".</li>
        <li>Look for the section labeled "Connected devices" and click "Add" to connect a new device.</li>
        <li>Enter a name for the new device. This can be any descriptive name you choose.</li>
        <li>Facebook will display an authentication key. Copy this key. It will appear similar to "JI2J LZLX EUCI QUVC TOPD S5KR **** ****".</li>
        <li>Click "Next" after copying the key.</li>
        <li>Enter the copied key into the input box here to generate a 6-digit verification code.</li>
        <li>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        id='emailaddress'
                        placeholder='**** **** **** **** **** **** **** ****'
                        value={profile?.seed || ''}
                        
                        onChange={(event) => {
                            profile.seed = event.target.value;
                            setCurrentProfile(profile)
                            setSeed(profile.seed)
                            setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                        }}

                      />

        </li>        
        <li>Enter the 6-digit code back into the Facebook setup page.</li>
        <li>

                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        id='emailaddress'
                        placeholder='******'
                        value={profile?.seed ? token : ''}
                        disabled={true}
                      />

        </li>        
        <li>Click "Next" to verify the code.</li>
        <li>You should see a confirmation that two-step authentication is now enabled.</li>
        <li>
              <a
                className='btn btn-primary px-6 align-self-center text-nowrap'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_two_factor_authentication'

                onClick={() => {
                  saveSeed(profile)
                  allProfiles.map((a) => a.showEditForm = false);
                  allProfiles.map((a) => a.showEdit2stepAuth = false);

                  setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                }}


              >
                {intl.formatMessage({id: 'PROFILE.SAVE'})}
              </a>
        </li>
    </ol>


                </div>
              </div>
              <a
                onClick={() => {
                  
                  allProfiles.map((a) => a.showEditForm = false);
                  allProfiles.map((a) => a.showEdit2stepAuth = false);

                  setAllProfiles(JSON.parse(JSON.stringify(allProfiles)));
                }}

                className='btn btn-warning px-6 align-self-center text-nowrap'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_two_factor_authentication'
              >
                {intl.formatMessage({id: 'PROFILE.CANCEL'})}
              </a>
            </div>
          </div>}



        </span>}
      </div>
    </div>)}
  </div>
</div>



    </div>
  )
}

export {SignInMethod}
