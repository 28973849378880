/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import * as React from 'react'
// import {PageTitle} from '../../../_metronic/layout/core'
import './index.scss'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const GrantAccessWrapper: FC = () => {
  const [value, setValue] = React.useState('iprovide')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-3'></div>
        <div className='col-xl-6'>
          <div className={`card card-xxl-stretch mb-xl-3`}>
            <div className='card-header border-0'>
              <h3 className='card-title fw-bold text-dark'>Grant Access</h3>
            </div>

            <div className='card-body pt-2 grant_access'>
              <p className='italic'>
                You can either use a new number you bring, or we can provide one. This number must
                be added as an admin user to all groups you want to manage.
              </p>
              <Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby='dgroup-label'
                    name='radio-buttons-group'
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value='iprovide'
                      control={<Radio />}
                      label='I will provide a number'
                    />
                    <FormControlLabel
                      value='provideme'
                      control={<Radio />}
                      label='Provide me a number'
                    />
                  </RadioGroup>
                </FormControl>
                <Typography className='text-center'>
                  {value === 'iprovide' ? (
                    <img
                      src={toAbsoluteUrl('/media/img/qr.png')}
                      className='img-responsive'
                      alt='img'
                    />
                  ) : (
                    <>
                      <p>Your Number is:</p>
                      <p className='number'>+972-553-5567</p>
                    </>
                  )}

                  <button type='submit' className='btn btn-lg btn-primary w-100 mt-5 mb-5'>
                    Continue
                  </button>
                </Typography>
              </Box>
            </div>
          </div>
        </div>
        <div className='col-xl-3'></div>
      </div>
    </>
  )
}

export {GrantAccessWrapper}
