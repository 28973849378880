// @ts-nocheck
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Vertical} from './components/Vertical'
import {Horizontal} from './components/Horizontal'

const RegisterPage = (props) => (
  <Horizontal type={props.type}/>
)

export default RegisterPage
