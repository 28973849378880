import {useEffect} from 'react'
import {initialQueryState, KTSVG} from '../../../_metronic/helpers'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {useQueryRequest} from '../../modules/apps/user-management/users-list/core/QueryRequestProvider'

const ContentFilter = () => {
  const {updateState} = useQueryRequest()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-primary me-5'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New Automation
      </button>
      <button type='button' className='btn btn-primary'>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New Post
      </button>

      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Automation Options</div>
        </div>

        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-5'>
            <input
              type='text'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoFocus
            ></input>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {ContentFilter}
