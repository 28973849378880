/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import './index.scss'

const AddOnsWrapper: FC = () => {
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Add-Ons</h3>

        <div className='d-flex flex-wrap my-2'>
          <div className='me-4 d-flex align-items-center'>
            <span className='fs-6 text-black-400 fw-bold me-4'>Filter</span>
            <select name='status' className='form-select form-select-sm form-select-white w-125px'>
              <option value='all'>All</option>
              <option>1</option>
            </select>
          </div>
        </div>
      </div>
      <div className='row gy-5 gx-xl-8 addon'>
        <div className='col-xl-4'>
          <div className={`card card-xxl-stretch mb-xl-3`}>
            <div className='card-body pt-2 contacts'>
              <div className={`card card-xxl-stretch`}>
                <div className='card-body py-3 pt-5 text-center'>
                  <i className='fa-solid fa-rss' style={{fontSize: '30px'}}></i>
                  <h3 className='mt-4'>Rss to Groups</h3>
                  <p className='mt-2'>
                    Automatically post new content from your site to your groups.
                  </p>
                  <a href='#' className='btn btn-primary btn-sm'>
                    Add - $19/m
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          <div className={`card card-xxl-stretch mb-xl-3`}>
            <div className='card-body pt-2 contacts'>
              <div className={`card card-xxl-stretch`}>
                <div className='card-body py-3 pt-5 text-center'>
                  <i className='fa-brands fa-instagram' style={{fontSize: '30px'}}></i>
                  <h3 className='mt-4'>Instagram to Groups</h3>
                  <p className='mt-2'>
                    Automatically post content from your Instagram page to your groups
                  </p>
                  <a href='#' className='btn btn-primary btn-sm'>
                    Settings
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {AddOnsWrapper}
