const PaymentWrapper = () => {
  return (
    <div className='row'>
      <div className='col-md-6 offset-md-3 col-lg-4 offset-lg-4 fv-row'>
        <div className='card mb-5 mb-xl-10'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Automation Info</h3>
            </div>
          </div>
          <div className='collapse show'>
            <form noValidate className='form'>
              <div className='card-body border-top p-9'>
                <p className='text-gray-800 fw-normal mb-5'>
                  You'll be billed $99 a month for this automation
                </p>
                <div className='row mb-6'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Automation Name'
                    ></input>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Name on Card'
                    ></input>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Card Number'
                    ></input>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-4 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='CCV'
                    ></input>
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Expiry'
                    ></input>
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Zip'
                    ></input>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary'>
                  Add Automation
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export {PaymentWrapper}
