import clsx from 'clsx'
import * as React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
// import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'


const Navbar = () => {
  const {config} = useLayout()
  const [value, setValue] = React.useState('addnew')
  let navigate = useNavigate();
  const {currentUser, logout, getBot, _setCurrentBot} = useAuth()
  const location = useLocation();

  // if (!getBot()._id && location.pathname !== '/new-bot') {
  //   console.log(1111111);
  //   // return navigate('/new-bot')
  // }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    _setCurrentBot(event.target.value)
    setValue(event.target.value);

    let value = event.target.value
    if (value === 'addnew') {
      navigate('/new-bot')
    } else {
      navigate('/campaigns')
    }
  }
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/*<div className={clsx('app-navbar-item', itemClass)}>
        <select
          name='add boat'
          className='form-select form-select-sm form-select-solid'
          onChange={handleChange}
          value={window.location.pathname === '/new-bot' ? 'addnew' : (getBot() ? getBot()._id : null)}
        >
          {currentUser?.bots && currentUser?.bots.map((bot) => <option value={bot._id}>{bot.name}</option>)}
          <option value='addnew'>+ Add New Bot</option>
        </select>
      </div>*/}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
