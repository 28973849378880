import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate, useParams, useMatch} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {GrantAccessWrapper} from '../pages/grantaccess/GrantAccessWrapper'
import {ContactsWrapper} from '../pages/contacts/ContactsWrapper'
import {GroupsWrapper} from '../pages/groups/GroupsWrapper'
import {PlansWrapper} from '../pages/plans/PlansWrapper'
import {StatsWrapper} from '../pages/stats/StatsWrapper'
import {PaymentInformationWrapper} from '../pages/payment/PaymentInformationWrapper'
import {AffiliateWrapper} from '../pages/affiliate/AffiliateWrapper'
import {AffiliatePaymentRequestWrapper} from '../pages/affiliate/AffiliatePaymentRequestWrapper'
import {AffiliateTutorialWrapper} from '../pages/affiliate/AffiliateTutorialWrapper'
import {AffiliateContentWrapper, VideoRecommendationsWrapper, AdvertisingPostsWrapper, ColdCallingWrapper, EmailContentWrapper, AdvertisementVideosWrapper} from '../pages/affiliate/AffiliateContentWrapper'
import {AffiliateLeadsWrapper} from '../pages/affiliate/AffiliateLeadsWrapper'
import {AffiliateAdvertisingWrapper} from '../pages/affiliate/AffiliateAdvertisingWrapper'
import {AffiliateAddNewClientWrapper} from '../pages/affiliate/AffiliateAddNewClientWrapper'










import {SchedulingDisributionWrapper} from '../pages/groups/SchedulingDisributionWrapper'
import {SettingsWrapper} from '../pages/settings/SettingsWrapper'
import {AddOnsWrapper} from '../pages/addons/AddOnsWrapper'
import {PaymentsWrapper} from '../pages/payments/PaymentsWrapper'
import ProfilePage from '../modules/profiles/ProfilePage'
import {ChatWrapper} from '../pages/chat/ChatWrapper'
import {ContentWrapper} from '../pages/content/ContentWrapper'
import { PaymentWrapper } from '../pages/payment/PaymentWrapper'
import {Logout, AuthPage, useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const {currentUser, getBot} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Groups after success login/registartion */}

        <Route path='auth/*' element={<Navigate to='/campaigns' />} />
        {/* Pages */}
        <Route path='new-bot' element={<PaymentsWrapper />} />
        <Route path='grant-access' element={getBot() ? <GrantAccessWrapper /> : <PaymentsWrapper />} />
        <Route path='contacts' element={getBot() ? <ContactsWrapper /> : <PaymentsWrapper />} />
        <Route path='payment' element={getBot() ? <PaymentWrapper /> : <PaymentsWrapper />} />
        <Route path="plans" element={<PlansWrapper />} />
        <Route path="update-payment-information" element={<PaymentInformationWrapper />} />
        <Route path="affiliate/dashboard" element={<AffiliateWrapper />} />

        <Route path="affiliate/payment-request" element={<AffiliatePaymentRequestWrapper />} />

        <Route path='affiliate/content/video-recommendations' element={<VideoRecommendationsWrapper />} />
        <Route path='affiliate/content/advertising-posts' element={<AdvertisingPostsWrapper />} />
        <Route path='affiliate/content/cold-calling' element={<ColdCallingWrapper />} />
        <Route path='affiliate/content/email-content' element={<EmailContentWrapper />} />
        <Route path='affiliate/content/advertisement-videos' element={<AdvertisementVideosWrapper />} />



        <Route path="affiliate/tutorials" element={<AffiliateTutorialWrapper />} />
        <Route path="affiliate/content" element={<AffiliateContentWrapper />} />
        <Route path="affiliate/leads" element={<AffiliateLeadsWrapper />} />
        <Route path="affiliate/leads/:id" element={<AffiliateLeadsWrapper />} />
        <Route path="affiliate/advertising" element={<AffiliateAdvertisingWrapper />} />
        <Route path="affiliate/add-new-client" element={<AffiliateAddNewClientWrapper />} />

        <Route path="profiles" element={<ProfilePage />} />
        <Route path="stats" element={<StatsWrapper />} />
        <Route path="campaigns" element={<GroupsWrapper />} />
        <Route path="campaigns/:id" element={<GroupsWrapper />} />
        <Route path="scheduling-distribution" element={<SchedulingDisributionWrapper />} />
        <Route path='settings' element={getBot() ? <SettingsWrapper /> : <PaymentsWrapper />} />
        <Route path='add-ons' element={getBot() ? <AddOnsWrapper /> : <PaymentsWrapper />} />
        <Route path='inbox' element={getBot() ? <ChatWrapper /> : <PaymentsWrapper />} />
        <Route path='content' element={getBot() ? <ContentWrapper /> : <PaymentsWrapper />} />
        <Route path='builder' element={getBot() ? <BuilderPageWrapper /> : <PaymentsWrapper />} />
        <Route path='menu-test' element={getBot() ? <MenuTestPage /> : <PaymentsWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
