import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const UPDATE_BOT_URL = `${API_URL}/api/update-bot`
export const GOOGLE_LOGIN_URL = `${API_URL}/api/google-login`
export const BOT_QR_CODE = `${API_URL}/api/bot-qr-code`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  yourname: string,
  phonenumber: string,
  email: string,
  password: string,
  changepassword: string
) {
  return axios.post(REGISTER_URL, {
    yourname,
    phonenumber,
    email,
    password,
    changepassword,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function updateBot(bot:any) {
  return axios.post<UserModel>(UPDATE_BOT_URL, {
    // api_token: token,
    bot: bot,
  })
}

export function googleLogin(bot:any, code:any) {
  return axios.post<UserModel>(GOOGLE_LOGIN_URL, {
    // api_token: token,
    bot: bot,
    code: code,
  })
}

export function getQRCode(bot:any) {
  return axios.post<UserModel>(BOT_QR_CODE, {
    // api_token: token,
    bot: bot,
  })
}
