import { CardElement,CardExpiryElement,CardCvcElement,CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Input } from "antd";
import React, { useState } from "react";
import axios from 'axios'
import {useAuth} from '../../../app/modules/auth'

const API_URL = process.env.REACT_APP_API_URL

export const CHECKOUT_URL = `${API_URL}/api/create-subscription`
export const CONFIRM_URL = `${API_URL}/api/create-subscription-confirm`



function CheckoutForm() {
  
  // collect data from the user
  const [priceId, setPriceId] = useState("");
  const [automationName, setAutomationName] = useState("");
  const [ZIP, setZIP] = useState("");
  const {currentUser, logout, getBot, setCurrentBot, currentBot, setBot, saveBot, googleLogin, getQRCode} = useAuth()
  const [name, setName] = useState(currentUser ? currentUser.first_name + ' ' + currentUser.last_name : '');
  const [email, setEmail] = useState('');

  if (!email && currentUser) {
    setEmail(currentUser.email);
  }


  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  // main function
  const createSubscription = async () => {
    try {
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement)!,
        billing_details: {
          name,
          email
          // name: `${currentUser.first_name} ${currentUser.last_name}`,
          // email: currentUser.email,
        },
      });

      // call the backend to create subscription


      const response = await axios.post(CHECKOUT_URL, {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name, 
        email,
        automationName,
        priceId
      });

      if (response.data.error) {
        window.alert('Payment error please try again')
        return;
      }

      const confirmPayment = await stripe?.confirmCardPayment(
        response.data.clientSecret
      );

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        // alert("Success! Check your email for the invoice.");

        const confirm_response = await axios.post(CONFIRM_URL, {
          client_secret: response.data.clientSecret,
          confirm_response: confirmPayment, 
        });
        window.location.reload();

      }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>

    <div className='row'>
      <div className='col-md-6 offset-md-3 col-lg-4 offset-lg-4 fv-row'>
        <div className='card mb-5 mb-xl-10'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Finished Package</h3>
            </div>
          </div>
          <div className='collapse show'>
              <div className='card-body border-top p-9'>
                <p className='text-gray-800 fw-normal mb-5'>
                  You'll be billed $1000 a month
                </p>
                <div className='row mb-6'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Automation Name'
                      value={automationName}
                      onChange={(e) => setAutomationName(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Name on Card'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-12 fv-row'>
                    <CardNumberElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                      className='form-control form-control-lg form-control-solid'
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col-lg-4 fv-row'>
                    <CardCvcElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                      className='form-control form-control-lg form-control-solid'
                    />
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <CardExpiryElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                      className='form-control form-control-lg form-control-solid'
                    />
                  </div>
                  <div className='col-lg-4 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Zip'
                      value={ZIP}
                      onChange={(e) => setZIP(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' onClick={createSubscription} disabled={!stripe}>
                  Subscribe
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CheckoutForm;