// @ts-nocheck

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, updateBot, googleLogin, getQRCode} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

import {useSearchParams} from 'react-router-dom'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  currentBot: any
  setCurrentBot: any
  logout: () => void
  getBot: any
  saveBot: any
  googleLogin: any
  _setCurrentBot: any
  getQRCode: any
  setBot: any
}
const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);

  var vars = query.split("&");

  for (var i=0;i<vars.length;i++) {
              var pair = vars[i].split("=");

              if(pair[0] == variable){return pair[1];}
   }
   return(false);
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentBot: undefined,
  setCurrentBot: () => {},
  logout: () => {},
  getBot: () => {},
  saveBot: () => {},
  googleLogin: () => {},
  _setCurrentBot: () => {},
  getQRCode: () => {},
  setBot: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}



const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentBot, setCurrentBot] = useState<any>({})
  const [affCode, setAffCode] = useState<any>(getQueryVariable('aff'))

  if (affCode) {
    localStorage.setItem('aff', affCode);
  }

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  const getBot = ():any => {
    if (currentUser && currentUser.bots) {
      var bot = currentUser.bots.find((b) => {
        return b._id == localStorage.getItem('bot_id');
      });

      if (!bot && currentUser && currentUser.bots.length > 0) {
        localStorage.setItem('bot_id', currentUser.bots[0]._id)
        return getBot();
      }
      // var aaaa = bot || {chats: []};

      
      
      setCurrentBot(bot);
      // window.alert(bot)
      return bot;
    }
  }
  const saveBot = ():any => {
    return new Promise((resolve:any, reject) => {
      (async () => {
        await updateBot(getBot());
        resolve();
      })()
    });
  }
  const _setCurrentBot = async (bot_id: string) => {
    localStorage.setItem('bot_id', bot_id);
  }
  const setBot = async (key: string, value: any) => {
    var bot = getBot();
    bot[key] = value;
    setCurrentBot(Object.assign({}, bot));
    return currentBot;
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, currentBot, setCurrentBot, logout, getBot, _setCurrentBot, setBot, saveBot, googleLogin, getQRCode}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, getBot, _setCurrentBot} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
